// GlobalAudioState.js
// Production‑ready global audio state management with integrated TransitionManager.
// This version fixes TTS issues by enforcing progress non-regression, 
// recording the TTS start time, and auto-forcing completion if TTS gets "stuck."

import TransitionManager from './TransitionManager.js';
import { debounce } from "../../utils/debounce";

class GlobalAudioState {
  constructor() {
    // Define default constants
    this.DEFAULT_API_BASE_URL = "https://api.pause.site";
    this.STATE_MONITOR_INTERVAL = 15000;
    this.TRANSITION_TIMEOUT = 10000;
    this.TTS_TIMEOUT = 30000; // If TTS takes longer than 30s, force completion.
    this.MIXING_TIMEOUT = 30000;
    this.URL_LOCK_TIMEOUT = 5000;
    this.SAFETY_MIXING_TIMEOUT = 45000;

    // Core state object
    this.state = {
      ttsKey: null,
      ttsProgress: 0,
      ttsProcessing: false,
      ttsUrl: null,
      // NEW: ttsStartTime is used to detect stuck processing.
      ttsStartTime: null,
      mixedAudioUrl: null,
      currentAudioUrl: null,
      fallbackAudioUrl: null,
      stableUrlReference: null,
      urlPriority: null,
      urlLocked: false,
      urlLockTime: null,
      urlPriorityOrder: ["mixed", "tts", "fallback", "other"],
      mixingInProgress: false,
      mixingStartTime: null,
      mixingProgress: 0,
      mixingCompleted: false,
      mixingFailed: false,
      mixingErrorReason: null,
      audioInitialized: false,
      audioLoaded: false,
      audioPlaying: false,
      errorRecoveryMode: false,
      errorCount: 0,
      initialized: false,
      lastUpdate: Date.now(),
      eventTarget: (typeof window !== "undefined") ? new EventTarget() : null,
      silentAudioCreated: false,
      silentAudioUrl: null,
      blobUrls: [],
      timeouts: [],
      activeListeners: [],
      isInTransition: false,
      debug: {
        lastError: null,
        stateHistory: [],
        initializeAttempts: 0,
        extractionAttempts: []
      }
    };

    console.log("[GlobalAudioState] Module loaded");

    // Shared audio element for global playback
    this._audioElement = null;

    // Restore previous state from sessionStorage if available.
    this.restoreFromSessionStorage();

    // Attach the TransitionManager instance.
    this.transition = TransitionManager;

    // Global registration for easy access.
    if (typeof window !== "undefined") {
      try {
        window.__audioState = this;
        window.__getAudioState = () => this.getDebugState();
        window.addEventListener("beforeunload", () => this.saveToSessionStorage());
        
        // Bind methods safely with existence checking
        if (typeof this.setCurrentAudioUrl === 'function') {
          window.centralizeAudioUrl = this.setCurrentAudioUrl.bind(this);
        }
        
        if (typeof this.updateAllAudioUrls === 'function') {
          window.updateAllAudioUrls = this.updateAllAudioUrls.bind(this);
        }
        
        if (typeof this.prepareTransitionToListenStep === 'function') {
          window.prepareTransitionToListenStep = this.prepareTransitionToListenStep.bind(this);
        }
        
        if (typeof this.extractMixedAudioUrl === 'function') {
          window.extractMixedAudioUrl = this.extractMixedAudioUrl.bind(this);
        }
        
        if (typeof this.completeTTSProcessing === 'function') {
          window.completeTTSProcessing = this.completeTTSProcessing.bind(this);
        }
        
        window.globalAudioState = this;
      } catch (e) {
        console.warn("Global registration failed:", e);
      }
    }

    // Make sure silent audio URL is available.
    this.ensureSilentAudioUrl();

    // Start periodic state monitoring.
    this.setupStateMonitoring();

    // Debounced updater for audio URLs.
    this._debouncedUpdate = debounce((url) => {
      this.updateAllAudioUrls(url);
    }, 300);
  }

  // ============ Debug & Persistence ============
  getDebugState() {
    return {
      tts: {
        key: this.state.ttsKey,
        progress: this.state.ttsProgress,
        processing: this.state.ttsProcessing,
        url: this.state.ttsUrl
      },
      audio: {
        currentUrl: this.state.currentAudioUrl,
        mixedUrl: this.state.mixedAudioUrl,
        fallbackUrl: this.state.fallbackAudioUrl,
        stableUrl: this.transition.getState().stableUrl
      },
      priority: {
        currentPriority: this.state.urlPriority,
        isLocked: this.state.urlLocked,
        lockTime: this.state.urlLockTime,
        priorityOrder: this.state.urlPriorityOrder
      },
      mixing: {
        inProgress: this.state.mixingInProgress,
        progress: this.state.mixingProgress,
        completed: this.state.mixingCompleted,
        failed: this.state.mixingFailed,
        errorReason: this.state.mixingErrorReason
      },
      transition: this.transition.getState(),
      errors: {
        count: this.state.errorCount,
        recoveryMode: this.state.errorRecoveryMode,
        lastError: this.state.debug.lastError,
        extractionAttempts: this.state.debug.extractionAttempts
      },
      fallback: {
        silentAudioCreated: this.state.silentAudioCreated,
        silentAudioUrl: this.state.silentAudioUrl
      },
      resources: {
        blobUrlCount: this.state.blobUrls.length,
        timeoutCount: this.state.timeouts.length,
        hasSharedAudioElement: !!this._audioElement
      },
      lastUpdate: new Date(this.state.lastUpdate).toISOString()
    };
  }

  restoreFromSessionStorage() {
    if (typeof window === "undefined" || typeof sessionStorage === "undefined") return;
    try {
      console.log("Restoring audio state from session storage");
      const ttsKey = sessionStorage.getItem("ttsKey");
      const ttsUrl = sessionStorage.getItem("ttsAudioUrl");
      const mixedUrl = sessionStorage.getItem("mixedAudioUrl");
      const currentUrl = sessionStorage.getItem("currentAudioUrl");
      const stableUrl = sessionStorage.getItem("stableTransitionUrl");
      const priority = sessionStorage.getItem("urlPriority");
      
      // TTS state
      if (ttsKey) this.state.ttsKey = ttsKey;
      if (ttsUrl) this.state.ttsUrl = ttsUrl;
      
      // Progress indicators
      const ttsProgress = sessionStorage.getItem("ttsProgress");
      if (ttsProgress) this.state.ttsProgress = parseFloat(ttsProgress);
      
      const ttsProcessing = sessionStorage.getItem("ttsProcessing");
      if (ttsProcessing) this.state.ttsProcessing = ttsProcessing === "true";
      
      // Audio URLs
      if (mixedUrl) this.state.mixedAudioUrl = mixedUrl;
      if (currentUrl) this.state.currentAudioUrl = currentUrl;
      if (stableUrl) this.state.stableUrlReference = stableUrl;
      if (priority) this.state.urlPriority = priority;
      
      // Mixing state
      const mixingInProgress = sessionStorage.getItem("mixingInProgress");
      if (mixingInProgress) this.state.mixingInProgress = mixingInProgress === "true";
      
      const mixingProgress = sessionStorage.getItem("mixingProgress");
      if (mixingProgress) this.state.mixingProgress = parseFloat(mixingProgress);
      
      const mixingCompleted = sessionStorage.getItem("mixingCompleted");
      if (mixingCompleted) this.state.mixingCompleted = mixingCompleted === "true";
      
      const mixingFailed = sessionStorage.getItem("mixingFailed");
      if (mixingFailed) this.state.mixingFailed = mixingFailed === "true";
      
      const mixingErrorReason = sessionStorage.getItem("mixingErrorReason");
      if (mixingErrorReason) this.state.mixingErrorReason = mixingErrorReason;
      
      console.log("State restored from session storage");
    } catch (e) {
      console.warn("Error restoring state:", e);
      this.state.debug.lastError = { time: Date.now(), context: "restoreFromSessionStorage", message: e.message };
    }
  }

  saveToSessionStorage() {
    if (typeof window === "undefined" || typeof sessionStorage === "undefined") return;
    try {
      // TTS state
      if (this.state.ttsKey) sessionStorage.setItem("ttsKey", this.state.ttsKey);
      if (this.state.ttsUrl) sessionStorage.setItem("ttsAudioUrl", this.state.ttsUrl);
      if (this.state.ttsProgress !== undefined) sessionStorage.setItem("ttsProgress", this.state.ttsProgress.toString());
      if (this.state.ttsProcessing !== undefined) sessionStorage.setItem("ttsProcessing", this.state.ttsProcessing.toString());
      
      // URLs
      if (this.state.mixedAudioUrl) sessionStorage.setItem("mixedAudioUrl", this.state.mixedAudioUrl);
      if (this.state.currentAudioUrl) sessionStorage.setItem("currentAudioUrl", this.state.currentAudioUrl);
      if (this.state.stableUrlReference) sessionStorage.setItem("stableTransitionUrl", this.state.stableUrlReference);
      if (this.state.urlPriority) sessionStorage.setItem("urlPriority", this.state.urlPriority);
      
      // Mixing state
      if (this.state.mixingInProgress !== undefined) sessionStorage.setItem("mixingInProgress", this.state.mixingInProgress.toString());
      if (this.state.mixingProgress !== undefined) sessionStorage.setItem("mixingProgress", this.state.mixingProgress.toString());
      if (this.state.mixingCompleted !== undefined) sessionStorage.setItem("mixingCompleted", this.state.mixingCompleted.toString());
      if (this.state.mixingFailed !== undefined) sessionStorage.setItem("mixingFailed", this.state.mixingFailed.toString());
      if (this.state.mixingErrorReason) sessionStorage.setItem("mixingErrorReason", this.state.mixingErrorReason);
      
      console.log("State saved to session storage");
    } catch (e) {
      console.warn("Error saving state to sessionStorage:", e);
      this.state.debug.lastError = { time: Date.now(), context: "saveToSessionStorage", message: e.message };
    }
  }

  // ============ Periodic Monitoring ============
  setupStateMonitoring() {
    if (typeof window === "undefined") return;
    this.state.monitoringIntervalId = setInterval(() => {
      const now = Date.now();
      // Check if a transition is stuck.
      const transitionState = this.transition.getState();
      if (transitionState.inTransition && transitionState.transitionStartTime) {
        if (now - transitionState.transitionStartTime > this.TRANSITION_TIMEOUT) {
          console.warn("Stuck transition detected. Forcing completion.");
          this.transition.completeTransition(this.state.currentAudioUrl || this.getSilentAudioUrl());
          this.publishEvent("transition-completed", {
            forced: true,
            duration: now - transitionState.transitionStartTime
          });
          this.state.isInTransition = false;
          if (typeof window !== "undefined") {
            window.__transitionInProgress = false;
          }
        }
      }
      
      // Check for stuck TTS processing
      if (this.state.ttsProcessing && this.state.ttsStartTime) {
        const ttsProcessingTime = now - this.state.ttsStartTime;
        if (ttsProcessingTime > this.TTS_TIMEOUT) {
          console.warn(`Stuck TTS processing detected (${ttsProcessingTime}ms). Forcing completion.`);
          this.completeTTSProcessing();
        }
      }
      
      // Check for stuck audio mixing
      if (this.state.mixingInProgress && this.state.mixingStartTime) {
        const mixingDuration = now - this.state.mixingStartTime;
        if (mixingDuration > this.MIXING_TIMEOUT) {
          console.warn(`Stuck audio mixing detected (${mixingDuration}ms). Forcing completion.`);
          // Force mixing completion with whatever URL we have
          const url = this.state.mixedAudioUrl || this.state.ttsUrl || this.getSilentAudioUrl();
          this.completeAudioMixing(url);
        }
      }
      
      // Check URL lock timeout
      if (this.state.urlLocked && this.state.urlLockTime) {
        const lockDuration = now - this.state.urlLockTime;
        if (lockDuration > this.URL_LOCK_TIMEOUT) {
          console.warn(`URL lock timeout detected (${lockDuration}ms). Releasing lock.`);
          this.state.urlLocked = false;
          // Also update global flag
          if (typeof window !== "undefined") {
            window.__transitionLock = false;
          }
        }
      }
    }, this.STATE_MONITOR_INTERVAL);
    window.__stateMonitoringInterval = this.state.monitoringIntervalId;
  }

  setTimeout(callback, delay) {
    const timeoutId = setTimeout(() => {
      this.state.timeouts = this.state.timeouts.filter((t) => t.id !== timeoutId);
      callback();
    }, delay);
    this.state.timeouts.push({ id: timeoutId, createdAt: Date.now() });
    return timeoutId;
  }

  // ============ URL Validation & Updates ============
  isValidUrl(url) {
    if (!url || typeof url !== "string" || url.includes("undefined")) return false;
    return (
      url.startsWith("http") ||
      url.startsWith("blob:") ||
      url.startsWith("data:") ||
      url.startsWith("/resources/") ||
      url.startsWith("/tts/")
    );
  }

  getApiBaseUrl() {
    try {
      return (
        window.process?.env?.REACT_APP_API_BASE_URL ||
        process.env.REACT_APP_API_BASE_URL ||
        this.DEFAULT_API_BASE_URL
      );
    } catch (e) {
      return this.DEFAULT_API_BASE_URL;
    }
  }

  getTtsUrl(filename) {
    if (!filename) return null;
    const cleanFilename = filename.includes("/") ? filename.split("/").pop() : filename;
    return `${this.getApiBaseUrl()}/tts/${cleanFilename}`;
  }

  getSilentAudioUrl() {
    if (this.state.silentAudioUrl && this.isValidUrl(this.state.silentAudioUrl)) {
      return this.state.silentAudioUrl;
    }
    const url = `${this.getApiBaseUrl()}/resources/silent.mp3`;
    this.state.silentAudioUrl = url;
    this.state.silentAudioCreated = true;
    return url;
  }

  ensureSilentAudioUrl() {
    if (!this.state.silentAudioUrl) {
      this.state.silentAudioUrl = this.getSilentAudioUrl();
      this.state.silentAudioCreated = true;
    }
  }

  /**
   * Update all audio URLs with proper event handling
   * @param {string} url - The URL to set
   * @param {string} source - Source of the update
   * @returns {boolean} Success status
   */
  updateAllAudioUrls(url, source = 'unknown') {
    if (!url || !this.isValidUrl(url)) {
      console.warn("Invalid URL in updateAllAudioUrls:", url);
      return false;
    }
    
    // Block event propagation when needed
    const blockPropagation = this._blockEventPropagation;
    
    try {
      // Update all URL states
      this.state.currentAudioUrl = url;
      
      // Determine the URL type based on filename pattern
      if (url.includes('mixed_') || url.includes('mix_')) {
        this.state.mixedAudioUrl = url;
        this.state.urlPriority = 'mixed';
      } else if (url.includes('tts_') || url.includes('TTS/')) {
        this.state.ttsUrl = url;
        if (!this.state.mixedAudioUrl) {
          this.state.urlPriority = 'tts';
        }
      }
      
      // Update URL in shared audio element if available
      if (this._audioElement && typeof this._audioElement.src !== 'undefined') {
        if (this._audioElement.src !== url) {
          // Only update if different to prevent unnecessary loads
          this._audioElement.src = url;
          this._audioElement.load();
        }
      }
      
      // Store in session storage for recovery
      try {
        sessionStorage.setItem("currentAudioUrl", url);
        if (this.state.urlPriority) {
          sessionStorage.setItem("urlPriority", this.state.urlPriority);
        }
      } catch (e) {
        console.warn("Error storing URL in session storage:", e);
      }
      
      // Publish event if not blocked
      if (!blockPropagation) {
        this.publishEvent('audio-url-updated', {
          url,
          source,
          priority: this.state.urlPriority,
          timestamp: Date.now()
        });
      }
      
      return true;
    } catch (error) {
      console.error("Error in updateAllAudioUrls:", error);
      return false;
    }
  }
  
  /**
   * Set current audio URL with priority handling 
   */
  setCurrentAudioUrl(url, priority = null) {
    if (!this.isValidUrl(url)) {
      console.warn("Invalid URL provided to setCurrentAudioUrl:", url);
      return false;
    }
    
    // Respect URL lock during transitions
    if (this.state.urlLocked && this.state.stableUrlReference) {
      console.log(`URL is locked. Ignoring update request for ${url}`);
      return false;
    }
    
    this.state.currentAudioUrl = url;
    
    // Determine URL category for priority
    if (priority) {
      this.state.urlPriority = priority;
    } else if (url.includes("mixed_")) {
      this.state.urlPriority = "mixed";
    } else if (url.includes("tts_") || url.includes("TTS/")) {
      this.state.urlPriority = "tts";
    }
    
    // Update the audio element if it exists
    if (this._audioElement) {
      this._audioElement.src = url;
      this._audioElement.load();
    }
    
    // Store in session storage
    try {
      sessionStorage.setItem("currentAudioUrl", url);
      if (this.state.urlPriority) {
        sessionStorage.setItem("urlPriority", this.state.urlPriority);
      }
    } catch (e) {
      console.warn("Error storing URL in session storage:", e);
    }
    
    // Use debounced event to prevent multiple rapid updates
    if (!this._blockEventPropagation) {
      this.publishEvent("audio-url-updated", {
        url,
        priority: this.state.urlPriority,
        timestamp: Date.now()
      });
    }
    
    return true;
  }

  /**
   * Extracts the mixed audio URL from a mix result.
   * @param {Object} mixResult - The response object from the audio mixing service.
   * @returns {Object} - An object with { success: true, url: "<url>" } if successful, or { success: false, error: "<message>" }.
   */
  extractMixedAudioUrl(mixResult) {
    // Track extraction attempt for debugging
    this.state.debug.extractionAttempts.push({
      timestamp: Date.now(),
      result: mixResult
    });
    
    // Validate input
    if (!mixResult) {
      console.warn("extractMixedAudioUrl: No mix result provided");
      return { success: false, error: "No mix result provided" };
    }
    
    try {
      // Check for direct file_path in data object
      if (mixResult.data && mixResult.data.file_path && this.isValidUrl(mixResult.data.file_path)) {
        return { success: true, url: mixResult.data.file_path };
      }
      
      // Check for other possible locations in response structure
      if (mixResult.result && mixResult.result.file_path && this.isValidUrl(mixResult.result.file_path)) {
        return { success: true, url: mixResult.result.file_path };
      }
      
      if (mixResult.file_path && this.isValidUrl(mixResult.file_path)) {
        return { success: true, url: mixResult.file_path };
      }
      
      // Check for URL field
      if (mixResult.url && this.isValidUrl(mixResult.url)) {
        return { success: true, url: mixResult.url };
      }
      
      if (mixResult.data && mixResult.data.url && this.isValidUrl(mixResult.data.url)) {
        return { success: true, url: mixResult.data.url };
      }
      
      console.warn("extractMixedAudioUrl: Valid URL not found in mix result", mixResult);
      return { success: false, error: "Valid URL not found in mix result" };
    } catch (error) {
      console.error("extractMixedAudioUrl: Error processing mix result", error);
      return { success: false, error: `Error extracting URL: ${error.message}` };
    }
  }

  // ============ Audio Mixing Methods ============
  startAudioMixing() {
    console.log("Starting audio mixing process");
    this.state.mixingInProgress = true;
    this.state.mixingStartTime = Date.now();
    this.state.mixingProgress = 0;
    this.state.mixingCompleted = false;
    this.state.mixingFailed = false;
    this.state.lastUpdate = Date.now();
    try {
      sessionStorage.setItem("mixingInProgress", "true");
      sessionStorage.setItem("mixingStartTime", Date.now().toString());
      sessionStorage.setItem("mixingProgress", "0");
      sessionStorage.setItem("mixingCompleted", "false");
      sessionStorage.setItem("mixingFailed", "false");
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("mixing-started", { timestamp: Date.now() });
    this.saveToSessionStorage();
    return true;
  }

  updateMixingProgress(progress) {
    if (typeof progress !== 'number' || isNaN(progress)) {
      console.warn("Invalid mixing progress value:", progress);
      progress = 0;
    }
    const normalizedProgress = Math.max(0, Math.min(100, progress));
    if (normalizedProgress < this.state.mixingProgress) {
      console.warn(`Prevented mixing progress decrease from ${this.state.mixingProgress}% to ${normalizedProgress}%`);
      return this.state.mixingProgress;
    }
    this.state.mixingProgress = normalizedProgress;
    this.state.lastUpdate = Date.now();
    try {
      sessionStorage.setItem("mixingProgress", normalizedProgress.toString());
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("mixing-progress-updated", { progress: normalizedProgress, timestamp: Date.now() });
    if (normalizedProgress >= 100 && this.state.mixingInProgress) {
      this.state.mixingCompleted = true;
      this.state.mixingInProgress = false;
      try {
        sessionStorage.setItem("mixingCompleted", "true");
        sessionStorage.setItem("mixingInProgress", "false");
      } catch (e) {
        console.warn("Error updating session storage:", e);
      }
    }
    return normalizedProgress;
  }

  completeAudioMixing(finalUrl) {
    console.log("Completing audio mixing with URL:", finalUrl);
    this.state.mixingInProgress = false;
    this.state.mixingCompleted = true;
    this.state.mixingProgress = 100;
    this.state.lastUpdate = Date.now();
    if (finalUrl && this.isValidUrl(finalUrl)) {
      this.state.mixedAudioUrl = finalUrl;
      this.setCurrentAudioUrl(finalUrl);
    }
    try {
      sessionStorage.setItem("mixingInProgress", "false");
      sessionStorage.setItem("mixingCompleted", "true");
      sessionStorage.setItem("mixingProgress", "100");
      if (finalUrl && this.isValidUrl(finalUrl)) {
        sessionStorage.setItem("mixedAudioUrl", finalUrl);
      }
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("mixing-completed", { finalUrl, timestamp: Date.now() });
    this.saveToSessionStorage();
    return true;
  }

  failAudioMixing(reason = 'unknown') {
    console.warn("Audio mixing failed:", reason);
    this.state.mixingInProgress = false;
    this.state.mixingFailed = true;
    this.state.mixingErrorReason = reason;
    this.state.lastUpdate = Date.now();
    try {
      sessionStorage.setItem("mixingInProgress", "false");
      sessionStorage.setItem("mixingFailed", "true");
      sessionStorage.setItem("mixingErrorReason", reason);
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("mixing-failed", { reason, timestamp: Date.now() });
    this.saveToSessionStorage();
    return true;
  }

  /**
   * Creates a silent audio fallback when mixing fails
   * @returns {Promise<{success: boolean, url: string}>} Result with URL
   */
  async createSilentFallback() {
    console.log("Creating silent audio fallback due to mixing failure");
    
    // Generate timestamp-based ID for the silent audio
    const silentId = `silent_audio_${Date.now()}.mp3`;
    
    // Get silent audio URL
    const silentUrl = this.getSilentAudioUrl();
    
    // Record in state
    this.state.ttsKey = silentId;
    this.state.ttsUrl = silentUrl;
    this.state.currentAudioUrl = silentUrl;
    
    // Update session storage
    try {
      sessionStorage.setItem("ttsKey", silentId);
      sessionStorage.setItem("ttsAudioUrl", silentUrl);
      sessionStorage.setItem("currentAudioUrl", silentUrl);
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    
    // Publish event
    this.publishEvent('fallback-audio-created', {
      id: silentId,
      url: silentUrl,
      reason: 'mixing-failure',
      timestamp: Date.now()
    });
    
    // Complete TTS and mixing processes
    this.completeTTSProcessing();
    this.completeAudioMixing(silentUrl);
    
    return { success: true, url: silentUrl };
  }

  // ============ Audio Element Methods ============
  getAudioElement() {
    if (this._audioElement) {
      return this._audioElement;
    }
    if (typeof Audio !== 'undefined') {
      try {
        const audioElement = new Audio();
        audioElement.crossOrigin = "anonymous";
        audioElement.preload = "auto";
        const bestUrl = this.getBestAudioUrl();
        if (bestUrl) {
          audioElement.src = bestUrl;
          audioElement.load();
        }
        audioElement.addEventListener('play', () => {
          this.state.audioPlaying = true;
          this.publishEvent("audio-play", { timestamp: Date.now() });
        });
        audioElement.addEventListener('pause', () => {
          this.state.audioPlaying = false;
          this.publishEvent("audio-pause", { timestamp: Date.now() });
        });
        audioElement.addEventListener('ended', () => {
          this.state.audioPlaying = false;
          this.publishEvent("audio-ended", { timestamp: Date.now() });
        });
        audioElement.addEventListener('timeupdate', () => {
          this.state.currentTime = audioElement.currentTime;
        });
        audioElement.addEventListener('loadedmetadata', () => {
          this.state.audioLoaded = true;
          this.state.duration = audioElement.duration;
          this.publishEvent("audio-loaded", { duration: audioElement.duration, timestamp: Date.now() });
        });
        audioElement.addEventListener('error', (e) => {
          console.warn("Audio element error:", e);
          this.state.errorCount++;
          this.state.debug.lastError = {
            time: Date.now(),
            context: "audioElement",
            message: audioElement.error ? audioElement.error.message : "Unknown audio error"
          };
          if (this.state.fallbackAudioUrl && this.state.fallbackAudioUrl !== audioElement.src) {
            console.log("Attempting recovery with fallback URL");
            audioElement.src = this.state.fallbackAudioUrl;
            audioElement.load();
          }
        });
        this._audioElement = audioElement;
        return audioElement;
      } catch (e) {
        console.error("Error creating audio element:", e);
        this.state.debug.lastError = { time: Date.now(), context: "getAudioElement", message: e.message };
      }
    }
    return null;
  }

  getBestAudioUrl() {
    if (this.state.urlPriority) {
      switch (this.state.urlPriority) {
        case 'mixed':
          if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) {
            return this.state.mixedAudioUrl;
          }
          break;
        case 'tts':
          if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
            return this.state.ttsUrl;
          }
          break;
        case 'fallback':
          if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
            return this.state.fallbackAudioUrl;
          }
          break;
      }
    }
    
    // If priority doesn't yield a valid URL, try in default priority order
    if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) {
      return this.state.mixedAudioUrl;
    }
    if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
      return this.state.ttsUrl;
    }
    if (this.state.fallbackAudioUrl && this.isValidUrl(this.state.fallbackAudioUrl)) {
      return this.state.fallbackAudioUrl;
    }
    if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) {
      return this.state.currentAudioUrl;
    }
    return this.getSilentAudioUrl();
  }

  updateTTSKey(key, url = null) {
    if (!key) {
      console.warn("Invalid TTS key");
      return false;
    }
    console.log("Updating TTS key:", key);
    this.state.ttsKey = key;
    if (url && this.isValidUrl(url)) {
      this.state.ttsUrl = url;
    } else if (key) {
      this.state.ttsUrl = this.getTtsUrl(key);
    }
    try {
      sessionStorage.setItem("ttsKey", key);
      if (this.state.ttsUrl) {
        sessionStorage.setItem("ttsAudioUrl", this.state.ttsUrl);
      }
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("tts-key-updated", { key, url: this.state.ttsUrl, timestamp: Date.now() });
    this.saveToSessionStorage();
    return true;
  }

  // ============ TTS Processing Methods ============
  startTTSProcessing() {
    console.log("Starting TTS processing");
    this.state.ttsProcessing = true;
    this.state.ttsProgress = 0;
    // Record the TTS start time for timeout monitoring.
    this.state.ttsStartTime = Date.now();
    this.state.lastUpdate = Date.now();
    try {
      sessionStorage.setItem("ttsProcessing", "true");
      sessionStorage.setItem("ttsProgress", "0");
      sessionStorage.setItem("ttsStartTime", this.state.ttsStartTime.toString());
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    this.publishEvent("tts-processing-started", { timestamp: Date.now() });
    this.saveToSessionStorage();
    return true;
  }

  /**
   * Updates TTS progress while preventing regression and intermingling updates.
   * Once TTS is complete (global flag __ttsCompleted is true), ignore further updates.
   * @param {number} progress The new progress value.
   * @returns {number} The progress after processing.
   */
  updateTTSProgress(progress) {
    // If TTS is already complete via our global flag, do not update.
    if (typeof window !== 'undefined' && window.__ttsCompleted === true) {
      console.log("TTS is complete; ignoring further progress updates.");
      return 100;
    }
    
    // Validate input progress.
    if (typeof progress !== 'number' || isNaN(progress)) {
      console.warn("Invalid progress value:", progress);
      progress = 0;
    }
    
    // Clamp progress between 0 and 100.
    const normalizedProgress = Math.max(0, Math.min(100, progress));
    
    // Prevent regression—ensure that the new progress is not lower than the current state.
    if (normalizedProgress < this.state.ttsProgress) {
      console.warn(`Prevented progress decrease from ${this.state.ttsProgress}% to ${normalizedProgress}%`);
      return this.state.ttsProgress;
    }
    
    // Update internal state and record the timestamp.
    this.state.ttsProgress = normalizedProgress;
    this.state.lastUpdate = Date.now();
    
    try {
      sessionStorage.setItem("ttsProgress", normalizedProgress.toString());
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    
    // Publish the updated progress event.
    this.publishEvent("tts-progress-updated", { progress: normalizedProgress, timestamp: Date.now() });
    
    // Auto-complete TTS processing if progress is 100% and processing is still marked as true.
    if (normalizedProgress >= 100 && this.state.ttsProcessing) {
      this.completeTTSProcessing();
    }
    
    return normalizedProgress;
  }

  /**
   * Completes TTS processing and updates all related state
   * @returns {boolean} Success status
   */
  completeTTSProcessing() {
    console.log("Completing TTS processing");
    
    this.state.ttsProcessing = false;
    this.state.ttsProgress = 100;
    
    // Record completion time
    const completionTime = Date.now();
    const processingTime = this.state.ttsStartTime ? (completionTime - this.state.ttsStartTime) : 0;
    
    this.state.lastUpdate = completionTime;
    
    // Set global flag for completion status
    if (typeof window !== 'undefined') {
      window.__ttsCompleted = true;
      window.__ttsCompletionTime = completionTime;
      window.__lastTTSProgress = 100;
    }
    
    try {
      sessionStorage.setItem("ttsProcessing", "false");
      sessionStorage.setItem("ttsProgress", "100");
      sessionStorage.setItem("ttsCompletionTime", completionTime.toString());
    } catch (e) {
      console.warn("Error updating sessionStorage:", e);
    }
    
    this.publishEvent("tts-processing-completed", { 
      timestamp: completionTime,
      processingTime,
      ttsKey: this.state.ttsKey
    });
    
    this.saveToSessionStorage();
    return true;
  }

  /**
   * Checks if TTS is ready to be used for mixing
   * @returns {Object} Result object with isReady status and reason
   */
  isTTSReadyForMixing() {
    // First check if we have a global completion flag
    if (typeof window !== 'undefined' && window.__ttsCompleted === true) {
      return { 
        isReady: true, 
        reason: 'globalFlag' 
      };
    }
    
    // Check if we have a valid TTS key and URL
    const hasTtsKey = Boolean(this.state.ttsKey);
    const hasTtsUrl = Boolean(this.state.ttsUrl) && this.isValidUrl(this.state.ttsUrl);
    
    // Check processing state
    const isProcessing = Boolean(this.state.ttsProcessing);
    const progress = this.state.ttsProgress || 0;
    
    // Either completely done, or high progress with key
    if ((!isProcessing && hasTtsKey) || (hasTtsKey && progress >= 95)) {
      return { 
        isReady: true, 
        reason: 'normalCompletion',
        hasKey: hasTtsKey,
        hasUrl: hasTtsUrl,
        progress
      };
    }
    
    // Not ready yet
    return {
      isReady: false,
      reason: 'incomplete',
      hasKey: hasTtsKey,
      hasUrl: hasTtsUrl,
      isProcessing,
      progress
    };
  }

  // ============ Transition Handling ============
  prepareTransitionToListenStep(params = {}) {
    console.log("Preparing transition to ListenStep");
    
    // Set transition flags
    this.state.isInTransition = true;
    if (typeof window !== 'undefined') {
      window.__transitionInProgress = true;
      window.__transitionTimestamp = Date.now();
    }
    
    // First, make sure TTS processing is complete
    if (this.state.ttsProcessing || this.state.ttsProgress < 100) {
      console.log("Forcing TTS completion before transition");
      this.completeTTSProcessing();
    }
    
    // Get best URL for transition
    let bestUrl = null;
    if (this.state.mixedAudioUrl && this.isValidUrl(this.state.mixedAudioUrl)) {
      bestUrl = this.state.mixedAudioUrl;
    } else if (this.state.ttsUrl && this.isValidUrl(this.state.ttsUrl)) {
      bestUrl = this.state.ttsUrl;
    } else if (this.state.ttsKey) {
      bestUrl = this.getTtsUrl(this.state.ttsKey);
    } else if (this.state.currentAudioUrl && this.isValidUrl(this.state.currentAudioUrl)) {
      bestUrl = this.state.currentAudioUrl;
    } else {
      bestUrl = this.getSilentAudioUrl();
    }
    
    // Lock the URL for transition
    if (typeof this.transition?.lockUrl === 'function') {
      this.transition.lockUrl(bestUrl, bestUrl.includes("mixed_") ? "mixed" : "tts");
    }
    
    // Update all audio URLs
    this.updateAllAudioUrls(bestUrl);
    
    // Preload audio
    if (this._audioElement) {
      try {
        this._audioElement.src = bestUrl;
        this._audioElement.load();
      } catch (e) {
        console.warn("Error preloading audio:", e);
      }
    }
    
    // Create an additional preload element for redundancy
    try {
      const preloadAudio = new Audio();
      preloadAudio.volume = 0;
      preloadAudio.src = bestUrl;
      preloadAudio.preload = "metadata";
      preloadAudio.load();
      if (typeof window !== 'undefined') {
        window.__preloadAudio = preloadAudio;
      }
    } catch (e) {
      console.warn("Error creating preload audio element:", e);
    }
    
    // Update session storage
    try {
      sessionStorage.setItem("stableTransitionUrl", bestUrl);
      sessionStorage.setItem("currentAudioUrl", bestUrl);
      sessionStorage.setItem("urlPriority", bestUrl.includes("mixed_") ? "mixed" : "tts");
      sessionStorage.setItem("isInTransition", "true");
    } catch (e) {
      console.warn("Error saving transition URLs:", e);
    }
    
    // Publish transition event
    this.publishEvent("transition-initiated", {
      source: params.source || 'globalAudioState',
      target: "listen",
      url: bestUrl,
      timestamp: Date.now()
    });
    
    return { success: true, url: bestUrl, isTransitioning: true };
  }

  /**
   * Properly completes transition between steps
   * @param {string} audioUrl - URL to use after transition
   * @returns {boolean} Success status
   */
  completeTransition(audioUrl) {
    console.log("GlobalAudioState: Completing transition with URL:", audioUrl);
    
    // Update state
    this.state.isInTransition = false;
    this.state.transitionCompleted = true;
    
    // Update URLs if valid
    if (audioUrl && this.isValidUrl(audioUrl)) {
      this.state.currentAudioUrl = audioUrl;
      this.state.stableUrlReference = audioUrl;
    }
    
    // Update global flags
    if (typeof window !== 'undefined') {
      window.__transitionInProgress = false;
      window.__transitionLock = false;
      window.__waveSurferLock = false;
      window.__transitionCompleted = true;
      window.__transitionCompletedTime = Date.now();
    }
    
    // Notify TransitionManager if available
    if (typeof window !== 'undefined' && 
        window.TransitionManager && 
        typeof window.TransitionManager.completeTransition === 'function') {
      window.TransitionManager.completeTransition(audioUrl);
    }
    
    // Publish event
    this.publishEvent('transition-completed', {
      url: audioUrl,
      timestamp: Date.now()
    });
    
    return true;
  }

  /**
   * Checks if system is in transition state
   * @returns {boolean} Whether in transition
   */
  isInTransition() {
    // Check internal state first
    if (this.state.isInTransition) {
      return true;
    }
    
    // Check window flags as backup
    if (typeof window !== 'undefined') {
      return (
        window.__transitionInProgress === true || 
        window.__transitionLock === true ||
        window.__waveSurferLock === true ||
        (window.TransitionManager && 
         typeof window.TransitionManager.isTransitionActive === 'function' && 
         window.TransitionManager.isTransitionActive())
      );
    }
    
    return false;
  }

  // ============ Event Pub/Sub ============
  publishEvent(eventName, data) {
    if (typeof window === "undefined") return;
    try {
      if (this._blockEventPropagation &&
          (eventName === 'audio-url-updated' || eventName === 'url-updated' || eventName === 'url-locked')) {
        console.log(`Blocked event propagation during transition: ${eventName}`);
        return;
      }
      const eventData = {
        ...data,
        timestamp: data.timestamp || Date.now(),
        source: data.source || "GlobalAudioState"
      };
      const event = new CustomEvent(eventName, { detail: eventData });
      if (this.state.eventTarget) {
        this.state.eventTarget.dispatchEvent(event);
      }
      window.dispatchEvent(event);
      if (this.state.activeListeners && Array.isArray(this.state.activeListeners)) {
        this.state.activeListeners
          .filter(listener => listener.event === eventName)
          .forEach(listener => {
            try {
              if (typeof listener.callback === 'function') {
                listener.callback(eventData);
              }
            } catch (e) {
              console.warn(`Error in event listener for ${eventName}:`, e);
            }
          });
      }
    } catch (e) {
      console.warn(`Error publishing event ${eventName}:`, e);
      this.state.debug.lastError = { time: Date.now(), context: "publishEvent", message: e.message, event: eventName };
    }
  }

  subscribe(eventName, callback) {
    if (typeof window === "undefined" || typeof callback !== 'function') {
      return () => {};
    }
    try {
      const wrappedCallback = (event) => {
        if (typeof callback === "function") {
          try {
            callback(event.detail || event);
          } catch (e) {
            console.warn(`Error in event listener for ${eventName}:`, e);
          }
        }
      };
      if (this.state.eventTarget) {
        this.state.eventTarget.addEventListener(eventName, wrappedCallback);
      }
      window.addEventListener(eventName, wrappedCallback);
      if (!this.state.activeListeners) {
        this.state.activeListeners = [];
      }
      const listenerId = Date.now() + Math.random().toString(36).substring(2, 9);
      this.state.activeListeners.push({
        id: listenerId,
        event: eventName,
        callback: wrappedCallback,
        addedAt: Date.now()
      });
      return () => {
        if (this.state.eventTarget) {
          this.state.eventTarget.removeEventListener(eventName, wrappedCallback);
        }
        window.removeEventListener(eventName, wrappedCallback);
        if (this.state.activeListeners) {
          this.state.activeListeners = this.state.activeListeners.filter(
            listener => listener.id !== listenerId
          );
        }
      };
    } catch (e) {
      console.warn(`Error in subscribe for ${eventName}:`, e);
      return () => {};
    }
  }

  /**
   * Reset all state for a new session
   */
  resetAllState() {
    console.log("Resetting all audio state");
    
    // Reset TTS state
    this.state.ttsKey = null;
    this.state.ttsProgress = 0;
    this.state.ttsProcessing = false;
    this.state.ttsUrl = null;
    this.state.ttsStartTime = null;
    
    // Reset URL state
    this.state.mixedAudioUrl = null;
    this.state.currentAudioUrl = null;
    this.state.fallbackAudioUrl = null;
    this.state.stableUrlReference = null;
    this.state.urlPriority = null;
    this.state.urlLocked = false;
    this.state.urlLockTime = null;
    
    // Reset mixing state
    this.state.mixingInProgress = false;
    this.state.mixingStartTime = null;
    this.state.mixingProgress = 0;
    this.state.mixingCompleted = false;
    this.state.mixingFailed = false;
    this.state.mixingErrorReason = null;
    
    // Reset audio state
    this.state.audioInitialized = false;
    this.state.audioLoaded = false;
    this.state.audioPlaying = false;
    
    // Reset error state
    this.state.errorRecoveryMode = false;
    this.state.errorCount = 0;
    
    // Reset transition state
    this.state.isInTransition = false;
    
    // Update timestamp
    this.state.lastUpdate = Date.now();
    
    // Clear session storage
    try {
      sessionStorage.removeItem("ttsKey");
      sessionStorage.removeItem("ttsAudioUrl");
      sessionStorage.removeItem("ttsProgress");
      sessionStorage.removeItem("ttsProcessing");
      sessionStorage.removeItem("mixedAudioUrl");
      sessionStorage.removeItem("currentAudioUrl");
      sessionStorage.removeItem("stableTransitionUrl");
      sessionStorage.removeItem("urlPriority");
      sessionStorage.removeItem("mixingInProgress");
      sessionStorage.removeItem("mixingProgress");
      sessionStorage.removeItem("mixingCompleted");
      sessionStorage.removeItem("mixingFailed");
      sessionStorage.removeItem("mixingErrorReason");
      sessionStorage.removeItem("isInTransition");
    } catch (e) {
      console.warn("Error clearing sessionStorage:", e);
    }
    
    // Reset global window flags
    if (typeof window !== 'undefined') {
      window.__ttsCompleted = false;
      window.__ttsCompletionTime = null;
      window.__lastTTSProgress = 0;
      window.__transitionInProgress = false;
      window.__transitionLock = false;
      window.__waveSurferLock = false;
      window.__audioStepTransitionStarted = false;
      window.__transitionCompleted = false;
    }
    
    // Publish reset event
    this.publishEvent("state-reset", { timestamp: Date.now() });
    
    return true;
  }
}

// Create and export a singleton instance.
const globalAudioState = new GlobalAudioState();
export default globalAudioState;