// LoadingOverlay.js
import React, { useState, useEffect, useCallback, useRef } from 'react';

const BACKEND_PHASES = {
  PREPARATION: "preparation",
  SCRIPT_GENERATION: "script_generation",
  SCRIPT_PROCESSING: "script_processing",
  VOICE_SYNTHESIS: "voice_synthesis",
  AUDIO_LOADING: "audio_loading",
  AUDIO_PROCESSING: "audio_processing",
  AUDIO_EFFECTS: "audio_effects",
  EXPORT: "export",
  FINALIZATION: "finalization"
};

const progressSteps = {
  script_generation: [
    { progress: 5, message: "Preparing your meditation journey..." },
    { progress: 25, message: "Creating your script..." },
    { progress: 50, message: "Developing calming phrases..." },
    { progress: 70, message: "Processing your meditation script..." },
    { progress: 95, message: "Finalizing your meditation script..." }
  ],
  voice_synthesis: [
    { progress: 5, message: "Setting up voice environment..." },
    { progress: 25, message: "Creating your meditation voice..." },
    { progress: 60, message: "Processing voice audio..." },
    { progress: 95, message: "Finalizing your meditation audio..." }
  ],
  audio_processing: [
    { progress: 5, message: "Preparing audio tracks..." },
    { progress: 40, message: "Applying audio effects..." },
    { progress: 70, message: "Enhancing your audio experience..." },
    { progress: 95, message: "Finalizing your meditation audio..." }
  ],
  default: [
    { progress: 10, message: "Starting your meditation journey..." },
    { progress: 40, message: "Creating peaceful elements..." },
    { progress: 70, message: "Bringing mindfulness forward..." },
    { progress: 95, message: "Finalizing your meditation..." }
  ]
};

const LoadingOverlay = ({
  message = "Preparing your meditation...",
  subtitle = "Finding your calm",
  progress: initialProgress = 0,
  isVisible = true,
  isMobile = false,
  jobId = null,
  currentPhase = '',
  baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site'
}) => {
  // Log the render attempt no more than once per second (for debugging)
  const now = Date.now();
  if (!window.__lastLoadingOverlayLog || now - window.__lastLoadingOverlayLog > 1000) {
    console.log("LoadingOverlay: render attempt");
    window.__lastLoadingOverlayLog = now;
  }

  // Local state
  const [progress, setProgress] = useState(initialProgress);
  const [statusMessage, setStatusMessage] = useState(message);
  const [detailedStatus, setDetailedStatus] = useState('');
  const [sseConnected, setSseConnected] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [currentDisplayPhase, setCurrentDisplayPhase] = useState('');
  const [jobCompleted, setJobCompleted] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(Date.now());

  // Refs for SSE management and component lifecycle
  const eventSourceRef = useRef(null);
  const mountedRef = useRef(true);
  const lastJobIdRef = useRef(null);
  const sseErrorCountRef = useRef(0);
  const retryCountRef = useRef(0);

  // Cleanup function to close SSE connection
  const cleanupSSE = useCallback(() => {
    if (eventSourceRef.current) {
      console.log("Closing SSE connection in LoadingOverlay");
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setSseConnected(false);
    sseErrorCountRef.current = 0;
  }, []);

  // Mark that the component is mounted for use in async callbacks
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      cleanupSSE();
    };
  }, [cleanupSSE]);

  // Helper: Get friendly phase message based on progress
  const getPhaseMessage = useCallback(
    (phase, currentProgress) => {
      const steps = progressSteps[phase] || progressSteps.default;
      let bestMessage = "Processing your meditation...";
      for (const step of steps) {
        if (currentProgress >= step.progress) {
          bestMessage = step.message;
        } else {
          break;
        }
      }
      return bestMessage;
    },
    []
  );

  // Helper: Get phase display name
  const getPhaseDisplayName = useCallback(
    (phase) => {
      switch (phase) {
        case BACKEND_PHASES.SCRIPT_GENERATION:
        case BACKEND_PHASES.SCRIPT_PROCESSING:
          return 'Creating Script';
        case BACKEND_PHASES.VOICE_SYNTHESIS:
          return 'Creating Voice';
        case BACKEND_PHASES.AUDIO_PROCESSING:
        case BACKEND_PHASES.AUDIO_EFFECTS:
        case BACKEND_PHASES.AUDIO_LOADING:
          return 'Creating Sound';
        case BACKEND_PHASES.PREPARATION:
          return 'Preparing';
        case BACKEND_PHASES.FINALIZATION:
        case BACKEND_PHASES.EXPORT:
          return 'Finalizing';
        default:
          return 'Creating Peace';
      }
    },
    []
  );

  // SSE connection logic – runs when jobId changes.
  // We unsubscribe early on jobId change (or when job is complete) to avoid duplicate subscriptions.
  useEffect(() => {
    if (!jobId || jobId === lastJobIdRef.current || jobCompleted) return;
    lastJobIdRef.current = jobId;
    console.log(`[LoadingOverlay] Setting up SSE for job: ${jobId}`);

    // Always cleanup any previous SSE connection
    cleanupSSE();
    setSseConnected(false);
    setConnectionFailed(false);

    let attempt = 0;
    const maxRetries = 3;

    // Function to establish an SSE connection
    const connectSSE = () => {
      attempt++;
      console.log(`[LoadingOverlay] Attempt ${attempt} to connect SSE for job ${jobId}`);

      const es = new EventSource(`${baseUrl}/progress-stream/${jobId}`);
      eventSourceRef.current = es;

      sseErrorCountRef.current = 0;

      // Reset a progress timeout to force completion if no update comes in 60 seconds.
      const resetProgressTimeout = () => {
        setTimeout(() => {
          if (mountedRef.current && !jobCompleted) {
            console.warn(`No updates for job ${jobId} after 60s, forcing completion`);
            setProgress(100);
            setJobCompleted(true);
            es.close();
          }
        }, 60000);
      };

      es.onopen = () => {
        console.log("[LoadingOverlay] SSE connection opened");
        sseErrorCountRef.current = 0;
        setSseConnected(true);
        setConnectionFailed(false);
        resetProgressTimeout();
      };

      es.onmessage = (e) => {
        if (!mountedRef.current) {
          es.close();
          return;
        }
        try {
          const data = JSON.parse(e.data);
          sseErrorCountRef.current = 0;
          retryCountRef.current = 0;
          setLastUpdate(Date.now());

          if (typeof data.percentage === 'number') {
            // If a complete event wasn’t flagged yet, cap at 99%.
            const clampedProgress = data.percentage >= 100 && !data.complete ? 99 : data.percentage;
            setProgress((prev) => Math.max(prev, clampedProgress));
          }

          // Update phase and detailed status based on data.
          if (data.phase && data.phase !== currentDisplayPhase) {
            setCurrentDisplayPhase(data.phase);
            setDetailedStatus(getPhaseMessage(data.phase, data.percentage || 0));
          } else if (data.percentage) {
            setDetailedStatus(getPhaseMessage(data.phase || currentDisplayPhase, data.percentage));
          }

          if (data.message) {
            setStatusMessage(data.message);
          }
          if (data.detailed_status) {
            setDetailedStatus(data.detailed_status);
          }

          // If the SSE data shows job completion, force closure of the connection.
          if (data.complete || (data.percentage >= 100)) {
            console.log("[LoadingOverlay] SSE job complete, closing");
            setProgress(100);
            setJobCompleted(true);
            es.close();
            setSseConnected(false);
          }
        } catch (err) {
          console.error("[LoadingOverlay] Error parsing SSE data:", err);
          sseErrorCountRef.current++;
          if (sseErrorCountRef.current > 5) {
            console.warn("[LoadingOverlay] Too many parse errors, closing connection");
            es.close();
            setConnectionFailed(true);
          }
        }
      };

      es.onerror = (err) => {
        console.error("[LoadingOverlay] SSE error:", err);
        sseErrorCountRef.current++;
        // If the connection is closed, attempt to reconnect if still under the retry limit.
        if (es.readyState === EventSource.CLOSED) {
          if (attempt < maxRetries) {
            const delay = 1000 * Math.pow(2, attempt - 1);
            console.log(`[LoadingOverlay] Reconnecting SSE in ${delay}ms`);
            setTimeout(() => {
              if (mountedRef.current && !jobCompleted) {
                connectSSE();
              }
            }, delay);
            return;
          } else {
            console.log("[LoadingOverlay] SSE max retries reached, closing connection");
            setConnectionFailed(true);
            es.close();
          }
        }
        if (sseErrorCountRef.current > 5) {
          console.warn(`[LoadingOverlay] Too many connection errors for job ${jobId}, closing`);
          es.close();
          setConnectionFailed(true);
        }
      };
    };

    connectSSE();

    // Unsubscribe early if jobId changes or component unmounts.
    return cleanupSSE;
  }, [jobId, baseUrl, jobCompleted, currentDisplayPhase, cleanupSSE, getPhaseMessage]);

  // If no SSE is connected (or in fallback mode), update progress and message from props.
  useEffect(() => {
    if (!sseConnected && !connectionFailed) {
      setProgress((prev) => Math.max(prev, initialProgress));
      setStatusMessage(message);
    }
  }, [initialProgress, message, sseConnected, connectionFailed]);

  // Update phase name if the prop "currentPhase" changes.
  useEffect(() => {
    if (currentPhase && currentPhase !== currentDisplayPhase) {
      setCurrentDisplayPhase(currentPhase);
      setDetailedStatus(getPhaseMessage(currentPhase, progress));
    }
  }, [currentPhase, currentDisplayPhase, progress, getPhaseMessage]);

  // Do not render the overlay if it is not visible or if the job is complete.
  if (!isVisible || jobCompleted) return null;

  // Define color scheme and gradients for the overlay.
  const appColors = {
    primary: '#8C67AC',
    secondary: '#9E85B7',
    highlight: '#B8A0D9',
    background: 'rgba(20, 16, 25, 0.95)',
    text: '#FFF8F0',
    progressBg: '#372B44'
  };

  const getGradientStyle = () => ({
    background: `linear-gradient(to right,
      ${appColors.primary} 0%,
      ${appColors.secondary} ${progress / 2}%,
      ${appColors.highlight} ${progress}%,
      ${appColors.progressBg} ${progress}%,
      ${appColors.progressBg} 100%)`,
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    transition: 'all 0.3s ease-out'
  });

  // Render the overlay
  return (
    <div
      aria-live="polite"
      role="alert"
      aria-label={`Loading: ${statusMessage}`}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: appColors.background,
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        transition: 'opacity 0.2s ease',
        backdropFilter: isMobile ? 'none' : 'blur(10px)',
        WebkitBackdropFilter: isMobile ? 'none' : 'blur(10px)'
      }}
    >
      {/* Main Message */}
      <div style={{
        color: appColors.text,
        fontSize: '24px',
        marginBottom: '12px',
        textAlign: 'center',
        maxWidth: '80%',
        fontWeight: '300'
      }}>
        {statusMessage}
      </div>

      {/* Detailed/Phase Message */}
      {detailedStatus && (
        <div style={{
          color: 'rgba(255, 248, 240, 0.8)',
          fontSize: '16px',
          marginBottom: '8px',
          textAlign: 'center',
          maxWidth: '80%',
          fontWeight: '300',
          fontStyle: 'italic'
        }}>
          {detailedStatus}
        </div>
      )}

      {/* Progress Bar */}
      <div style={{ width: '280px', marginBottom: '20px' }}>
        <div style={{
          width: '100%',
          height: '10px',
          backgroundColor: appColors.progressBg,
          borderRadius: '8px',
          overflow: 'hidden',
          border: '1px solid rgba(255,255,255,0.1)'
        }}>
          <div style={getGradientStyle()} />
        </div>
        <div style={{
          fontSize: '12px',
          color: 'rgba(255, 248, 240, 0.6)',
          textAlign: 'center',
          marginTop: '8px',
          fontWeight: '300'
        }}>
          {getPhaseDisplayName(currentDisplayPhase || currentPhase)}
        </div>
      </div>

      {/* Subtitle */}
      <div style={{
        color: appColors.text,
        fontSize: '14px',
        marginBottom: '5px',
        opacity: 0.8,
        fontWeight: '300'
      }}>
        {subtitle}
      </div>

      {/* Percentage */}
      <div style={{
        color: appColors.highlight,
        fontSize: '16px',
        fontWeight: '500',
        letterSpacing: '0.5px'
      }}>
        {progress}%
      </div>

      {/* Dev Info (only in development mode) */}
      {process.env.NODE_ENV === 'development' && (
        <div style={{
          fontSize: '0.7rem',
          color: sseConnected
            ? 'rgba(140, 103, 172, 0.8)'
            : connectionFailed
              ? 'rgba(255, 158, 158, 0.8)'
              : 'rgba(255, 255, 158, 0.8)',
          marginTop: '0.5rem'
        }}>
          {sseConnected ? 'Live Updates' : connectionFailed ? 'Fallback Mode' : 'Connecting...'}
          {jobId ? ` (Job: ${jobId.substring(0, 8)}...)` : ''}
        </div>
      )}
    </div>
  );
};

export default React.memo(LoadingOverlay);
