import React, { useState, useEffect, useCallback, useRef } from 'react';

import { toast } from 'react-toastify';
import { IoPlayCircle, IoStopCircle, IoCheckmarkCircle } from 'react-icons/io5';
import audioService from '../../../../services/audioService';


const VoiceStep = ({ selectedVoice, setSelectedVoice, onCreateTTS }) => {
  const voices = [
    { id: 'alloy', name: 'Alloy', gender: 'Neutral', description: 'A versatile voice suitable for various applications' },
    { id: 'ash', name: 'Ash', gender: 'Neutral', description: 'A balanced, versatile voice with a measured tone' },
    { id: 'coral', name: 'Coral', gender: 'Female', description: 'A warm and expressive female voice with dynamic range' },
    { id: 'echo', name: 'Echo', gender: 'Male', description: 'Ethereal and tranquil male voice' },
    { id: 'fable', name: 'Fable', gender: 'Neutral', description: 'A narrative-style voice, perfect for storytelling and guided sessions' },
    { id: 'onyx', name: 'Onyx', gender: 'Male', description: 'Deep and calming male voice' },
    { id: 'nova', name: 'Nova', gender: 'Female', description: 'Soothing and gentle female voice' },
    { id: 'sage', name: 'Sage', gender: 'Neutral', description: 'A voice of wisdom and clarity, ideal for educational content' },
  ];

  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';


  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activePreviewId, setActivePreviewId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingVoiceId, setLoadingVoiceId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [previewUrls, setPreviewUrls] = useState({});
  const [previewError, setPreviewError] = useState(null);
  const [isCreatingAudio, setIsCreatingAudio] = useState(false);
  const maxRetryAttempts = useRef(3);
  const retryCounter = useRef(0);
  const progressTimerId = useRef(null);

  // Log rendering info
  useEffect(() => {
    console.log("VoiceStep rendered with selectedVoice:", selectedVoice);
    console.log("onCreateTTS is type:", typeof onCreateTTS);
  }, [selectedVoice, onCreateTTS]);

  // Initialize the audio element once
  useEffect(() => {
    if (!audioRef.current) audioRef.current = new Audio();
  }, []);

  // Track window resizing
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Setup audio event listeners
  useEffect(() => {
    if (!audioRef.current) audioRef.current = new Audio();
    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlaying(false);
      setActivePreviewId(null);
    };
    const handleError = (e) => {
      console.error('Audio playback error:', e);
      setIsPlaying(false);
      setActivePreviewId(null);
      toast.error(`Playback error: ${e.message || 'Unknown error'}`);
    };
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);
    return () => {
      audio.pause();
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, []);

  // Ensure a valid selectedVoice is set
  useEffect(() => {
    if (!selectedVoice || !voices.some(v => v.id === selectedVoice)) {
      console.log("Setting default voice to 'onyx'");
      setSelectedVoice('onyx');
    }
  }, [selectedVoice, setSelectedVoice]);

  // Helper to return the base URL
  const getBaseUrl = () => process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

  // Fetch voice preview with retry and exponential backoff
  const fetchVoicePreview = async (voiceId) => {
    setPreviewError(null);
    try {
      if (!voiceId) throw new Error('Voice ID is required');
      if (previewUrls[voiceId]) {
        console.log(`Using cached preview for ${voiceId}`);
        return previewUrls[voiceId];
      }
      
      const baseUrl = getBaseUrl();
      if (!baseUrl) throw new Error('API base URL is not configured');
      
      console.log(`Fetching voice preview for ${voiceId} from ${baseUrl}`);
      
      retryCounter.current = 0;
      const fetchWithRetry = async () => {
        try {
          const encodedVoiceId = encodeURIComponent(voiceId);
          const apiUrl = `${baseUrl}/voice-preview/${encodedVoiceId}`;
          
          // For debugging
          console.log(`Making request to: ${apiUrl}`);
          
          // IMPORTANT CHANGE: Adjust fetch parameters to match your backend
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: { 
              'Accept': 'audio/mpeg, */*',
              'Origin': window.location.origin
            },
            // The credentials inclusion might be causing issues if cookies aren't needed
            credentials: 'same-origin', // Changed from 'include' to 'same-origin'
            // Don't explicitly set mode to 'cors' as it might enforce stricter rules
            // mode: 'cors' 
          });
          
          if (!response.ok) {
            const errorText = await response.text();
            console.error(`Server responded with ${response.status}: ${errorText}`);
            throw new Error(`Server responded with ${response.status}`);
          }
          
          const audioBlob = await response.blob();
          
          if (!audioBlob || audioBlob.size === 0) {
            console.error('Received empty audio data');
            throw new Error('Received empty audio data');
          }
          
          console.log(`Successfully received audio blob: ${audioBlob.size} bytes, type: ${audioBlob.type}`);
          
          const blobUrl = URL.createObjectURL(audioBlob);
          setPreviewUrls(prev => ({ ...prev, [voiceId]: blobUrl }));
          return blobUrl;
        } catch (error) {
          retryCounter.current += 1;
          if (retryCounter.current < maxRetryAttempts.current) {
            console.log(`Retry attempt ${retryCounter.current} for voice ${voiceId}`);
            const delay = Math.pow(2, retryCounter.current) * 500;
            await new Promise(resolve => setTimeout(resolve, delay));
            return fetchWithRetry();
          } else {
            throw error;
          }
        }
      };
      
      return await fetchWithRetry();
    } catch (error) {
      console.error(`Error fetching preview for ${voiceId}:`, error);
      
      // Create a fallback preview when server request fails
      setPreviewError(`Unable to load voice preview from server. Using a sample tone instead.`);
      return createFallbackAudio(voiceId);
    }
  };

  const createFallbackAudio = useCallback((voiceName) => {
    // Create a basic fallback audio implementation
    const fallbackName = voiceName || 'default';
    const timestamp = Date.now();
    const placeholderFilename = `TTS_fallback_${fallbackName}_${timestamp}.mp3`;
    
    console.log("Creating fallback audio placeholder:", placeholderFilename);
    return {
      filename: placeholderFilename,
      filePath: `${baseUrl}/tts/${placeholderFilename}`,
      placeholder: true
    };
  }, [baseUrl]);

  // Toggle preview playback with retry logic for audio.play()
  const togglePreview = async (voice, event) => {
    if (event) event.stopPropagation();
    if (!audioRef.current) audioRef.current = new Audio();
    const audio = audioRef.current;
    if (isPlaying && activePreviewId === voice.id) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
      setActivePreviewId(null);
      return;
    }
    audio.pause();
    audio.currentTime = 0;
    try {
      setIsLoading(true);
      setLoadingVoiceId(voice.id);
      setPreviewError(null);
      const previewUrl = await fetchVoicePreview(voice.id);
      audio.src = previewUrl;
      await new Promise(resolve => setTimeout(resolve, 100));
      const playWithRetry = async (maxRetries = 2) => {
        for (let attempt = 0; attempt <= maxRetries; attempt++) {
          try {
            await audio.play();
            return true;
          } catch (playError) {
            if (playError.name === 'AbortError' && attempt < maxRetries) {
              console.log(`Play attempt ${attempt + 1} for ${voice.id} aborted, retrying...`);
              await new Promise(resolve => setTimeout(resolve, 300));
              continue;
            }
            throw playError;
          }
        }
        return false;
      };
      await playWithRetry();
      setIsPlaying(true);
      setActivePreviewId(voice.id);
    } catch (error) {
      console.error('Error playing preview:', error);
      if (error.name === 'NotAllowedError') {
        setPreviewError(`Browser permission needed. Please click again.`);
      } else if (error.name === 'AbortError') {
        setPreviewError(`Playback interrupted. Please try again.`);
      } else {
        setPreviewError(`Unable to play preview for ${voice.name}. ${error.message}`);
      }
    } finally {
      setIsLoading(false);
      setLoadingVoiceId(null);
    }
  };

  // Handle Create Audio button click
  const handleCreateAudio = async () => {
    console.log("Create Audio button clicked");
    if (!selectedVoice) {
      toast.error("Please select a voice first");
      return;
    }
    setIsCreatingAudio(true);
    setPreviewError(null);
    const safetyTimer = setTimeout(() => {
      if (isCreatingAudio) {
        toast.info("Voice generation is taking longer than expected but will continue in the background.", {
          toastId: 'voice-generation-background',
          autoClose: 5000
        });
      }
    }, 10000);
    try {
      toast.info("Voice generation started. You'll be taken to music selection when ready.", {
        toastId: 'voice-generation-start',
        autoClose: 3000
      });
      await onCreateTTS();
      console.log("onCreateTTS completed successfully");
    } catch (error) {
      console.error("Error in onCreateTTS:", error);
      setPreviewError(`Error creating audio: ${error.message}`);
      toast.error(`Voice generation encountered an issue: ${error.message}`, {
        toastId: 'tts-error',
        autoClose: 5000
      });
      setTimeout(() => {
        toast.info("You can still continue with a placeholder voice in the next step", {
          toastId: 'tts-fallback-info',
          autoClose: 5000
        });
      }, 1000);
    } finally {
      clearTimeout(safetyTimer);
      // In your app, navigation to the next step might clear this overlay
      setTimeout(() => {
        if (isCreatingAudio) setIsCreatingAudio(false);
      }, 15000);
    }
  };

  // Helpers for responsive styling and gender colors
  const getGenderGradient = (gender) => {
    if (gender === 'Female') return 'linear-gradient(45deg, #ff9966, #ff5e62)';
    if (gender === 'Male') return 'linear-gradient(45deg, #2193b0, #6dd5ed)';
    if (gender === 'Neutral') return 'linear-gradient(45deg, #8E2DE2, #4A00E0)';
    return 'linear-gradient(45deg, #8E2DE2, #4A00E0)';
  };
  const getGenderSymbol = (gender) => {
    if (gender === 'Female') return '♀';
    if (gender === 'Male') return '♂';
    return '⚪';
  };

  const isMobile = windowWidth < 768;
  const isSmallMobile = windowWidth < 480;
  const cardPadding = isSmallMobile ? '12px' : '16px';
  const fontSize = isSmallMobile ? '0.9rem' : '1rem';

  return (
    <div className="step-container" style={{ padding: isMobile ? '16px' : '20px', maxWidth: '100%' }}>
      <div className="step-header">
        <h2 className="greeting-title" style={{ color: '#ffffff', textAlign: isMobile ? 'center' : 'left' }}>
          Choose a Voice
        </h2>
        <p className="greeting-subtitle" style={{ color: '#aaaaaa', textAlign: isMobile ? 'center' : 'left' }}>
          Select the perfect narrator for your meditation
        </p>
      </div>

      {/* Instructions for preview */}
      <div style={{
        backgroundColor: 'rgba(125, 18, 255, 0.08)',
        borderRadius: '10px',
        padding: '12px',
        margin: '16px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        color: '#ffffff',
        fontSize: isSmallMobile ? '0.85rem' : '0.9rem',
        textAlign: 'center'
      }}>
        <IoPlayCircle size={18} />
        Tap any voice card to select, or use the play button to preview
      </div>

      {/* Error message */}
      {previewError && (
        <div style={{
          padding: '12px',
          marginBottom: '16px',
          backgroundColor: 'rgba(255, 0, 0, 0.1)',
          border: '1px solid rgba(255, 0, 0, 0.2)',
          borderRadius: '8px',
          color: '#ff6b6b',
          fontSize: isSmallMobile ? '0.85rem' : '0.9rem',
          textAlign: 'center'
        }}>
          {previewError}
        </div>
      )}

      {/* Voice grid */}
      <div className="voice-grid" style={{
        display: 'grid',
        gridTemplateColumns: isSmallMobile ? '1fr' : isMobile ? 'repeat(2, 1fr)' : 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: isSmallMobile ? '12px' : '16px',
        margin: '16px 0 24px'
      }}>
        {voices.map((voice) => (
          <div
            key={voice.id}
            onClick={() => setSelectedVoice(voice.id)}
            style={{
              background: '#1c1c1e',
              border: selectedVoice === voice.id ? '2px solid #7d12ff' : '1px solid rgba(255, 255, 255, 0.08)',
              borderRadius: '12px',
              padding: cardPadding,
              cursor: 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              backgroundColor: selectedVoice === voice.id ? 'rgba(125, 18, 255, 0.1)' : '#1c1c1e',
              boxShadow: selectedVoice === voice.id ? '0 4px 12px rgba(125, 18, 255, 0.15)' : 'none',
              transform: selectedVoice === voice.id ? 'translateY(-2px)' : 'none',
              minHeight: '100px'
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: isSmallMobile ? '8px' : '12px',
              paddingRight: '40px',
              height: '100%'
            }}>
              <div style={{
                width: isSmallMobile ? '40px' : '48px',
                height: isSmallMobile ? '40px' : '48px',
                borderRadius: '8px',
                background: getGenderGradient(voice.gender),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: isSmallMobile ? '20px' : '24px',
                color: '#ffffff',
                flexShrink: 0
              }}>
                {getGenderSymbol(voice.gender)}
              </div>
              <div style={{ flex: 1, minWidth: 0 }}>
                <h3 style={{
                  margin: '0 0 4px',
                  color: '#ffffff',
                  fontSize: isSmallMobile ? '1rem' : '1.1rem',
                  fontWeight: '500'
                }}>
                  {voice.name}
                </h3>
                <p style={{
                  fontSize: isSmallMobile ? '0.8rem' : '0.9rem',
                  color: '#999',
                  margin: 0,
                  lineHeight: '1.3',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {isMobile ? voice.gender : voice.description}
                </p>
              </div>
            </div>
            {selectedVoice === voice.id && (
              <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                width: '20px',
                height: '20px',
                backgroundColor: '#7d12ff',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <IoCheckmarkCircle size={16} color="white" />
              </div>
            )}
            <button
              onClick={(e) => togglePreview(voice, e)}
              disabled={isLoading && loadingVoiceId !== voice.id}
              style={{
                position: 'absolute',
                bottom: cardPadding,
                right: cardPadding,
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                backgroundColor: (isPlaying && activePreviewId === voice.id) 
                  ? 'rgba(255, 71, 87, 0.1)' 
                  : 'rgba(125, 18, 255, 0.1)',
                color: (isPlaying && activePreviewId === voice.id) ? '#ff4757' : '#7d12ff',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 5,
                padding: 0,
                transition: 'background-color 0.2s ease, transform 0.2s ease',
                opacity: (isLoading && loadingVoiceId !== voice.id) ? 0.5 : 1,
              }}
              className="play-button"
              aria-label={isPlaying && activePreviewId === voice.id ? "Stop preview" : "Play preview"}
            >
              {isLoading && loadingVoiceId === voice.id ? (
                <div className="loading-spinner" style={{
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                  border: '2px solid rgba(125, 18, 255, 0.3)',
                  borderTopColor: '#7d12ff',
                  animation: 'spin 1s linear infinite'
                }}></div>
              ) : isPlaying && activePreviewId === voice.id ? (
                <IoStopCircle size={22} />
              ) : (
                <IoPlayCircle size={22} />
              )}
            </button>
          </div>
        ))}
      </div>

            <div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '24px auto 0', // Center the container horizontally with auto left/right margins
        padding: '0',  // Remove padding from container
        width: '100%', // Take full width
        maxWidth: '500px' // But constrain to 500px max
      }}>
        <button
          onClick={handleCreateAudio}
          disabled={!selectedVoice || isCreatingAudio}
          style={{
            background: '#7d12ff',
            color: '#fff',
            border: 'none',
            borderRadius: '12px',
            padding: isSmallMobile ? '12px 24px' : '14px 32px', // Horizontal padding added to button instead
            fontSize: isSmallMobile ? '1rem' : '1.1rem',
            fontWeight: '500',
            cursor: selectedVoice && !isCreatingAudio ? 'pointer' : 'not-allowed',
            width: isSmallMobile ? 'calc(100% - 32px)' : 'auto', // Full width minus margins on mobile, auto on desktop
            minWidth: isSmallMobile ? 'auto' : '260px', // Minimum width on non-mobile
            maxWidth: '100%', // Don't exceed container
            opacity: (!selectedVoice || isCreatingAudio) ? 0.5 : 1,
            transition: 'all 0.2s ease',
            boxShadow: '0 4px 12px rgba(125, 18, 255, 0.25)',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            margin: '0 16px' // Add margin to the button itself on mobile
          }}
          onMouseEnter={(e) => {
            if (selectedVoice && !isCreatingAudio) {
              e.currentTarget.style.transform = 'translateY(-2px)';
              e.currentTarget.style.boxShadow = '0 6px 16px rgba(125, 18, 255, 0.35)';
            }
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = '';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(125, 18, 255, 0.25)';
          }}
        >
          {isCreatingAudio ? (
            <>
              <div className="loading-spinner" style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid rgba(255, 255, 255, 0.3)',
                borderTopColor: '#ffffff',
                animation: 'spin 1s linear infinite',
                marginRight: '10px'
              }}></div>
              Creating Audio...
            </>
          ) : (
            `Create Audio with ${selectedVoice ? voices.find(v => v.id === selectedVoice)?.name : 'Selected Voice'}`
          )}
        </button>
      </div>

      {isCreatingAudio && (
        <div className="processing-overlay" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)'
        }}>
          <div style={{
            color: '#fff',
            marginBottom: '20px',
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}>
            Creating Meditation Voice
          </div>
          <div style={{
            width: '80%',
            maxWidth: '400px',
            height: '8px',
            backgroundColor: 'rgba(125, 18, 255, 0.2)',
            borderRadius: '4px',
            overflow: 'hidden',
            marginBottom: '20px'
          }}>
            <div className="progress-animation" style={{
              height: '100%',
              width: '30%',
              backgroundColor: '#7d12ff',
              borderRadius: '4px',
              animation: 'progress-move 1.5s infinite alternate ease-in-out'
            }}></div>
          </div>
          <div style={{
            color: '#ccc',
            fontSize: '1rem',
            textAlign: 'center',
            maxWidth: '80%'
          }}>
            <p>Your audio is being prepared...</p>
            <p style={{ fontSize: '0.9rem', opacity: 0.8 }}>
              You'll be automatically taken to the next step when ready
            </p>
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes progress-move {
          0% { transform: translateX(0); width: 30%; }
          100% { transform: translateX(233%); width: 30%; }
        }
        .play-button:hover {
          background-color: ${isPlaying ? 'rgba(255, 71, 87, 0.2)' : 'rgba(125, 18, 255, 0.2)'} !important;
          transform: scale(1.05);
        }
        .play-button:active {
          transform: scale(0.95);
        }
        .voice-grid > div:hover {
          transform: ${selectedVoice ? 'translateY(-2px)' : 'translateY(-1px)'};
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
        .voice-grid > div:active {
          transform: scale(0.99);
        }
        @media (max-width: 768px) {
          .play-button {
            min-width: 32px;
            min-height: 32px;
          }
          .voice-grid > div {
            -webkit-tap-highlight-color: transparent;
            touch-action: manipulation;
          }
        }
      `}</style>
    </div>
  );
};

export default VoiceStep;
