// PersistentTTSP.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import globalAudioState from '../audio/globalAudioState';

/**
 * A robust, persistent TTS progress indicator that follows the user across steps
 * and accurately reflects the TTS processing state.
 */
const PersistentTTSProgressIndicator = ({
  onComplete = () => {},
  colorScheme = "purple",
  zIndex = 1000,
  attachToBottom = true,
  attachToStep = null,
  showTTSKey = true
}) => {
  // Create a unique instance ID for debugging
  const instanceId = useRef(`tts-indicator-${Date.now()}-${Math.floor(Math.random() * 1000)}`).current;
  
  // State for tracking TTS progress and visibility
  const [progressValue, setProgressValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [currentPhase, setCurrentPhase] = useState('initializing');
  const [statusMessage, setStatusMessage] = useState('Preparing voice generation...');
  const [detailMessage, setDetailMessage] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [ttsKey, setTtsKey] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  
  // Refs for tracking lifecycle and preventing stale state
  const completedRef = useRef(false);
  const progressRef = useRef(0);
  const visibilityRef = useRef(false);
  const instanceMountedRef = useRef(true);
  const subscribedRef = useRef(false);
  const lastUpdateTimeRef = useRef(Date.now());
  const registeredCompletionRef = useRef(false);
  
  // Determine if we're on mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // Update mobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Register progress indicator in global registry for coordination
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Create registry if it doesn't exist
      if (!window.__ttsProgressIndicators) {
        window.__ttsProgressIndicators = {};
      }
      
      // Register this instance
      window.__ttsProgressIndicators[instanceId] = {
        mountTime: Date.now(),
        setProgress: (progress) => {
          if (instanceMountedRef.current) {
            setProgressValue(progress);
            progressRef.current = progress;
          }
        },
        setVisibility: (visible) => {
          if (instanceMountedRef.current) {
            setIsVisible(visible);
            visibilityRef.current = visible;
          }
        },
        completeProgress: () => {
          if (instanceMountedRef.current && !completedRef.current) {
            completedRef.current = true;
            setProgressValue(100);
            progressRef.current = 100;
            
            // Call completion callback after a short delay
            setTimeout(() => {
              if (instanceMountedRef.current) {
                onComplete();
              }
            }, 500);
          }
        }
      };
      
      // Set mounted state
      setIsMounted(true);
    }
    
    return () => {
      instanceMountedRef.current = false;
      
      // Remove from registry
      if (typeof window !== 'undefined' && window.__ttsProgressIndicators) {
        delete window.__ttsProgressIndicators[instanceId];
      }
    };
  }, [instanceId, onComplete]);
  
  // Function to safely update progress with minimum/maximum enforcement
  const updateProgress = useCallback((newProgress) => {
    if (!instanceMountedRef.current || completedRef.current) return;
    
    // Handle invalid inputs
    if (typeof newProgress !== 'number' || isNaN(newProgress)) {
      console.warn(`[TTSProgressIndicator ${instanceId}] Invalid progress value:`, newProgress);
      return;
    }
    
    // Clamp value and prevent regression
    const validProgress = Math.max(0, Math.min(100, newProgress));
    const currentProgress = progressRef.current;
    
    // Prevent regression (progress going backward)
    if (validProgress < currentProgress) {
      console.log(`[TTSProgressIndicator ${instanceId}] Prevented progress decrease from ${currentProgress}% to ${validProgress}%`);
      return;
    }
    
    // Update state and refs
    setProgressValue(validProgress);
    progressRef.current = validProgress;
    lastUpdateTimeRef.current = Date.now();
    
    // Update window flags for cross-component communication
    if (typeof window !== 'undefined') {
      window.__lastTTSProgress = validProgress;
    }
    
    // Set indicator phase based on progress
    if (validProgress < 5) {
      setCurrentPhase('initializing');
      setStatusMessage('Initializing voice generation');
      setDetailMessage('Starting the process...');
    } else if (validProgress < 30) {
      setCurrentPhase('starting');
      setStatusMessage('Voice generation in progress');
      setDetailMessage('Creating your meditation voice...');
    } else if (validProgress < 65) {
      setCurrentPhase('processing');
      setStatusMessage('Voice processing');
      setDetailMessage('Processing your voice audio...');
    } else if (validProgress < 90) {
      setCurrentPhase('finalizing');
      setStatusMessage('Almost done');
      setDetailMessage('Finalizing your meditation voice...');
    } else if (validProgress >= 95) {
      setCurrentPhase('complete');
      setStatusMessage('Voice generation complete');
      setDetailMessage('Your meditation voice is ready');
      
      // If we reach 100%, mark as complete
      if (validProgress >= 100 && !completedRef.current) {
        completeProgress();
      }
    }
  }, [instanceId]);
  
  // Function to complete progress
  const completeProgress = useCallback(() => {
    if (completedRef.current || !instanceMountedRef.current) return;
    
    console.log(`[TTSProgressIndicator ${instanceId}] Completing progress`);
    completedRef.current = true;
    
    // Set progress to 100%
    setProgressValue(100);
    progressRef.current = 100;
    
    // Update phases
    setCurrentPhase('complete');
    setStatusMessage('Voice generation complete!');
    setDetailMessage('Your meditation voice is ready');
    
    // Set global completion flag
    if (typeof window !== 'undefined') {
      window.__ttsCompleted = true;
      window.__ttsCompletionTime = Date.now();
      window.__lastTTSProgress = 100;
    }
    
    // Notify global registry
    if (typeof window !== 'undefined' && window.__ttsProgressIndicators) {
      Object.values(window.__ttsProgressIndicators).forEach(indicator => {
        if (indicator && typeof indicator.completeProgress === 'function') {
          indicator.completeProgress();
        }
      });
    }
    
    // Call completion callback after a short delay
    setTimeout(() => {
      if (instanceMountedRef.current && !registeredCompletionRef.current) {
        registeredCompletionRef.current = true;
        onComplete();
      }
    }, 500);
    
    // Auto-hide indicator after completion with delay
    setTimeout(() => {
      if (instanceMountedRef.current) {
        setIsMinimized(true);
      }
    }, 3000);
  }, [instanceId, onComplete]);
  
  // Subscribe to global state events for progress updates
  useEffect(() => {
    if (subscribedRef.current || !globalAudioState) return;
    
    try {
      // Subscribe to progress updates
      const progressUnsubscribe = globalAudioState.subscribe('tts-progress-updated', (data) => {
        if (!instanceMountedRef.current) return;
        
        if (data && typeof data.progress === 'number') {
          console.log(`[TTSProgressIndicator ${instanceId}] Progress update:`, Math.round(data.progress));
          updateProgress(data.progress);
          
          // Show the indicator when we get progress updates
          if (!visibilityRef.current) {
            setIsVisible(true);
            visibilityRef.current = true;
          }
        }
      });
      
      // Subscribe to TTS processing started event
      const startedUnsubscribe = globalAudioState.subscribe('tts-processing-started', () => {
        if (!instanceMountedRef.current) return;
        
        console.log(`[TTSProgressIndicator ${instanceId}] TTS processing started`);
        setIsVisible(true);
        visibilityRef.current = true;
        updateProgress(0);
      });
      
      // Subscribe to TTS key updates
      const keyUpdateUnsubscribe = globalAudioState.subscribe('tts-key-updated', (data) => {
        if (!instanceMountedRef.current) return;
        
        console.log(`[TTSProgressIndicator ${instanceId}] TTS key updated:`, data.key);
        setTtsKey(data.key);
        
        // When a key is set, ensure we're at least at 95%
        if (progressRef.current < 95) {
          updateProgress(95);
        }
        
        // Make sure the indicator is visible
        if (!visibilityRef.current) {
          setIsVisible(true);
          visibilityRef.current = true;
        }
      });
      
      // Subscribe to TTS completion
      const completionUnsubscribe = globalAudioState.subscribe('tts-processing-completed', () => {
        if (!instanceMountedRef.current) return;
        
        console.log(`[TTSProgressIndicator ${instanceId}] TTS processing completed`);
        completeProgress();
      });
      
      // Mark as subscribed
      subscribedRef.current = true;
      
      // Get current state from globalAudioState
      if (globalAudioState.state) {
        // Check if there's already a progress value
        if (typeof globalAudioState.state.ttsProgress === 'number') {
          updateProgress(globalAudioState.state.ttsProgress);
        }
        
        // Check if there's a TTS key
        if (globalAudioState.state.ttsKey) {
          setTtsKey(globalAudioState.state.ttsKey);
        }
        
        // Check if processing is already happening
        if (globalAudioState.state.ttsProcessing) {
          setIsVisible(true);
          visibilityRef.current = true;
        }
      }
      
      // Check window globals for completion status
      if (typeof window !== 'undefined' && window.__ttsCompleted === true) {
        completeProgress();
      }
      
      // Set up unsubscribes for cleanup
      return () => {
        progressUnsubscribe();
        startedUnsubscribe();
        keyUpdateUnsubscribe();
        completionUnsubscribe();
      };
    } catch (error) {
      console.error(`[TTSProgressIndicator ${instanceId}] Error setting up subscriptions:`, error);
    }
  }, [instanceId, updateProgress, completeProgress, globalAudioState]);
  
  // Set up automatic timeout completion for stuck progress
  useEffect(() => {
    // Safety timeout for high progress values
    let highProgressTimeout = null;
    if (progressValue > 90 && progressValue < 100 && !completedRef.current) {
      highProgressTimeout = setTimeout(() => {
        const timeSinceUpdate = Date.now() - lastUpdateTimeRef.current;
        if (timeSinceUpdate > 8000 && instanceMountedRef.current) {
          console.warn(`[TTSProgressIndicator ${instanceId}] High progress timeout reached. Forcing completion.`);
          completeProgress();
        }
      }, 8000);
    }
    
    // General safety timeout to prevent stuck indicators
    const generalTimeout = setTimeout(() => {
      const timeSinceUpdate = Date.now() - lastUpdateTimeRef.current;
      if (timeSinceUpdate > 30000 && instanceMountedRef.current && !completedRef.current) {
        console.warn(`[TTSProgressIndicator ${instanceId}] General timeout reached. Forcing completion.`);
        completeProgress();
      }
    }, 30000);
    
    // Auto-hide the indicator after a period of inactivity when not completed
    const inactivityTimeout = setTimeout(() => {
      const timeSinceUpdate = Date.now() - lastUpdateTimeRef.current;
      if (timeSinceUpdate > 10000 && instanceMountedRef.current && !completedRef.current && progressValue > 0) {
        setIsMinimized(true);
      }
    }, 10000);
    
    return () => {
      if (highProgressTimeout) clearTimeout(highProgressTimeout);
      if (generalTimeout) clearTimeout(generalTimeout);
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
    };
  }, [progressValue, instanceId, completeProgress]);
  
  // Handle visibility based on step attachment
  useEffect(() => {
    if (attachToStep !== null) {
      // Check if we're on the appropriate step
      if (typeof window !== 'undefined' && window.currentStep !== undefined) {
        const shouldBeVisible = window.currentStep === attachToStep;
        setIsVisible(shouldBeVisible);
        visibilityRef.current = shouldBeVisible;
      }
    }
  }, [attachToStep]);
  
  // Only show if we have a progress value > 0 or TTS is processing
  const shouldDisplay = isVisible && (
    progressValue > 0 || 
    (globalAudioState?.state?.ttsProcessing === true) || 
    currentPhase !== 'initializing'
  );
  
  // Don't render anything if we shouldn't display
  if (!shouldDisplay) return null;
  
  // Define color schemes
  const colorSchemes = {
    purple: {
      primary: '#7d12ff',
      secondary: '#5636f3',
      background: 'rgba(125,18,255,0.1)',
      border: 'rgba(125,18,255,0.2)',
      spinner: 'rgba(125,18,255,0.3)',
      spinnerActive: '#7d12ff',
      text: '#ffffff'
    },
    blue: {
      primary: '#2193b0',
      secondary: '#6dd5ed',
      background: 'rgba(33,147,176,0.1)',
      border: 'rgba(33,147,176,0.2)',
      spinner: 'rgba(33,147,176,0.3)',
      spinnerActive: '#2193b0',
      text: '#ffffff'
    },
    dark: {
      primary: '#7d12ff',
      secondary: '#5636f3',
      background: 'rgba(0,0,0,0.8)',
      border: 'rgba(255,255,255,0.1)',
      spinner: 'rgba(125,18,255,0.5)',
      spinnerActive: '#7d12ff',
      text: '#ffffff'
    }
  };
  
  const colors = colorSchemes[colorScheme] || colorSchemes.purple;
  const displayProgress = Math.min(Math.round(progressValue), 100);
  
  // Create a minimized version that can be expanded
  if (isMinimized) {
    return (
      <div 
        style={{
          position: attachToBottom ? 'fixed' : 'sticky',
          bottom: attachToBottom ? '20px' : 'auto',
          right: attachToBottom ? '20px' : 'auto',
          zIndex: zIndex,
          marginTop: attachToBottom ? '0' : '16px',
          width: isMobile ? '48px' : '56px',
          height: isMobile ? '48px' : '56px',
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
          cursor: 'pointer',
          transition: 'all 0.3s ease'
        }}
        onClick={() => setIsMinimized(false)}
      >
        <div 
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            background: `conic-gradient(
              ${colors.primary} ${displayProgress}%, 
              rgba(255, 255, 255, 0.1) ${displayProgress}%
            )`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          <div 
            style={{
              width: '26px',
              height: '26px',
              borderRadius: '50%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '10px',
              fontWeight: 'bold'
            }}
          >
            {displayProgress}%
          </div>
        </div>
      </div>
    );
  }
  
  // Full progress indicator
  return (
    <div style={{
      position: attachToBottom ? 'fixed' : 'sticky',
      bottom: attachToBottom ? '20px' : 'auto',
      left: attachToBottom ? '50%' : 'auto',
      transform: attachToBottom ? 'translateX(-50%)' : 'none',
      zIndex: zIndex,
      width: isMobile ? '90%' : '400px',
      maxWidth: isMobile ? '90vw' : '90%',
      margin: attachToBottom ? '0' : '16px auto',
      padding: isMobile ? '12px' : '16px',
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      borderRadius: '12px',
      color: colors.text,
      boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
      border: `1px solid ${colors.border}`,
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      animation: 'fadeIn 0.3s ease-in-out',
      transition: 'all 0.3s ease'
    }}>
      {/* Background pulse effect */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `linear-gradient(90deg, transparent, ${colors.background}, transparent)`,
        backgroundSize: '200% 100%',
        animation: 'pulse-bg 2s infinite',
        opacity: 0.3,
        zIndex: 0,
        borderRadius: '12px',
        pointerEvents: 'none'
      }} />
      
      {/* Header section with title and minimize button */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 1
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            border: `3px solid ${colors.spinner}`,
            borderTopColor: colors.spinnerActive,
            animation: 'spin 1s linear infinite',
            opacity: progressValue >= 100 ? 0 : 1,
            transition: 'opacity 0.3s ease'
          }} />
          <div style={{ 
            fontWeight: '600', 
            fontSize: isMobile ? '14px' : '16px',
            textShadow: '0 1px 2px rgba(0,0,0,0.3)'
          }}>
            {statusMessage}
          </div>
        </div>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px'
        }}>
          <div style={{ 
            fontWeight: '600',
            fontSize: isMobile ? '13px' : '14px'
          }}>
            {displayProgress}%
          </div>
          <button 
            onClick={() => setIsMinimized(true)}
            style={{
              width: '24px',
              height: '24px',
              background: 'rgba(255,255,255,0.1)',
              border: 'none',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: 'white',
              fontSize: '14px',
              padding: 0
            }}
          >
            <span style={{ transform: 'translateY(-1px)' }}>−</span>
          </button>
        </div>
      </div>
      
      {/* Progress bar */}
      <div style={{
        width: '100%',
        height: '6px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '3px',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
      }}>
        <div style={{
          height: '100%',
          width: `${displayProgress}%`,
          background: `linear-gradient(90deg, ${colors.secondary}, ${colors.primary})`,
          borderRadius: '3px',
          transition: 'width 0.3s ease',
        }} />
      </div>
      
      {/* Status message */}
      <p style={{
        margin: 0,
        fontSize: isMobile ? '13px' : '14px',
        opacity: 0.8,
        lineHeight: 1.4,
        position: 'relative',
        zIndex: 1,
      }}>
        {detailMessage}
      </p>
      
      {/* TTS Key indicator when available */}
      {showTTSKey && ttsKey && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 12px',
          backgroundColor: 'rgba(0, 255, 0, 0.1)',
          borderRadius: '6px',
          border: '1px solid rgba(0, 255, 0, 0.2)',
          marginTop: '4px',
          position: 'relative',
          zIndex: 1,
          fontSize: '12px'
        }}>
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#4CAF50"
               strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/>
            <polyline points="22 4 12 14.01 9 11.01"/>
          </svg>
          <span style={{ color: '#4CAF50', fontWeight: '500' }}>
            Voice processing ready for next step
          </span>
        </div>
      )}
      
      {/* CSS Animations */}
      <style>{`
        @keyframes spin { to { transform: rotate(360deg); } }
        @keyframes pulse-bg { 0%, 100% { background-position: 100% 0%; } 50% { background-position: 0% 0%; } }
        @keyframes fadeIn { from { opacity: 0; transform: translateY(10px); } to { opacity: 1; transform: translateY(0); } }
      `}</style>
    </div>
  );
};

export default PersistentTTSProgressIndicator;