// src/pages/MeditationApp/components/steps/MusicSelection.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  IoMusicalNotesOutline,
  IoPlayCircleOutline,
  IoPauseCircleOutline,
  IoCheckmarkCircle
} from 'react-icons/io5';

// Import audioService for consistent S3 access
import audioService from '../../../../services/audioService';
import globalAudioState from '../audio/globalAudioState';
import TTSProgressIndicator from '../common/TTSProgressIndicator';
//
// Single card for each track
//
const MusicCard = ({
  track,
  isSelected,
  isPlaying,
  onSelect,
  onTogglePlay,
  isMobile,
  isSmallMobile
}) => {
  if (!track || !track.name) return null;

  const trackName = track.name;
  const displayName =
    track.displayName ||
    trackName.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  const moodLabel = track.moodLabel || 'Meditation Music';
  const coverArt =
    track.coverArt ||
    'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=300&h=300&fit=crop&auto=format';

  return (
    <div
      className={`music-card ${isSelected ? 'selected' : ''}`}
      onClick={() => onSelect(trackName)}
      data-testid={`music-card-${trackName}`}
      style={{
        background: 'rgba(25, 25, 35, 0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        borderRadius: '12px',
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        boxShadow: isSelected
          ? '0 0 0 2px #7d12ff, 0 4px 12px rgba(0, 0, 0, 0.3)'
          : '0 4px 8px rgba(0, 0, 0, 0.2)',
        transform: isSelected ? 'translateY(-2px)' : 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: isSmallMobile ? '160px' : '180px'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100px',
          background: `url(${coverArt})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative'
        }}
      >
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              width: isSmallMobile ? '20px' : '24px',
              height: isSmallMobile ? '20px' : '24px',
              borderRadius: '50%',
              background: '#7d12ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 0 10px rgba(125, 18, 255, 0.5)',
              zIndex: 2
            }}
          >
            <IoCheckmarkCircle size={isSmallMobile ? 16 : 18} color="white" />
          </div>
        )}
      </div>
      <div
        style={{
          padding: isSmallMobile ? '8px' : '12px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative'
        }}
      >
        <div style={{ paddingRight: '32px' }}>
          <div
            style={{
              fontSize: isSmallMobile ? '0.85rem' : '0.95rem',
              fontWeight: '500',
              color: '#ffffff',
              marginBottom: '4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            title={displayName}
          >
            {displayName}
          </div>
          <div
            style={{
              fontSize: isSmallMobile ? '0.75rem' : '0.85rem',
              color: 'rgba(255, 255, 255, 0.7)',
              lineHeight: '1.2',
              height: '2.4em',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical'
            }}
            title={moodLabel}
          >
            {moodLabel}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onTogglePlay(track);
          }}
          style={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            background: 'rgba(125, 18, 255, 0.1)',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 2,
            padding: 0
          }}
          className="play-button"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? (
            <IoPauseCircleOutline size={22} color="#7d12ff" />
          ) : (
            <IoPlayCircleOutline size={22} color="#7d12ff" />
          )}
        </button>
      </div>
    </div>
  );
};

// Main MusicSelection Component
const MusicSelection = ({
  musicLibrary = [],
  selectedMusic,
  setSelectedMusic,
  isMusicLoading,
  markStepCompleted,
  goToNextStep,
  isSessionCreated = true,
  baseUrl,
  isLoading,
  isBackgroundTTSProcessing = false,
  backgroundTTSProgress = 0,
  setBackgroundTTSProgress = null,
  onMusicLibraryLoaded = null, // callback
  setIsBackgroundTTSProcessing = null,
  completedSteps = []
}) => {
  // Local state for filtering, selection, etc.
  const [filter, setFilter] = useState('all');
  const [moods] = useState([
    'calm',
    'ocean',
    'rain',
    'forest',
    'night',
    'ambient',
    'meditation'
  ]);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const [previewTrack, setPreviewTrack] = useState(null);
  const [localSelection, setLocalSelection] = useState(selectedMusic || '');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [localMusicLibrary, setLocalMusicLibrary] = useState([]);
  const [isLocalMusicLoading, setIsLocalMusicLoading] = useState(true);
  const [showTTSIndicator, setShowTTSIndicator] = useState(false);
  const [ttsProgress, setTtsProgress] = useState(0);
  const [ttsKey, setTtsKey] = useState(null);

  // For the Emergency Skip Button
  const [loadingStartTime] = useState(Date.now());
  const [loadingDuration, setLoadingDuration] = useState(0);

  const audioRef = useRef(null);
  const previewTimeoutRef = useRef(null);
  const mounted = useRef(true);
  const previousSelectionRef = useRef(selectedMusic);
  const libraryLoadedRef = useRef(false);

  useEffect(() => {
    // Check if TTS is processing from session or window
    const isTTSProcessing = 
      (typeof sessionStorage !== 'undefined' && sessionStorage.getItem("ttsProcessing") === "true") ||
      (typeof window !== 'undefined' && window.__ttsProcessing === true);
    
    // Check for TTS key
    const storedTTSKey = 
      (typeof sessionStorage !== 'undefined') ? sessionStorage.getItem("ttsKey") : null;
    
    if (isTTSProcessing || storedTTSKey) {
      setShowTTSIndicator(true);
      if (storedTTSKey) {
        setTtsKey(storedTTSKey);
      }
    }
    
    // Subscribe to global audio state events if available
    if (globalAudioState) {
      const progressSub = globalAudioState.subscribe('tts-progress-updated', (data) => {
        if (data && typeof data.progress === 'number') {
          setTtsProgress(data.progress);
          setShowTTSIndicator(true);
        }
      });
      
      const keySub = globalAudioState.subscribe('tts-key-updated', (data) => {
        if (data && data.key) {
          setTtsKey(data.key);
          setShowTTSIndicator(true);
        }
      });
      
      // Clean up subscriptions
      return () => {
        progressSub();
        keySub();
      };
    }
  }, []);

  // Notify parent component when music library is loaded and ready
  useEffect(() => {
    if (Array.isArray(localMusicLibrary) && 
        localMusicLibrary.length > 0 && 
        typeof onMusicLibraryLoaded === 'function' &&
        !libraryLoadedRef.current) {
      
      console.log("MusicSelection: Notifying parent that music library is loaded with", localMusicLibrary.length, "tracks");
      onMusicLibraryLoaded(localMusicLibrary);
      libraryLoadedRef.current = true;
    }
  }, [localMusicLibrary, onMusicLibraryLoaded]);

  // Update loading duration every second
  useEffect(() => {
    const timer = setInterval(() => {
      if (!mounted.current) return;
      setLoadingDuration(Date.now() - loadingStartTime);
    }, 1000);
    return () => clearInterval(timer);
  }, [loadingStartTime]);

  // Define isMobile and isSmallMobile based on windowWidth
  const isMobile = windowWidth <= 768;
  const isSmallMobile = windowWidth <= 480;

  const apiBaseUrl = baseUrl || process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

  // Cover art options
  const coverArtOptions = [
    'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1504851149312-7a075b496cc7?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1519681393784-d120267933ba?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1448375240586-882707db888b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1507041957456-9c397ce39c97?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1473773508845-188df298d2d1?w=300&h=300&fit=crop&auto=format'
  ];

  // Default cover art and mood label functions
  const getDefaultCoverArt = useCallback(
    (trackName) => {
      if (!trackName) return coverArtOptions[0];
      try {
        const cleanTrackName = trackName
          .replace(/^Background_Music\/?/i, '')
          .replace(/\.mp3$/i, '')
          .trim();
        let hash = 0;
        for (let i = 0; i < cleanTrackName.length; i++) {
          hash = (hash << 5) - hash + cleanTrackName.charCodeAt(i);
          hash |= 0;
        }
        const index = Math.abs(hash) % coverArtOptions.length;
        return coverArtOptions[index];
      } catch (error) {
        console.error('Error getting track cover art:', error);
        return coverArtOptions[0];
      }
    },
    [coverArtOptions]
  );

  const getDefaultMoodLabel = useCallback((trackName) => {
    if (!trackName) return 'Relaxing Music';
    try {
      const cleanTrackName = trackName
        .replace(/^Background_Music\/?/i, '')
        .replace(/\.mp3$/i, '')
        .trim();
      const lowerName = cleanTrackName.toLowerCase();
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      for (const mood of moodMap) {
        if (mood.keywords.some((keyword) => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, []);

  // Use audioService functions if available; otherwise, use defaults
  const getCoverArtFunc = useCallback(
    (trackName) =>
      audioService.getTrackCoverArt
        ? audioService.getTrackCoverArt(trackName, coverArtOptions)
        : getDefaultCoverArt(trackName),
    [coverArtOptions, getDefaultCoverArt]
  );

  const getMoodLabelFunc = useCallback(
    (trackName) =>
      audioService.getMoodLabelFromTrackName
        ? audioService.getMoodLabelFromTrackName(trackName)
        : getDefaultMoodLabel(trackName),
    [getDefaultMoodLabel]
  );

  // Mount status
  useEffect(() => {
    mounted.current = true;
    console.log("MusicSelection: Component mounted");
    return () => {
      mounted.current = false;
    };
  }, []);

  // Fallback library if API fails
  const getFallbackLibrary = useCallback(() => {
    return [
      {
        name: 'calm_meditation',
        displayName: 'Calm Meditation',
        coverArt: getCoverArtFunc('calm_meditation'),
        moodLabel: 'Calm & Peaceful'
      },
      {
        name: 'ocean_waves',
        displayName: 'Ocean Waves',
        coverArt: getCoverArtFunc('ocean_waves'),
        moodLabel: 'Ocean Soundscape'
      },
      {
        name: 'forest_ambience',
        displayName: 'Forest Ambience',
        coverArt: getCoverArtFunc('forest_ambience'),
        moodLabel: 'Forest Sounds'
      },
      {
        name: 'gentle_rain',
        displayName: 'Gentle Rain',
        coverArt: getCoverArtFunc('gentle_rain'),
        moodLabel: 'Rainfall Ambience'
      }
    ];
  }, [getCoverArtFunc]);

  // Fetch music library from audioService
  const fetchMusicLibraryData = useCallback(async () => {
    setIsLocalMusicLoading(true);
    console.log("MusicSelection: Fetching music library data from server");
    
    try {
      const result = await audioService.fetchMusicLibrary();
      let musicFiles = [];
      // FIX: Check if result is an object with a status property
      if (result && typeof result === 'object' && !Array.isArray(result) && result.status) {
        if (result.status.toLowerCase() === 'success') {
          musicFiles = result.data.musicFiles;
        } else {
          console.error("Music library fetch returned status:", result.status);
          return getFallbackLibrary();
        }
      } else {
        musicFiles = result;
      }
      if (!Array.isArray(musicFiles) || musicFiles.length === 0) {
        console.warn("No valid tracks found; using fallback library.");
        return getFallbackLibrary();
      }
      
      // Transform tracks to a consistent format with all required fields
      return musicFiles
        .map((file) => {
          if (!file) return null;
          const name =
            typeof file === 'string'
              ? file.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '')
              : (file.name || '')
                  .replace(/^Background_Music\/?/i, '')
                  .replace(/\.mp3$/i, '');
          if (!name) return null;
          return {
            name,
            displayName:
              typeof file === 'object' && file.displayName
                ? file.displayName
                : name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
            coverArt: getCoverArtFunc(name),
            moodLabel: getMoodLabelFunc(name)
          };
        })
        .filter(Boolean);
    } catch (err) {
      console.error('Error in fetchMusicLibraryData:', err);
      return getFallbackLibrary();
    } finally {
      setIsLocalMusicLoading(false);
    }
  }, [getCoverArtFunc, getMoodLabelFunc, getFallbackLibrary]);

  // On mount, load the music library
  useEffect(() => {
    let canceled = false;
    
    (async () => {
      console.log("MusicSelection: Checking music library on mount", {
        externalLibraryLength: Array.isArray(musicLibrary) ? musicLibrary.length : 'not an array',
        selectedMusic: selectedMusic
      });
      
      if (Array.isArray(musicLibrary) && musicLibrary.length > 0) {
        console.log("MusicSelection: Using provided music library");
        
        // Format the library to ensure consistent structure
        const formattedLibrary = musicLibrary
          .map((track) => {
            if (!track) return null;
            const name =
              typeof track === 'string'
                ? track.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '')
                : (track.name || '')
                    .replace(/^Background_Music\/?/i, '')
                    .replace(/\.mp3$/i, '');
            if (!name) return null;
            return {
              name,
              displayName:
                typeof track === 'object' && track.displayName
                  ? track.displayName
                  : name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
              coverArt: track.coverArt || getCoverArtFunc(name),
              moodLabel: track.moodLabel || getMoodLabelFunc(name)
            };
          })
          .filter(Boolean);
          
        if (!canceled) {
          setLocalMusicLibrary(formattedLibrary);
          setIsLocalMusicLoading(false);
          
          if (!selectedMusic && formattedLibrary.length > 0) {
            setLocalSelection(formattedLibrary[0].name);
            if (typeof setSelectedMusic === 'function') {
              setSelectedMusic(formattedLibrary[0].name);
            }
          }
        }
      } else {
        console.log("MusicSelection: No library provided, fetching from server");
        const lib = await fetchMusicLibraryData();
        
        if (!canceled && lib && lib.length > 0) {
          console.log("MusicSelection: Loaded library from server with", lib.length, "tracks");
          setLocalMusicLibrary(lib);
          
          if (!selectedMusic && lib.length > 0) {
            setLocalSelection(lib[0].name);
            if (typeof setSelectedMusic === 'function') {
              setSelectedMusic(lib[0].name);
            }
          }
        }
      }
    })();
    
    return () => {
      canceled = true;
    };
  }, []);

  // Sync local selection with prop changes
  useEffect(() => {
    if (selectedMusic !== previousSelectionRef.current) {
      setLocalSelection(selectedMusic || '');
      previousSelectionRef.current = selectedMusic;
    }
  }, [selectedMusic]);

  // Cleanup audio on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
      if (previewTimeoutRef.current) {
        clearTimeout(previewTimeoutRef.current);
      }
    };
  }, []);

  // Track window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // When a card is clicked
  const handleCardClick = useCallback(
    (trackName) => {
      if (!mounted.current || !trackName) return;
      if (!isSessionCreated) {
        toast.info('Please complete the previous steps first');
        return;
      }
      try {
        const normalizedTrackName = trackName
          .replace(/^Background_Music\/?/i, '')
          .replace(/\.mp3$/i, '')
          .trim();
          
        if (normalizedTrackName === localSelection) return;
        setLocalSelection(normalizedTrackName);
        
        if (typeof setSelectedMusic === 'function') {
          setTimeout(() => {
            if (mounted.current) {
              setSelectedMusic(normalizedTrackName);
              // Save to localStorage so AudioStep can access the same selection
              localStorage.setItem('lastSelectedMusic', normalizedTrackName);
            }
          }, 10);
        }
      } catch (error) {
        console.error('Error in handleCardClick:', error);
        toast.error('Failed to select music. Please try again.');
      }
    },
    [isSessionCreated, setSelectedMusic, localSelection, toast]
  );
  
  useEffect(() => {
    const lastSelectedMusic = localStorage.getItem('lastSelectedMusic');
    if (lastSelectedMusic && !selectedMusic) {
      console.log('MusicSelection: Restoring previous music selection from localStorage:', lastSelectedMusic);
      setLocalSelection(lastSelectedMusic);
      if (typeof setSelectedMusic === 'function') {
        setSelectedMusic(lastSelectedMusic);
      }
    }
  }, [selectedMusic, setSelectedMusic]);
  
  // On component unmount, ensure selection is saved to localStorage
  useEffect(() => {
    return () => {
      if (localSelection) {
        localStorage.setItem('lastSelectedMusic', localSelection);
      }
    };
  }, [localSelection]);

  // Continue button handler
  const handleContinue = useCallback(() => {
    if (!mounted.current) return;
    const finalSelection = selectedMusic || localSelection;
    if (finalSelection) {
      if (typeof setSelectedMusic === 'function') {
        setSelectedMusic(finalSelection);
        localStorage.setItem('lastSelectedMusic', finalSelection);
      }
      
      // Check if TTS is still processing and needs to be forced to high progress
      if (isBackgroundTTSProcessing && backgroundTTSProgress < 90) {
        // Force high progress to allow user to continue
        if (setBackgroundTTSProgress) setBackgroundTTSProgress(100);
        if (setIsBackgroundTTSProcessing) setIsBackgroundTTSProcessing(false);
        
        // Also update global state
        if (globalAudioState && typeof globalAudioState.updateTTSProgress === 'function') {
          globalAudioState.updateTTSProgress(95);
        }
        
        toast.info('Voice generation will continue in the background...', {
          toastId: 'voice-background-processing',
          autoClose: 3000
        });
      }
      
      markStepCompleted(4);
      goToNextStep();
    } else {
      const fallbackTrack =
        localMusicLibrary.length > 0 ? localMusicLibrary[0].name : 'calm_meditation';
      setSelectedMusic(fallbackTrack);
      setLocalSelection(fallbackTrack);
      toast.info('Auto-selecting default background music', { autoClose: 3000 });
      markStepCompleted(4);
      goToNextStep();
    }
  }, [
    selectedMusic,
    localSelection,
    isBackgroundTTSProcessing,
    backgroundTTSProgress,
    setBackgroundTTSProgress,
    setIsBackgroundTTSProcessing,
    setSelectedMusic,
    markStepCompleted,
    goToNextStep,
    localMusicLibrary,
    mounted,
    toast
  ]);

  // Toggle preview playback
  const togglePreview = useCallback(
    async (track) => {
      if (!mounted.current || !track || !track.name) return;
      try {
        if (!audioRef.current) audioRef.current = new Audio();
        if (isPreviewPlaying && previewTrack === track.name) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          if (previewTimeoutRef.current) {
            clearTimeout(previewTimeoutRef.current);
            previewTimeoutRef.current = null;
          }
          setIsPreviewPlaying(false);
          setPreviewTrack(null);
          return;
        }
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        if (previewTimeoutRef.current) {
          clearTimeout(previewTimeoutRef.current);
          previewTimeoutRef.current = null;
        }
        audioRef.current.onloadeddata = () => {
          toast.success('Preview ready', { toastId: 'preview-ready', autoClose: 1000 });
        };
        audioRef.current.onerror = (e) => {
          toast.error('Audio preview not available', { toastId: 'preview-error', autoClose: 3000 });
          console.error('Audio preview error:', e);
          setIsPreviewPlaying(false);
          setPreviewTrack(null);
        };
        const previewUrl = audioService.getMusicPreviewUrl
          ? audioService.getMusicPreviewUrl(track.name)
          : `${apiBaseUrl}/music-preview/${encodeURIComponent(track.name)}`;
        audioRef.current.src = previewUrl;
        audioRef.current.volume = 0.7;
        toast.info('Loading preview...', { toastId: 'preview-loading', autoClose: false });
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              if (!mounted.current) return;
              setIsPreviewPlaying(true);
              setPreviewTrack(track.name);
              toast.update('preview-loading', {
                render: 'Playing preview',
                type: 'success',
                autoClose: 2000
              });
              previewTimeoutRef.current = setTimeout(() => {
                if (!mounted.current) return;
                if (audioRef.current && !audioRef.current.paused) {
                  audioRef.current.pause();
                  audioRef.current.currentTime = 0;
                  setIsPreviewPlaying(false);
                  setPreviewTrack(null);
                }
              }, 10000);
              audioRef.current.onended = () => {
                if (!mounted.current) return;
                setIsPreviewPlaying(false);
                setPreviewTrack(null);
                if (previewTimeoutRef.current) {
                  clearTimeout(previewTimeoutRef.current);
                  previewTimeoutRef.current = null;
                }
              };
            })
            .catch((err) => {
              if (!mounted.current) return;
              toast.dismiss('preview-loading');
              console.error('Error playing preview:', err);
              if (err.name === 'NotAllowedError') {
                toast.info('Click again to play audio', {
                  toastId: 'preview-permission',
                  autoClose: 3000
                });
              } else {
                toast.error('Could not play audio preview', {
                  toastId: 'preview-failed',
                  autoClose: 3000
                });
              }
              setIsPreviewPlaying(false);
              setPreviewTrack(null);
            });
        }
      } catch (err) {
        if (!mounted.current) return;
        console.error('Error setting up audio preview:', err);
        toast.error('Audio system error. Please try again.', {
          toastId: 'audio-system-error',
          autoClose: 3000
        });
        setIsPreviewPlaying(false);
        setPreviewTrack(null);
      }
    },
    [isPreviewPlaying, previewTrack, apiBaseUrl]
  );



  // Filter displayed tracks
  const filteredTracks = React.useMemo(() => {
    if (!Array.isArray(localMusicLibrary) || localMusicLibrary.length === 0) return [];
    const validTracks = localMusicLibrary.filter((track) => track && track.name);
    if (filter === 'all') return validTracks;
    return validTracks.filter((track) =>
      track.name.toLowerCase().includes(filter.toLowerCase())
    );
  }, [filter, localMusicLibrary]);

  // Emergency Skip Button: allow user to bypass loading if stuck >15 seconds
  const skipLoadingAndContinue = useCallback(() => {
    setIsLocalMusicLoading(false);
    const fallback = getFallbackLibrary();
    setLocalMusicLibrary(fallback);
    const firstTrack = fallback[0];
    setLocalSelection(firstTrack.name);
    if (typeof setSelectedMusic === 'function') {
      setSelectedMusic(firstTrack.name);
    }
    toast.info('Skipping loading and using fallback tracks.');
  }, [getFallbackLibrary, setSelectedMusic]);

  

  // Derived states
// Derived states
const isLibraryEmpty =
!Array.isArray(localMusicLibrary) || localMusicLibrary.length === 0;
const showLoading =
isLocalMusicLoading || isMusicLoading || (isLoading && isLibraryEmpty);



// Render loading state
if (showLoading) {
return (
  <div
    className="step-container"
    style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}
  >
    <div
      className="step-header"
      style={{ textAlign: isMobile ? 'center' : 'left' }}
    >
      <h2
        className="step-title"
        style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem' }}
      >
        Select Background Music
      </h2>
      <p
        className="step-description"
        style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem' }}
      >
        {isLocalMusicLoading || isMusicLoading
          ? 'Loading music library...'
          : 'No music tracks available'}
      </p>
    </div>
    <div
      style={{
        textAlign: 'center',
        padding: isSmallMobile ? '24px' : '40px',
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: isSmallMobile ? '0.9rem' : '1rem'
      }}
    >
      {isLocalMusicLoading || isMusicLoading ? (
        <>
          <div
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              border: '3px solid rgba(125, 18, 255, 0.3)',
              borderTopColor: '#7d12ff',
              animation: 'spin 1s linear infinite',
              margin: '0 auto 20px'
            }}
          ></div>
          <div>Loading music library from server...</div>
          {loadingDuration > 15000 && (
            <button
              onClick={skipLoadingAndContinue}
              style={{
                padding: '8px 16px',
                background: '#7d12ff',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                margin: '20px auto',
                display: 'block',
                cursor: 'pointer'
              }}
            >
              Skip Loading and Continue
            </button>
          )}
        </>
      ) : (
        <>
          <div>
            No music tracks available. Please try refreshing the page or
            loading again.
          </div>
          <button
            onClick={skipLoadingAndContinue}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              borderRadius: '8px',
              background: '#7d12ff',
              border: 'none',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            Retry / Skip
          </button>
        </>
      )}
    </div>
    <style jsx>{`
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);
}

if (isLibraryEmpty && !isLocalMusicLoading) {
return (
  <div
    className="step-container"
    style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}
  >
    <div
      className="step-header"
      style={{ textAlign: isMobile ? 'center' : 'left' }}
    >
      <h2
        className="step-title"
        style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem' }}
      >
        Select Background Music
      </h2>
      <p
        className="step-description"
        style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem' }}
      >
        Loading music library...
      </p>
    </div>
    <div
      style={{
        textAlign: 'center',
        padding: isSmallMobile ? '24px' : '40px',
        color: 'rgba(255, 255, 255, 0.7)'
      }}
    >
      <div
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '3px solid rgba(125, 18, 255, 0.3)',
          borderTopColor: '#7d12ff',
          animation: 'spin 1s linear infinite',
          margin: '0 auto 20px'
        }}
      ></div>
      <div>Loading music library from server...</div>
      {loadingDuration > 15000 && (
        <button
          onClick={skipLoadingAndContinue}
          style={{
            padding: '8px 16px',
            background: '#7d12ff',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            margin: '20px auto',
            display: 'block',
            cursor: 'pointer'
          }}
        >
          Skip Loading and Continue
        </button>
      )}
    </div>
    <style jsx>{`
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);
}

return (
<div
  className="step-container"
style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}


>
  <div className="step-header" style={{ textAlign: isMobile ? 'center' : 'left' }}>
    <h2
      className="step-title"
      style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem', marginBottom: '8px' }}
    >
      Select Background Music
    </h2>
    <p
      className="step-description"
      style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem', color: '#aaaaaa' }}
    >
      Choose a track to set the mood for your meditation
    </p>
  </div>
  <div
    style={{
      backgroundColor: 'rgba(125, 18, 255, 0.08)',
      borderRadius: '10px',
      padding: '12px',
      margin: '16px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      color: '#ffffff',
      fontSize: isSmallMobile ? '0.85rem' : '0.9rem',
      textAlign: 'center'
    }}
  >
    <IoMusicalNotesOutline size={18} />
    Tap any music card to select, or use the play button to preview
  </div>



  {/* Filter Buttons */}
  <div
    style={{
      margin: '16px 0',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      position: 'relative'
    }}
  >
    <div
      className="music-filter"
      style={{
        display: 'flex',
        gap: '8px',
        padding: '4px 0',
        whiteSpace: 'nowrap',
        width: 'max-content',
        minWidth: '100%'
      }}
    >
      <button
        className={`filter-button ${filter === 'all' ? 'active' : ''}`}
        onClick={() => setFilter('all')}
        style={{
          padding: '8px 16px',
          borderRadius: '20px',
          border: 'none',
          background: filter === 'all' ? '#7d12ff' : 'rgba(255, 255, 255, 0.1)',
          color: filter === 'all' ? '#fff' : 'rgba(255, 255, 255, 0.8)',
          fontSize: isSmallMobile ? '0.85rem' : '0.9rem',
          cursor: 'pointer',
          transition: 'all 0.2s ease'
        }}
      >
        All
      </button>
      {moods.slice(0, 8).map((mood) => (
        <button
          key={mood}
          className={`filter-button ${filter === mood ? 'active' : ''}`}
          onClick={() => setFilter(mood)}
          style={{
            padding: '8px 16px',
            borderRadius: '20px',
            border: 'none',
            background: filter === mood ? '#7d12ff' : 'rgba(255, 255, 255, 0.1)',
            color: filter === mood ? '#fff' : 'rgba(255, 255, 255, 0.8)',
            fontSize: isSmallMobile ? '0.85rem' : '0.9rem',
            cursor: 'pointer',
            transition: 'all 0.2s ease'
          }}
        >
          {mood.charAt(0).toUpperCase() + mood.slice(1)}
        </button>
      ))}
    </div>
  </div>

  {/* Music Grid */}
  <div
    className="music-grid"
    style={{
      display: 'grid',
      gridTemplateColumns: isSmallMobile
        ? 'repeat(2, 1fr)'
        : isMobile
        ? 'repeat(2, 1fr)'
        : windowWidth <= 1024
        ? 'repeat(3, 1fr)'
        : 'repeat(4, 1fr)',
      gap: isSmallMobile ? '8px' : '12px',
      margin: '16px 0 24px',
      width: '100%'
    }}
  >
    {filteredTracks.length > 0 ? (
      filteredTracks.map((track) => {
        if (!track || !track.name) return null;
        return (
          <MusicCard
            key={track.name}
            track={{
              ...track,
              coverArt: track.coverArt || getCoverArtFunc(track.name),
              moodLabel: track.moodLabel || getMoodLabelFunc(track.name)
            }}
            isSelected={localSelection === track.name}
            isPlaying={isPreviewPlaying && previewTrack === track.name}
            onSelect={handleCardClick}
            onTogglePlay={togglePreview}
            isMobile={isMobile}
            isSmallMobile={isSmallMobile}
          />
        );
      })
    ) : (
      <div
        style={{
          gridColumn: '1 / -1',
          textAlign: 'center',
          padding: '40px 20px',
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: isSmallMobile ? '0.9rem' : '1rem'
        }}
      >
        <div style={{ marginBottom: '12px' }}>
          No music tracks match your filter.
        </div>
        <button
          onClick={() => setFilter('all')}
          style={{
            padding: '8px 16px',
            borderRadius: '20px',
            border: 'none',
            background: '#7d12ff',
            color: '#fff',
            fontSize: '0.9rem',
            cursor: 'pointer'
          }}
        >
          Show All Music
        </button>
      </div>
    )}
  </div>

  {/* Preview of currently selected track */}
  {localSelection && (
    <div
      className="music-preview"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '12px',
        marginTop: '16px',
        background: 'rgba(25, 25, 35, 0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.08)',
        borderRadius: '12px',
        padding: isSmallMobile ? '12px' : '16px',
        width: '100%',
        maxWidth: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          flex: 1,
          minWidth: 0
        }}
      >
        <div
          style={{
            width: isSmallMobile ? '40px' : '48px',
            height: isSmallMobile ? '40px' : '48px',
            flexShrink: 0,
            borderRadius: '6px',
            background: `url(${
              localMusicLibrary.find((t) => t && t.name === localSelection)
                ?.coverArt || getCoverArtFunc(localSelection)
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        ></div>
        <div style={{ flex: 1, minWidth: 0 }}>
          <div
            style={{
              fontSize: isSmallMobile ? '0.9rem' : '1rem',
              fontWeight: '500',
              color: '#ffffff',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginBottom: '4px'
            }}
            title={
              localMusicLibrary.find((t) => t && t.name === localSelection)
                ?.displayName ||
              localSelection
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase())
            }
          >
            {localMusicLibrary.find((t) => t && t.name === localSelection)
              ?.displayName ||
              localSelection
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase())}
          </div>
          <div
            style={{
              fontSize: isSmallMobile ? '0.8rem' : '0.85rem',
              color: 'rgba(255, 255, 255, 0.7)'
            }}
          >
            Selected track
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          const track = localMusicLibrary.find(
            (t) => t && t.name === localSelection
          );
          if (track) {
            togglePreview(track);
          } else {
            togglePreview({
              name: localSelection,
              displayName: localSelection
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (c) => c.toUpperCase()),
              coverArt: getCoverArtFunc(localSelection),
              moodLabel: getMoodLabelFunc(localSelection)
            });
          }
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          width: isSmallMobile ? '36px' : '40px',
          height: isSmallMobile ? '36px' : '40px',
          borderRadius: '50%',
          background: '#7d12ff',
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          flexShrink: 0
        }}
      >
        {isPreviewPlaying && previewTrack === localSelection ? (
          <IoPauseCircleOutline size={isSmallMobile ? 20 : 24} />
        ) : (
          <IoPlayCircleOutline size={isSmallMobile ? 20 : 24} />
        )}
      </button>
    </div>
  )}

  {/* Action buttons */}
  <div
    className="action-buttons"
    style={{
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      marginTop: '24px',
      width: '100%'
    }}
  >
    <button
      className="primary-btn"
      onClick={handleContinue}
      disabled={!localSelection}
      style={{
        background: '#7d12ff',
        color: '#fff',
        border: 'none',
        borderRadius: '12px',
        padding: isSmallMobile ? '12px 24px' : '14px 28px',
        fontSize: isSmallMobile ? '0.95rem' : '1rem',
        fontWeight: '500',
        cursor: localSelection ? 'pointer' : 'not-allowed',
        transition: 'all 0.2s ease',
        opacity: localSelection ? 1 : 0.5,
        width: isSmallMobile ? '100%' : 'auto',
        minWidth: isSmallMobile ? 'auto' : '260px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        boxShadow: '0 4px 12px rgba(125, 18, 255, 0.25)'
      }}
      onMouseEnter={(e) => {
        if (localSelection) {
          e.currentTarget.style.transform = 'translateY(-2px)';
          e.currentTarget.style.boxShadow =
            '0 6px 16px rgba(125, 18, 255, 0.35)';
        }
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = '';
        e.currentTarget.style.boxShadow =
          '0 4px 12px rgba(125, 18, 255, 0.25)';
      }}
    >
      {isBackgroundTTSProcessing && backgroundTTSProgress < 90 ? (
        <>
          Continue{' '}
          {backgroundTTSProgress > 75
            ? '(Voice Almost Ready)'
            : '(Voice Processing...)'}
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </>
      ) : (
        <>
          Continue to Audio Mix
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </>
      )}
    </button>
  </div>

  {/* Audio element for track previews */}
  <audio
    ref={audioRef}
    onEnded={() => {
      if (mounted.current) {
        setIsPreviewPlaying(false);
        setPreviewTrack(null);
      }
    }}
    
  
  />
     {/* Add the TTSProgressIndicator */}
     {showTTSIndicator && (
      <div style={{ position: 'sticky', bottom: '20px', zIndex: 1000 }}>
        <TTSProgressIndicator
          isBackgroundTTSProcessing={isBackgroundTTSProcessing}
          backgroundTTSProgress={ttsProgress}
          ttsKey={ttsKey}
          isMobile={isMobile}
          colorScheme="purple"
          onComplete={() => {
            console.log("TTS completed during music selection");
            // Keep showing for a bit then fade
            setTimeout(() => setShowTTSIndicator(false), 3000);
          }}
        />
      </div>
    )}


  <style jsx>{`
    .music-card:hover .play-button {
      background: rgba(125, 18, 255, 0.2);
    }
    .music-card:active {
      transform: scale(0.98);
    }
    .filter-button:hover:not(.active) {
      background: rgba(255, 255, 255, 0.15);
    }
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    .music-filter::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 768px) {
      .play-button {
        min-width: 32px;
        min-height: 32px;
      }
      .music-card {
        -webkit-tap-highlight-color: transparent;
        touch-action: manipulation;
      }
    }
  `}</style>
  
</div>
);
};

export default MusicSelection;