import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  IoChevronUpSharp, 
  IoChevronDownSharp, 
  IoMusicalNotesOutline, 
  IoPersonOutline, 
  IoVolumeHighOutline 
} from 'react-icons/io5';
import useToast from '../../hooks/useToast';
import audioService from '../../../../services/audioService';
import { getPresetConfigByName } from '../../components/common/FilterPresets';
import globalAudioState from '../../components/audio/globalAudioState';
import TransitionManager from '../../components/audio/TransitionManager.js';
import TTSProgressIndicator from '../common/TTSProgressIndicator';

import { clearAllTransitionFlags, isAnyTransitionActive } from '../../utils/transitionUtils';


const AudioStep = ({
  volume,
  setVolume,
  windowDimensions,  // add this prop here
  filters,
  setFilters,
  musicLibrary,
  selectedMusic,
  setSelectedMusic,
  onApplyChanges,
  ttsKey,
  isMixingAudio,
  setIsMixingAudio,     
  isMusicLoading,
  setIsMusicLoading,
  markStepCompleted,
  goToNextStep,
  baseUrl,
  isLoading,
  isBackgroundTTSProcessing = false,
  backgroundTTSProgress = 0,
  setTransitionMessage = () => {},
  setTransitionSubtitle = () => {},
  setIsTransitioning = () => {}
}) => {
  const toast = useToast();

  // Local state for UI and controls
  const [localMusic, setLocalMusic] = useState(selectedMusic);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const [localVolume, setLocalVolume] = useState({ ...volume });
  const [expandedFilter, setExpandedFilter] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadingDuration, setLoadingDuration] = useState(0);
  const [localMusicLibrary, setLocalMusicLibrary] = useState([]);
  const [ttsReady, setTtsReady] = useState(false);
  const [localTTSProcessing, setLocalTTSProcessing] = useState(isBackgroundTTSProcessing);
  const [localTTSProgress, setLocalTTSProgress] = useState(backgroundTTSProgress);
  const [isTTSComplete, setIsTTSComplete] = useState(false);
  
  // Refs for lifecycle management
  const mounted = useRef(true);
  const previousSelectionRef = useRef(selectedMusic);
  // This ref will track if we have already subscribed for TTS events.
  const subscribedRef = useRef(false);
  
  // Responsive design helpers.
  const isMobile = windowWidth <= 768;
  const isSmallMobile = windowWidth <= 480;
  const containerWidth = isMobile ? '100%' : '700px';
  const containerPadding = isMobile ? '16px' : '20px';
  const fontSize = isMobile ? '0.9rem' : '0.95rem';
  const labelWidth = isMobile ? '70px' : '110px';
  
  // Show main loading overlay if the entire step is loading
  const showMainLoading = isLoading || isMixingAudio;
  
  // ───────────────────────────────────────────────────────────────
  // Synchronize global TTS state into local state.
// Add this useEffect to track TTS state
useEffect(() => {
  const globalStateExists =
    typeof globalAudioState === 'object' &&
    globalAudioState !== null &&
    typeof globalAudioState.state === 'object';

  const processing = globalStateExists
    ? globalAudioState.state.ttsProcessing
    : isBackgroundTTSProcessing;
  
  const progress = globalStateExists
    ? globalAudioState.state.ttsProgress
    : backgroundTTSProgress;
  
  const key = globalStateExists
    ? globalAudioState.state.ttsKey
    : ttsKey;

  console.log("AudioStep TTS Status:", {
    isBackgroundTTSProcessing,
    backgroundTTSProgress,
    ttsKey,
    globalState: globalStateExists
      ? {
          ttsProcessing: globalAudioState.state.ttsProcessing,
          ttsProgress: globalAudioState.state.ttsProgress,
          ttsKey: globalAudioState.state.ttsKey
        }
      : 'not available',
    using: { processing, progress, key }
  });

  setLocalTTSProcessing(processing);
  setLocalTTSProgress(progress);

  // Using a lenient check to mark TTS as ready
  const ready =
    (!processing && !!key) ||
    (!!key && progress >= 90) ||
    (!!key && !processing) ||
    (progress >= 95);
  
  console.log(
    `AudioStep: Setting ttsReady to ${ready} (processing: ${processing}, key: ${key}, progress: ${progress})`
  );
  
  setTtsReady(ready);

  // Check for complete TTS
  if ((typeof window !== 'undefined' && window.__ttsCompleted === true) || progress >= 100) {
    setIsTTSComplete(true);
  }
}, [isBackgroundTTSProcessing, backgroundTTSProgress, ttsKey]);
  

useEffect(() => {
  if (globalAudioState) {
    const progressSub = globalAudioState.subscribe('tts-progress-updated', (data) => {
      if (data && typeof data.progress === 'number') {
        setLocalTTSProgress(data.progress);
        
        // Mark as ready when progress is high
        if (data.progress >= 95) {
          setTtsReady(true);
        }
        
        if (data.progress >= 100) {
          setIsTTSComplete(true);
        }
      }
    });
    
    const completeSub = globalAudioState.subscribe('tts-processing-completed', () => {
      setLocalTTSProcessing(false);
      setLocalTTSProgress(100);
      setTtsReady(true);
      setIsTTSComplete(true);
      
      console.log("TTS generation complete in AudioStep");
    });
    
    // Clean up subscriptions
    return () => {
      if (typeof progressSub === 'function') progressSub();
      if (typeof completeSub === 'function') completeSub();
    };
  }
}, []);


  // ───────────────────────────────────────────────────────────────
  // Subscribe to global TTS events for real-time updates.
  // Use a local ref (subscribedRef) to make sure this runs only once per mount.
  useEffect(() => {
    if (!subscribedRef.current) {
      const ttsProgressSub = globalAudioState.subscribe('tts-progress-updated', (data) => {
        if (data && typeof data.progress === 'number') {
          setLocalTTSProgress(data.progress);
          // When progress is very high, mark as ready.
          if (data.progress >= 95) {
            setTtsReady(true);
          }
        }
      });
    
      const ttsCompleteSub = globalAudioState.subscribe('tts-processing-completed', () => {
        setLocalTTSProcessing(false);
        setLocalTTSProgress(100);
        setTtsReady(true);
      });
    
      subscribedRef.current = true;
      
      // Unsubscribe when component unmounts to prevent duplicates.
      return () => {
        ttsProgressSub();
        ttsCompleteSub();
        subscribedRef.current = false;
      };
    }
  }, []);

  // ───────────────────────────────────────────────────────────────
  // Additional effect to update local progress if incoming props change.
  useEffect(() => {
    if (typeof backgroundTTSProgress === 'number' && !isNaN(backgroundTTSProgress)) {
      setLocalTTSProgress(backgroundTTSProgress);
    }
  }, [backgroundTTSProgress]);

  // ───────────────────────────────────────────────────────────────
  // Helper function to determine if TTS is ready (used in the continue button)
// Add this helper function for Continue button
const isTTSReadyForMixing = useCallback(() => {
  // First check global completion flag
  if (typeof window !== 'undefined' && window.__ttsCompleted === true) {
    return true;
  }
  
  // Then use globalAudioState's checker if available
  if (typeof globalAudioState?.isTTSReadyForMixing === 'function') {
    const result = globalAudioState.isTTSReadyForMixing();
    return result.isReady;
  }
  
  // Fall back to standard checks
  const processing = globalAudioState?.state?.ttsProcessing ?? isBackgroundTTSProcessing;
  const progress = globalAudioState?.state?.ttsProgress ?? backgroundTTSProgress;
  const key = globalAudioState?.state?.ttsKey ?? ttsKey;
  
  return (!processing && !!key) || (!!key && progress >= 90) || (progress >= 95) || isTTSComplete;
}, [isBackgroundTTSProcessing, backgroundTTSProgress, ttsKey, isTTSComplete]);


  // ───────────────────────────────────────────────────────────────
  // Utility functions for default cover art and mood label (unchanged)
  const coverArtOptions = [
    'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1504851149312-7a075b496cc7?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1519681393784-d120267933ba?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1448375240586-882707db888b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1507041957456-9c397ce39c97?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1473773508845-188df298d2d1?w=300&h=300&fit=crop&auto=format'
  ];

  const getDefaultCoverArt = useCallback((trackName) => {
    if (!trackName) return coverArtOptions[0];
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      for (let i = 0; i < cleanName.length; i++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, []);

  const getDefaultMoodLabel = useCallback((trackName) => {
    if (!trackName) return 'Relaxing Music';
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanName.toLowerCase();
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      return cleanName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, []);

  const getCoverArtFunc = useCallback(
    (trackName) =>
      (typeof audioService.getTrackCoverArt === 'function')
        ? audioService.getTrackCoverArt(trackName, coverArtOptions)
        : getDefaultCoverArt(trackName),
    [getDefaultCoverArt]
  );

  const getMoodLabelFunc = useCallback(
    (trackName) =>
      (typeof audioService.getMoodLabelFromTrackName === 'function')
        ? audioService.getMoodLabelFromTrackName(trackName)
        : getDefaultMoodLabel(trackName),
    [getDefaultMoodLabel]
  );

  // Calculate a background gradient for range inputs.
  const calculateBackground = useCallback((value, min = 0, max = 1) => {
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #7d12ff 0%, #7d12ff ${percentage}%, #3a3a3c ${percentage}%, #3a3a3c 100%)`;
  }, []);

  // Skip loading and use fallback music tracks
  const skipLoadingAndContinue = useCallback(() => {
    if (typeof setIsMusicLoading === 'function') {
      setIsMusicLoading(false);
    }
    toast.info('Skipping music library loading. Using fallback tracks.');
    const fallbackLibrary = [
      { name: "calm_meditation", displayName: "Calm Meditation" },
      { name: "ocean_waves", displayName: "Ocean Waves" },
      { name: "forest_ambience", displayName: "Forest Ambience" },
      { name: "gentle_rain", displayName: "Gentle Rain" }
    ];
    setLocalMusicLibrary(fallbackLibrary);
    if (!selectedMusic && fallbackLibrary.length > 0) {
      setLocalMusic(fallbackLibrary[0].name);
      setSelectedMusic(fallbackLibrary[0].name);
    }
  }, [toast, selectedMusic, setSelectedMusic, setIsMusicLoading]);

  // ───────────────────────────────────────────────────────────────
  // UI Event Handlers
  const handleVolumeChange = useCallback((type, value) => {
    setLocalVolume(prev => ({ ...prev, [type]: value }));
  }, []);

  const handleMusicSelect = useCallback((e) => {
    const newMusic = e.target.value;
    const normalizedMusic = newMusic
      .replace(/^Background_Music\/?/i, '')
      .replace(/\.mp3$/i, '')
      .trim();
    setLocalMusic(normalizedMusic);
    setSelectedMusic(normalizedMusic);
    if (normalizedMusic) localStorage.setItem('lastSelectedMusic', normalizedMusic);
  }, [setSelectedMusic]);

  // Toggle filter on/off
  const toggleFilter = useCallback((filterName) => {
    if (filterName === 'equalizer') filterName = 'eq';
    setLocalFilters(prev => {
      const updatedFilters = { ...prev };
      if (updatedFilters[filterName]) {
        updatedFilters[filterName] = {
          ...updatedFilters[filterName],
          enabled: !updatedFilters[filterName].enabled
        };
      } else {
        updatedFilters[filterName] = {
          enabled: true,
          ...(filterName === 'reverb' ? { wet: 0.5, dry: 1.0 } : {}),
          ...(filterName === 'stereoPan' ? { value: 0.3, speed: 15, pattern: 'sine' } : {}),
          ...(filterName === 'eq' ? { lowGain: 0, midGain: 0, highGain: 0 } : {})
        };
      }
      return updatedFilters;
    });
  }, []);

  // Apply a preset configuration to the given filter.
  const applyPresetConfig = useCallback((presetConfig, filterType) => {
    let newFilter = {
      enabled: true,
      preset: presetConfig.label || presetConfig.preset || ""
    };
    Object.keys(presetConfig).forEach(key => {
      if (key !== 'label' && key !== 'description') {
        newFilter[key] = presetConfig[key];
      }
    });
    if (filterType === 'reverb') {
      if (newFilter.impulse_response_path && !newFilter.impulse_response_path.startsWith('s3://')) {
        const basePath = 's3://pausebeta20-meditation-assets/';
        newFilter.impulse_response_path = `${basePath}${newFilter.impulse_response_path}`;
      }
    }
    const finalFilterType = filterType === 'equalizer' ? 'eq' : filterType;
    newFilter.enabled = true;
    setLocalFilters(prevFilters => ({
      ...prevFilters,
      [finalFilterType]: newFilter
    }));
  }, []);

  // Handle preset selection with error handling.
  const handlePresetSelect = useCallback((filterType, presetName) => {
    let presetConfig;
    try {
      presetConfig = getPresetConfigByName(presetName);
    } catch (error) {
      console.error(`Error getting preset: ${error.message}`);
      presetConfig = null;
    }
    if (!presetConfig) {
      if (filterType === 'reverb') {
        if (presetName === 'Intimate Room') {
          presetConfig = {
            label: 'Intimate Room',
            enabled: true,
            wet: 0.35,
            dry: 0.75,
            preset: 'Intimate Room',
            impulse_response_path: 'reverb/small_room.wav'
          };
        } else if (presetName === 'Sacred Hall') {
          presetConfig = {
            label: 'Sacred Hall',
            enabled: true,
            wet: 0.5,
            dry: 0.6,
            preset: 'Sacred Hall',
            impulse_response_path: 'reverb/large_hall.wav'
          };
        }
      } else if (filterType === 'stereoPan') {
        if (presetName === 'Gentle Movement') {
          presetConfig = {
            label: 'Gentle Movement',
            enabled: true,
            value: 0.2,
            speed: 10,
            pattern: 'sine',
            preset: 'Gentle Movement'
          };
        } else if (presetName === 'Meditative Space') {
          presetConfig = {
            label: 'Meditative Space',
            enabled: true,
            value: 0.4,
            speed: 15,
            pattern: 'sine',
            preset: 'Meditative Space'
          };
        }
      } else if (filterType === 'equalizer') {
        if (presetName === 'Voice Clarity') {
          presetConfig = {
            label: 'Voice Clarity',
            enabled: true,
            lowGain: -1,
            midGain: 3,
            highGain: 2,
            preset: 'Voice Clarity'
          };
        } else if (presetName === 'Warm Voice') {
          presetConfig = {
            label: 'Warm Voice',
            enabled: true,
            lowGain: 3,
            midGain: 1,
            highGain: -1,
            preset: 'Warm Voice'
          };
        }
      }
    }
    if (presetConfig) {
      presetConfig.enabled = true;
      applyPresetConfig(presetConfig, filterType);
    } else {
      const finalFilterType = filterType === 'equalizer' ? 'eq' : filterType;
      setLocalFilters(prev => ({
        ...prev,
        [finalFilterType]: {
          ...prev[finalFilterType],
          enabled: true,
          preset: presetName
        }
      }));
    }
  }, [applyPresetConfig]);

  // ───────────────────────────────────────────────────────────────
  // Main continue function. It sets various global flags, uses TransitionManager to
  // coordinate transitions, and finally triggers audio mixing before proceeding to the next step.
  const handleContinue = async () => {
    console.log("CONTINUE BUTTON CLICKED - STARTING TRANSITION");
    
    // SAFETY CHECK: Ensure TTS is complete before transition
    console.log("Ensuring TTS completion before transition");
    if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
      globalAudioState.completeTTSProcessing();
    } else {
      // Fallback completion if method is missing
      if (typeof window !== 'undefined') {
        window.__ttsCompleted = true;
        window.__ttsCompletionTime = Date.now();
      }
      
      // Clear all transition flags
      if (typeof TransitionManager.clearAllTransitionFlags === 'function') {
        TransitionManager.clearAllTransitionFlags();
      } else {
        window.__transitionInProgress = false;
        window.__transitionLock = false;
        window.__waveSurferLock = false;
        window.__audioStepTransitionStarted = false;
      }
      
      // Wait briefly for cleanup to complete
      await new Promise(resolve => setTimeout(resolve, 300));
    }
  
    // SAFETY CHECK: Ensure TTS is complete or force completion
    console.log("Ensuring TTS completion before transition");
    if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
      globalAudioState.completeTTSProcessing();
    } else {
      // Fallback completion if method not available
      console.log("TTS completion method not found, using fallback completion");
      if (typeof window !== 'undefined') {
        window.__ttsCompleted = true;
        window.__ttsCompletionTime = Date.now();
        window.__lastTTSProgress = 100;
      }
      
      try {
        sessionStorage.setItem("ttsProcessing", "false");
        sessionStorage.setItem("ttsProgress", "100");
      } catch (e) {
        console.warn("Error updating sessionStorage:", e);
      }
    }
    
    // TTS key validation
    if (!ttsKey && !(globalAudioState?.state?.ttsKey)) {
      console.log("TTS key is missing, cannot proceed with audio mixing");
      toast.info("Please wait for voice generation to complete before continuing", {
        toastId: "tts-key-missing",
        autoClose: 3000,
      });
      return;
    }
    
    // Save current settings
    const currentVolume = { ...localVolume };
    const currentFilters = { ...localFilters };
    setVolume({ ...currentVolume });
    setFilters({ ...currentFilters });
    
    try {
      // Set transition flag with proper cleanup
      window.__audioStepTransitionStarted = true;
      window.__transitionInProgress = true;
      window.__transitionTimestamp = Date.now();
      
      // IMPROVED: Set a safety timeout to clear flags if transition gets stuck
      const safetyTimer = setTimeout(() => {
        if (window.__audioStepTransitionStarted) {
          console.log("Safety timeout triggered: clearing stuck transition flags");
          window.__audioStepTransitionStarted = false;
          window.__transitionInProgress = false;
          window.__transitionLock = false;
          window.__waveSurferLock = false;
        }
      }, 10000);
      
      // Set WaveSurfer lock BEFORE audio preparation
      window.__waveSurferLock = true;
      console.log("Set WaveSurfer initialization lock for transition");
      
      // Prepare a fallback audio URL based on ttsKey
      let fallbackAudioUrl = "";
      if (ttsKey) {
        fallbackAudioUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, "")}`;
        
        // Store the URL in globalAudioState with event blocking to prevent circular updates
        if (typeof globalAudioState.setCurrentAudioUrl === 'function') {
          globalAudioState._blockEventPropagation = true;
          globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
          setTimeout(() => {
            globalAudioState._blockEventPropagation = false;
          }, 1000);
        } else if (typeof globalAudioState.updateAllAudioUrls === 'function') {
          // Alternative method if setCurrentAudioUrl is not available
          globalAudioState._blockEventPropagation = true;
          globalAudioState.updateAllAudioUrls(fallbackAudioUrl, 'transition-preparation');
          setTimeout(() => {
            globalAudioState._blockEventPropagation = false;
          }, 1000);
        }
      }
      
      // Coordinate transition via TransitionManager
      let transitionResult = { success: false, url: null };
      try {
        // First try using globalAudioState's prepare method if available
        if (globalAudioState && typeof globalAudioState.prepareTransitionToListenStep === 'function') {
          transitionResult = await globalAudioState.prepareTransitionToListenStep({
            fromStep: 5,
            toStep: 6,
            ttsKey,
            audioUrl: fallbackAudioUrl,
            isPlaying: false,
            selectedMusic
          });
        } else {
          // Fall back to TransitionManager
          transitionResult = await TransitionManager.coordinateTransition({
            fromStep: 5,
            toStep: 6,
            globalAudioState,
            ttsKey,
            audioUrl: fallbackAudioUrl,
            isPlaying: false,
            selectedMusic,
            toast,
            setIsTransitioning,
            setTransitionMessage,
            setTransitionSubtitle
          });
        }
      } catch (err) {
        console.error("Transition coordination error:", err);
        transitionResult = { success: false, url: fallbackAudioUrl };
      }
      
      const transitionUrl = transitionResult?.url || fallbackAudioUrl;
      
      // Call onApplyChanges to trigger audio mixing
      try {
        // IMPROVED: Set mixing indicators before starting
        setIsMixingAudio(true);
        setTransitionMessage("Creating your audio mix...");
        setIsTransitioning(true);
        
        const mixResult = await onApplyChanges(currentVolume, currentFilters);
        
        if (mixResult && mixResult.status === "success") {
          let finalUrl = null;
          
          // Extract the mixed audio URL using extractMixedAudioUrl if available
          if (typeof globalAudioState.extractMixedAudioUrl === 'function') {
            const extractResult = globalAudioState.extractMixedAudioUrl(mixResult);
            if (extractResult && extractResult.success && extractResult.url) {
              finalUrl = extractResult.url;
            }
          } else {
            // Fallback to direct access if method not available
            finalUrl = mixResult.data?.file_path || transitionUrl;
          }
          
          // Ensure we have a valid URL
          if (!finalUrl) {
            finalUrl = transitionUrl;
          }
          
          // Inform globalAudioState of successful mixing
          if (typeof globalAudioState.completeAudioMixing === 'function') {
            globalAudioState.completeAudioMixing(finalUrl);
          } else if (typeof globalAudioState.setCurrentAudioUrl === 'function') {
            globalAudioState.setCurrentAudioUrl(finalUrl);
          } else if (typeof globalAudioState.updateAllAudioUrls === 'function') {
            globalAudioState.updateAllAudioUrls(finalUrl, 'mixing-complete');
          }
          
          // Inform TransitionManager of final URL
          TransitionManager.setUrl(finalUrl, 'mixed');
          
          // Complete the transition properly
          if (typeof globalAudioState.completeTransition === 'function') {
            globalAudioState.completeTransition(finalUrl);
          } else if (typeof TransitionManager.completeTransition === 'function') {
            TransitionManager.completeTransition(finalUrl);
          }
        } else {
          // Handle mixing failure
          if (typeof globalAudioState.failAudioMixing === 'function') {
            globalAudioState.failAudioMixing("invalid-mix-result");
          }
          
          // Try to create a silent fallback if available
          if (typeof globalAudioState.createSilentFallback === 'function') {
            await globalAudioState.createSilentFallback();
          }
          
          // Fall back to TTS URL
          if (typeof globalAudioState.setCurrentAudioUrl === 'function') {
            globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
          } else if (typeof globalAudioState.updateAllAudioUrls === 'function') {
            globalAudioState.updateAllAudioUrls(fallbackAudioUrl, 'mixing-failure');
          }
        }
      } catch (mixError) {
        console.error("Error during audio mixing:", mixError);
        
        // Handle mixing error
        if (typeof globalAudioState.failAudioMixing === 'function') {
          globalAudioState.failAudioMixing("server-error");
        }
        
        // Try to create a silent fallback if available
        if (typeof globalAudioState.createSilentFallback === 'function') {
          await globalAudioState.createSilentFallback();
        }
        
        // Fall back to TTS URL
        if (typeof globalAudioState.setCurrentAudioUrl === 'function') {
          globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
        } else if (typeof globalAudioState.updateAllAudioUrls === 'function') {
          globalAudioState.updateAllAudioUrls(fallbackAudioUrl, 'mixing-error');
        }
        
        toast.warning("There was an issue mixing your audio. Using voice-only version.");
      }
      
      // Wait for UI update before navigating
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Proceed to next step
      goToNextStep();
      
      // Clear our safety timeout since we're proceeding normally
      clearTimeout(safetyTimer);
      
    } catch (error) {
      // Clean up all transition flags in case of error
      window.__audioStepTransitionStarted = false;
      window.__transitionInProgress = false;
      window.__transitionLock = false;
      window.__waveSurferLock = false;
      
      console.error("Critical error during transition:", error);
      toast.error("There was an issue preparing your audio. Using voice only.");
      
      // Fall back to TTS URL in case of error
      const fallbackTtsUrl = ttsKey
        ? `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, "")}`
        : (typeof globalAudioState.getSilentAudioUrl === 'function')
          ? globalAudioState.getSilentAudioUrl()
          : `${baseUrl}/resources/silent.mp3`;
          
      if (typeof globalAudioState.setCurrentAudioUrl === 'function') {
        globalAudioState.setCurrentAudioUrl(fallbackTtsUrl);
      } else if (typeof globalAudioState.updateAllAudioUrls === 'function') {
        globalAudioState.updateAllAudioUrls(fallbackTtsUrl, 'critical-error');
      }
      
      // Still go to next step even with error
      goToNextStep();
    } finally {
      // Ensure transition UI is cleared
      if (setIsTransitioning) setIsTransitioning(false);
    }
  };

  // ───────────────────────────────────────────────────────────────
  // Lifecycle: set up a timer for music library loading duration.
  useEffect(() => {
    mounted.current = true;
    const loadingTimer = setInterval(() => {
      if (isMusicLoading) {
        setLoadingDuration(prev => prev + 1000);
      }
    }, 1000);
    return () => {
      mounted.current = false;
      clearInterval(loadingTimer);
    };
  }, [isMusicLoading]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // On mount, retrieve music library and previous selection.
  useEffect(() => {
    if (Array.isArray(musicLibrary) && musicLibrary.length > 0) {
      setLocalMusicLibrary(musicLibrary);
      if (selectedMusic) {
        setLocalMusic(selectedMusic);
      } else {
        const lastSelected = localStorage.getItem('lastSelectedMusic');
        if (lastSelected) {
          setLocalMusic(lastSelected);
          setSelectedMusic(lastSelected);
        } else if (musicLibrary.length > 0) {
          setLocalMusic(musicLibrary[0].name);
          setSelectedMusic(musicLibrary[0].name);
        }
      }
    }
  }, [musicLibrary, selectedMusic, setSelectedMusic]);

  useEffect(() => {
    if (selectedMusic !== previousSelectionRef.current) {
      setLocalMusic(selectedMusic || '');
      previousSelectionRef.current = selectedMusic;
    }
  }, [selectedMusic]);

  useEffect(() => {
    const lastSelected = localStorage.getItem('lastSelectedMusic');
    if (lastSelected && !selectedMusic) {
      setLocalMusic(lastSelected);
      setSelectedMusic(lastSelected);
    }
  }, [selectedMusic, setSelectedMusic]);

  // ───────────────────────────────────────────────────────────────
  // CSS styles for loading animations and global styles.
  const loadingAnimationStyles = `
    .mixing-progress-bar {
      width: 100%;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      overflow: hidden;
      margin-top: 10px;
    }
    .mixing-progress-bar-inner {
      height: 100%;
      width: 0;
      background-color: #7d12ff;
      border-radius: 2px;
      transition: width 0.5s;
    }
    .mixing-progress-bar-animated {
      position: relative;
      animation: indeterminate-progress 1.5s ease-in-out infinite;
      background-image: linear-gradient(
        45deg, 
        rgba(255, 255, 255, 0.15) 25%, 
        transparent 25%, 
        transparent 50%, 
        rgba(255, 255, 255, 0.15) 50%, 
        rgba(255, 255, 255, 0.15) 75%, 
        transparent 75%, 
        transparent
      );
      background-size: 40px 40px;
    }
    
    @keyframes indeterminate-progress {
      0% {
        left: -100%;
        width: 100%;
      }
      100% {
        left: 100%;
        width: 100%;
      }
    }
  `;

  const getGlobalStyles = useCallback((isMobile) => {
    return `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: ${isMobile ? '20px' : '16px'};
        height: ${isMobile ? '20px' : '16px'};
        border-radius: 50%;
        background: #7d12ff;
        cursor: pointer;
        box-shadow: 0 0 6px rgba(125,18,255,0.4);
      }
      input[type="range"]::-moz-range-thumb {
        width: ${isMobile ? '20px' : '16px'};
        height: ${isMobile ? '20px' : '16px'};
        border-radius: 50%;
        background: #7d12ff;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 6px rgba(125,18,255,0.4);
      }
      button:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 6px 16px rgba(125,18,255,0.3);
      }
      button:active:not(:disabled) {
        transform: translateY(0);
      }
      @media (max-width: 768px) {
        input[type="range"] {
          height: 12px;
          padding: 8px 0;
        }
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
        }
        select {
          height: 44px;
        }
      }
    `;
  }, []);

  // Debug component to show TTS state (not rendered in production)
  const TTSDebugInfo = ({ 
    isBackgroundTTSProcessing, 
    backgroundTTSProgress, 
    ttsKey, 
    globalAudioState 
  }) => {
    if (process.env.NODE_ENV === 'production') return null;
    
    return (
      <div style={{
        margin: '10px 0',
        padding: '8px',
        background: 'rgba(0,0,0,0.5)',
        borderRadius: '8px',
        fontSize: '12px',
        color: '#fff',
        fontFamily: 'monospace'
      }}>
        <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
          TTS Debug Info
        </div>
        <div>
          <span style={{ color: '#aaa' }}>Props:</span> processing={isBackgroundTTSProcessing ? 'true' : 'false'}, 
          progress={backgroundTTSProgress !== undefined ? Math.round(backgroundTTSProgress) : 'N/A'}%, 
          ttsKey={ttsKey ? 'exists' : 'missing'}
        </div>
        <div>
          <span style={{ color: '#aaa' }}>Global:</span> processing={
            globalAudioState?.state?.ttsProcessing ? 'true' : 'false'
          }, progress={
            globalAudioState?.state?.ttsProgress !== undefined ? Math.round(globalAudioState.state.ttsProgress) : 'N/A'
          }%, ttsKey={
            globalAudioState?.state?.ttsKey ? 'exists' : 'missing'
          }
        </div>
        <div>
          <span style={{ color: '#aaa' }}>Session:</span> {
            typeof sessionStorage !== 'undefined'
              ? `ttsKey=${sessionStorage.getItem('ttsKey') ? 'exists' : 'missing'}, 
                 ttsProcessing=${sessionStorage.getItem('ttsProcessing') === 'true' ? 'true' : 'false'},
                 ttsProgress=${sessionStorage.getItem('ttsProgress') || 'N/A'}`
              : 'unavailable'
          }
        </div>
      </div>
    );
  };

  // ───────────────────────────────────────────────────────────────
  // Conditional Rendering for when the music library is still loading.
  const libraryEmpty = !Array.isArray(localMusicLibrary) || localMusicLibrary.length === 0;
  if (showMainLoading || libraryEmpty) {
    return (
      <div className="step-container" style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}>
        <div className="step-header" style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <h2 className="step-title" style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem' }}>
            Select Background Music
          </h2>
          <p className="step-description" style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem' }}>
            {isMusicLoading ? 'Loading music library...' : 'No music tracks available'}
          </p>
        </div>
        <div style={{
          textAlign: 'center',
          padding: isSmallMobile ? '24px' : '40px',
          color: 'rgba(255,255,255,0.7)',
          fontSize: isSmallMobile ? '0.9rem' : '1rem'
        }}>
          {isMusicLoading ? (
            <>
              <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '3px solid rgba(125,18,255,0.3)',
                borderTopColor: '#7d12ff',
                animation: 'spin 1s linear infinite',
                margin: '0 auto 20px'
              }}></div>
              <div>Loading music library from server...</div>
              {loadingDuration > 15000 && (
                <button
                  onClick={skipLoadingAndContinue}
                  style={{
                    padding: '8px 16px',
                    background: '#7d12ff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    margin: '20px auto',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                >
                  Skip Loading and Continue
                </button>
              )}
            </>
          ) : (
            <>
              <div>No music tracks available. Please try refreshing the page or loading again.</div>
              <button
                onClick={skipLoadingAndContinue}
                style={{
                  marginTop: '20px',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  background: '#7d12ff',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Retry / Skip
              </button>
            </>
          )}
        </div>
        <style jsx>{`
          @keyframes spin {
            to { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  // ───────────────────────────────────────────────────────────────
  // Main rendering of the AudioStep component UI.
  return (
    <div className="step-container">
      <TTSDebugInfo 
        isBackgroundTTSProcessing={isBackgroundTTSProcessing}
        backgroundTTSProgress={backgroundTTSProgress}
        ttsKey={ttsKey}
        globalAudioState={globalAudioState}
      />
      <div className="step-header" style={{ padding: isMobile ? '0 16px' : '0', textAlign: isMobile ? 'center' : 'left' }}>
        <h2 className="step-title" style={{ fontSize: isMobile ? '1.3rem' : '1.5rem' }}>
          Customize Audio
        </h2>
        <p className="step-description" style={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>
          Adjust volume levels, select background music, and apply audio effects.
        </p>
      </div>

      {/* Brief explanation */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        padding: isMobile ? '12px 16px' : '16px',
        background: 'rgba(125,18,255,0.1)',
        borderRadius: '10px',
        fontSize: fontSize,
        color: '#ffffff',
        textAlign: 'center'
      }}>
        Your changes will automatically apply when you continue to the next step.
      </div>

      {/* Music Dropdown */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        background: 'rgba(25,25,35,0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.08)',
        borderRadius: '12px',
        padding: containerPadding,
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
      }}>
        <label style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '12px',
          color: '#ffffff',
          fontSize: fontSize
        }}>
          <IoMusicalNotesOutline size={18} />
          Background Music
        </label>
        <select
          value={localMusic}
          onChange={handleMusicSelect}
          disabled={isMusicLoading || showMainLoading}
          style={{
            width: '100%',
            padding: isMobile ? '10px' : '12px',
            backgroundColor: '#2a2a2e',
            color: '#ffffff',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '8px',
            fontSize: fontSize,
            appearance: 'none',
            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 12px center',
            backgroundSize: '16px',
            opacity: showMainLoading ? 0.7 : 1
          }}
        >
          <option value="">No background music</option>
          {Array.isArray(musicLibrary) && musicLibrary.map((track) => {
            if (!track || !track.name) return null;
            const displayName = track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
            return (
              <option key={track.name} value={track.name}>
                {displayName}
              </option>
            );
          })}
        </select>
      </div>

      {/* Volume Controls */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        background: 'rgba(25,25,35,0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.08)',
        borderRadius: '12px',
        padding: containerPadding,
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        opacity: showMainLoading ? 0.7 : 1
      }}>
        <h3 style={{
          fontSize: isMobile ? '1rem' : '1.1rem',
          fontWeight: '500',
          marginBottom: '16px',
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <IoVolumeHighOutline size={18} />
          Volume Levels
        </h3>
        <div>
          {/* Voice Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '16px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoPersonOutline size={16} />
              Voice
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={localVolume.tts_volume}
                onChange={(e) => handleVolumeChange('tts_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.tts_volume),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {(localVolume.tts_volume * 100).toFixed(0)}%
            </div>
          </div>
          {/* Music Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '16px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoMusicalNotesOutline size={16} />
              Music
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="0.2"
                step="0.01"
                value={localVolume.bg_volume}
                onChange={(e) => handleVolumeChange('bg_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.bg_volume, 0, 0.2),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {((localVolume.bg_volume / 0.2) * 100).toFixed(0)}%
            </div>
          </div>
          {/* Master Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoVolumeHighOutline size={16} />
              Master
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={localVolume.overall_volume}
                onChange={(e) => handleVolumeChange('overall_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.overall_volume),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {(localVolume.overall_volume * 100).toFixed(0)}%
            </div>
          </div>
        </div>
      </div>
  
      {/* Audio Effects */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        background: 'rgba(25,25,35,0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.08)',
        borderRadius: '12px',
        padding: containerPadding,
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        opacity: showMainLoading ? 0.7 : 1
      }}>
        <div className="step-header" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <h3 style={{
            fontSize: isMobile ? '1rem' : '1.1rem',
            fontWeight: '500',
            color: '#ffffff',
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 3v2"></path>
              <path d="M19.07 6.93l-1.41 1.41"></path>
              <path d="M21 12h-2"></path>
              <path d="M19.07 17.07l-1.41-1.41"></path>
              <path d="M12 19v2"></path>
              <path d="M6.34 17.07l1.41-1.41"></path>
              <path d="M3 12h2"></path>
              <path d="M6.34 6.93l1.41 1.41"></path>
              <circle cx="12" cy="12" r="4"></circle>
            </svg>
            Audio Effects
          </h3>
          <button
            style={{
              background: 'transparent',
              border: 'none',
              color: '#7d12ff',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              cursor: showMainLoading ? 'default' : 'pointer',
              fontSize: fontSize,
              padding: '6px 10px',
              borderRadius: '6px',
              transition: 'background-color 0.2s ease',
              opacity: showMainLoading ? 0.7 : 1
            }}
            onClick={() => !showMainLoading && setShowAdvanced(!showAdvanced)}
            disabled={showMainLoading}
          >
            {showAdvanced ? 'Hide Effects' : 'Show Effects'}
            {showAdvanced ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
          </button>
        </div>

        {showAdvanced && (
        <>
          {/* Effect Type Selector Tabs */}
          <div style={{
            display: 'flex',
            marginBottom: '20px',
            background: 'rgba(20,20,30,0.5)',
            borderRadius: '8px',
            padding: '4px',
            position: 'relative'
          }}>
            <button
              onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'reverb' ? null : 'reverb')}
              style={{
                flex: 1,
                padding: '10px 12px',
                background: expandedFilter === 'reverb' ? 'rgba(125,18,255,0.2)' : 'transparent',
                border: 'none',
                borderRadius: '6px',
                color: expandedFilter === 'reverb' ? '#ffffff' : 'rgba(255,255,255,0.7)',
                fontWeight: expandedFilter === 'reverb' ? '600' : '400',
                fontSize: '14px',
                cursor: showMainLoading ? 'default' : 'pointer',
                transition: 'all 0.2s ease'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                  <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                </svg>
                Room Space {localFilters.reverb?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
              </div>
            </button>
            <button
              onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'stereoPan' ? null : 'stereoPan')}
              style={{
                flex: 1,
                padding: '10px 12px',
                background: expandedFilter === 'stereoPan' ? 'rgba(125,18,255,0.2)' : 'transparent',
                border: 'none',
                borderRadius: '6px',
                color: expandedFilter === 'stereoPan' ? '#ffffff' : 'rgba(255,255,255,0.7)',
                fontWeight: expandedFilter === 'stereoPan' ? '600' : '400',
                fontSize: '14px',
                cursor: showMainLoading ? 'default' : 'pointer',
                transition: 'all 0.2s ease'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M9 18V5l12-2v13"></path>
                  <circle cx="6" cy="18" r="3"></circle>
                  <circle cx="18" cy="16" r="3"></circle>
                </svg>
                Spatial Audio {localFilters.stereoPan?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
              </div>
            </button>
            <button
              onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'equalizer' ? null : 'equalizer')}
              style={{
                flex: 1,
                padding: '10px 12px',
                background: expandedFilter === 'equalizer' ? 'rgba(125,18,255,0.2)' : 'transparent',
                border: 'none',
                borderRadius: '6px',
                color: expandedFilter === 'equalizer' ? '#ffffff' : 'rgba(255,255,255,0.7)',
                fontWeight: expandedFilter === 'equalizer' ? '600' : '400',
                fontSize: '14px',
                cursor: showMainLoading ? 'default' : 'pointer',
                transition: 'all 0.2s ease'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line>
                  <line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line>
                  <line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line>
                  <line x1="17" y1="16" x2="23" y2="16"></line>
                </svg>
                Tone {localFilters.eq?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
              </div>
            </button>
          </div>
          
          {/* Room Space Effect */}
          {expandedFilter === 'reverb' && (
            <div>
              <div style={{ 
                fontSize: '14px', 
                color: 'rgba(255,255,255,0.7)', 
                marginBottom: '12px',
                textAlign: 'center' 
              }}>
                Select the type of space for your meditation voice:
              </div>
              
              <div style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
                gap: '12px',
                marginBottom: '16px'
              }}>
                {/* Option 1: None */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      toggleFilter('reverb');
                      if (localFilters.reverb?.enabled) {
                        setTimeout(() => setExpandedFilter(null), 500);
                      }
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: !localFilters.reverb?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: !localFilters.reverb?.enabled ? 'translateY(-2px)' : 'none',
                    boxShadow: !localFilters.reverb?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: !localFilters.reverb?.enabled ? '#7d12ff' : 'transparent'
                    }}>
                      {!localFilters.reverb?.enabled && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: !localFilters.reverb?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    No Effect
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Clean audio without any room ambience
                  </p>
                </div>
                
                {/* Option 2: Intimate Space */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      handlePresetSelect('reverb', 'Intimate Room');
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? 'translateY(-2px)' : 'none',
                    boxShadow: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'transparent'
                    }}>
                      {localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    Intimate Space
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Cozy room with gentle acoustics
                  </p>
                </div>
                
                {/* Option 3: Expansive Hall */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      handlePresetSelect('reverb', 'Sacred Hall');
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? 'translateY(-2px)' : 'none',
                    boxShadow: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'transparent'
                    }}>
                      {localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    Expansive Hall
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Open, cathedral-like reverberations
                  </p>
                </div>
              </div>
              
              {/* Selected Effect Summary */}
              {localFilters.reverb?.enabled && (
                <div style={{
                  padding: '10px',
                  backgroundColor: 'rgba(125,18,255,0.1)',
                  borderRadius: '8px',
                  marginTop: '12px',
                  textAlign: 'center'
                }}>
                  <p style={{
                    margin: 0,
                    fontSize: '14px',
                    color: '#ffffff'
                  }}>
                    Room Space: <strong>{localFilters.reverb.preset || 'Custom'}</strong> {' '}
                    <button
                      onClick={() => toggleFilter('reverb')}
                      style={{
                        background: 'rgba(255,255,255,0.1)',
                        border: 'none',
                        padding: '3px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: 'white',
                        cursor: 'pointer',
                        marginLeft: '8px'
                      }}
                    >
                      Turn Off
                    </button>
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Spatial Audio Effect */}
          {expandedFilter === 'stereoPan' && (
            <div>
              <div style={{ 
                fontSize: '14px', 
                color: 'rgba(255,255,255,0.7)', 
                marginBottom: '12px',
                textAlign: 'center' 
              }}>
                Choose the spatial movement for your audio:
              </div>
              
              <div style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
                gap: '12px',
                marginBottom: '16px'
              }}>
                {/* Option 1: No Movement */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      toggleFilter('stereoPan');
                      if (localFilters.stereoPan?.enabled) {
                        setTimeout(() => setExpandedFilter(null), 500);
                      }
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: !localFilters.stereoPan?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: !localFilters.stereoPan?.enabled ? 'translateY(-2px)' : 'none',
                    boxShadow: !localFilters.stereoPan?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: !localFilters.stereoPan?.enabled ? '#7d12ff' : 'transparent'
                    }}>
                      {!localFilters.stereoPan?.enabled && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: !localFilters.stereoPan?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    No Movement
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Standard stereo without spatial movement
                  </p>
                </div>
                
                {/* Option 2: Gentle Movement */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      handlePresetSelect('stereoPan', 'Gentle Movement');
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? 'translateY(-2px)' : 'none',
                    boxShadow: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'transparent'
                    }}>
                      {localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M2 12h20"></path>
                      <path d="M16 7l5 5-5 5"></path>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    Subtle Movement
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Gentle stereo movement for light immersion
                  </p>
                </div>
                
                {/* Option 3: Meditative Space */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      handlePresetSelect('stereoPan', 'Meditative Space');
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? 'translateY(-2px)' : 'none',
                    boxShadow: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px'
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'transparent'
                    }}>
                      {localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="4.93" y1="4.93" x2="9.17" y2="9.17"></line>
                      <line x1="14.83" y1="14.83" x2="19.07" y2="19.07"></line>
                      <line x1="14.83" y1="9.17" x2="19.07" y2="4.93"></line>
                      <line x1="4.93" y1="19.07" x2="9.17" y2="14.83"></line>
                    </svg>
                  </div>
                  
                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    Deep Immersion
                  </h4>
                  
                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Enhanced spatial movement with binaural effect
                  </p>
                </div>
              </div>
              
              {/* Selected Effect Summary */}
              {localFilters.stereoPan?.enabled && (
                <div style={{
                  padding: '10px',
                  backgroundColor: 'rgba(125,18,255,0.1)',
                  borderRadius: '8px',
                  marginTop: '12px',
                  textAlign: 'center'
                }}>
                  <p style={{
                    margin: 0,
                    fontSize: '14px',
                    color: '#ffffff'
                  }}>
                    Spatial Audio: <strong>{
                      localFilters.stereoPan.preset === 'Gentle Movement' ? 'Subtle Movement' :
                      localFilters.stereoPan.preset === 'Meditative Space' ? 'Deep Immersion' :
                      localFilters.stereoPan.preset || 'Custom'
                    }</strong> {' '}
                    <button
                      onClick={() => toggleFilter('stereoPan')}
                      style={{
                        background: 'rgba(255,255,255,0.1)',
                        border: 'none',
                        padding: '3px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: 'white',
                        cursor: 'pointer',
                        marginLeft: '8px'
                      }}
                    >
                      Turn Off
                    </button>
                  </p>
                </div>
              )}
            </div>
          )}
          
          {/* Tone Effect */}
          {expandedFilter === 'equalizer' && (
            <div>
              <div style={{ 
                fontSize: '14px', 
                color: 'rgba(255,255,255,0.7)', 
                marginBottom: '12px',
                textAlign: 'center' 
              }}>
                Select voice tone enhancement:
              </div>
              
              <div style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
                gap: '12px',
                marginBottom: '16px'
              }}>
                {/* Option 1: No Enhancement */}
                <div
                  onClick={() => {
                    if (!showMainLoading) {
                      toggleFilter('equalizer');
                      if (localFilters.eq?.enabled) {
                        setTimeout(() => setExpandedFilter(null), 500);
                      }
                    }
                  }}
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
                    backgroundColor: !localFilters.eq?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
                    padding: '16px',
                    cursor: showMainLoading ? 'default' : 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    transform: !localFilters.eq?.enabled ? 'translateY(-2px)' : 'none',
                    boxShadow: !localFilters.eq?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
                  }}
                >
                  <div style={{ 
                    position: 'absolute', 
                    top: '14px',right: '14px' 
                  }}>
                    <div style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: `2px solid ${!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: !localFilters.eq?.enabled ? '#7d12ff' : 'transparent'
                    }}>
                      {!localFilters.eq?.enabled && (
                        <div style={{ 
                          width: '10px', 
                          height: '10px', 
                          borderRadius: '50%', 
                          backgroundColor: 'white' 
                        }}></div>
                      )}
                    </div>
                  </div>

                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>

                  <h4 style={{ 
                    margin: '0 0 6px 0', 
                    textAlign: 'center',
                    color: !localFilters.eq?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
                    fontSize: '16px',
                    fontWeight: '600'
                  }}>
                    No Enhancement
                  </h4>

                  <p style={{ 
                    margin: 0, 
                    fontSize: '13px', 
                    color: 'rgba(255,255,255,0.6)',
                    textAlign: 'center'
                  }}>
                    Natural voice without EQ adjustment
                  </p>
                </div>

                {/* Option 2: Enhanced Clarity */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Voice Clarity preset");
                handlePresetSelect('equalizer', 'Voice Clarity');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{ 
              position: 'absolute', 
              top: '14px', 
              right: '14px' 
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
              </svg>
            </div>

            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Enhanced Clarity
            </h4>

            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Boosted mid-range for clearer voice
            </p>
          </div>

          {/* Option 3: Warm Tone */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Warm Voice preset");
                handlePresetSelect('equalizer', 'Warm Voice');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px',
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                <line x1="15" y1="9" x2="15.01" y2="9"></line>
              </svg>
            </div>

            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Warm Tone
            </h4>

            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Smooth, relaxing bass enhancement
            </p>
          </div>
        </div>
        
        {/* ADDED: Selected Effect Summary */}
        {localFilters.eq?.enabled && (
          <div style={{
            padding: '10px',
            backgroundColor: 'rgba(125,18,255,0.1)',
            borderRadius: '8px',
            marginTop: '12px',
            textAlign: 'center'
          }}>
            <p style={{
              margin: 0,
              fontSize: '14px',
              color: '#ffffff'
            }}>
              Tone: <strong>{localFilters.eq.preset || 'Custom'}</strong> {' '}
              <button
                onClick={() => toggleFilter('equalizer')}
                style={{
                  background: 'rgba(255,255,255,0.1)',
                  border: 'none',
                  padding: '3px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '8px'
                }}
              >
                Turn Off
              </button>
            </p>
          </div>
        )}
      </div>
    )}

    {/* Visual indicator of effect combination */}
    <div style={{
      marginTop: '20px',
      padding: '12px',
      background: 'rgba(20,20,30,0.3)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div style={{ 
        fontSize: '14px', 
        color: 'rgba(255,255,255,0.7)',
        marginRight: isMobile ? '0' : '10px',
        textAlign: isMobile ? 'center' : 'left'
      }}>
        Active effects:
      </div>

      <div style={{ 
        display: 'flex', 
        gap: '10px',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        {!localFilters.reverb?.enabled && !localFilters.stereoPan?.enabled && !localFilters.eq?.enabled ? (
          <span style={{
            padding: '5px 10px',
            borderRadius: '20px',
            background: 'rgba(255,255,255,0.1)',
            fontSize: '13px',
            color: 'rgba(255,255,255,0.6)'
          }}>
            No effects
          </span>
        ) : (
          <>
            {localFilters.reverb?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                  <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                </svg>
                {localFilters.reverb?.preset === 'Intimate Room' ? 'Intimate Space' : 
                localFilters.reverb?.preset === 'Sacred Hall' ? 'Expansive Hall' : 
                localFilters.reverb?.preset}
              </span>
            )}

            {localFilters.stereoPan?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M9 18V5l12-2v13"></path>
                  <circle cx="6" cy="18" r="3"></circle>
                  <circle cx="18" cy="16" r="3"></circle>
                </svg>
                {localFilters.stereoPan?.preset === 'Gentle Movement' ? 'Subtle Movement' : 
                localFilters.stereoPan?.preset === 'Meditative Space' ? 'Deep Immersion' : 
                localFilters.stereoPan?.preset}
              </span>
            )}

            {localFilters.eq?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line>
                  <line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line>
                  <line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line>
                  <line x1="17" y1="16" x2="23" y2="16"></line>
                </svg>
                {localFilters.eq?.preset}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  </>
  )}
</div>

      {/* Action Button with modified button style based on TTS state */}
      <div className="action-buttons" style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        marginTop: '32px',
        width: '100%',
        padding: '0 16px'
      }}>
    <button
      onClick={handleContinue}
      disabled={
        !localMusic ||
        showMainLoading ||
        !isTTSReadyForMixing()
      }
      style={{
        background: '#7d12ff',
        color: '#fff',
        border: 'none',
        borderRadius: '12px',
        padding: isSmallMobile ? '12px 24px' : '14px 28px',
        fontSize: isSmallMobile ? '0.95rem' : '1rem',
        fontWeight: '500',
        cursor: (
          !localMusic ||
          showMainLoading ||
          !isTTSReadyForMixing()
        ) ? 'not-allowed' : 'pointer',
        opacity: (
          !localMusic ||
          showMainLoading ||
          !isTTSReadyForMixing()
        ) ? 0.5 : 1,
        width: isSmallMobile ? '100%' : 'auto',
        minWidth: isSmallMobile ? 'auto' : '260px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        boxShadow: '0 4px 12px rgba(125, 18, 255, 0.25)'
      }}
    >
      {isLoading ? (
        <>
          <span className="spinner-small" style={{
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            border: '2px solid #ffffff',
            borderTopColor: 'transparent',
            animation: 'spin 1s linear infinite',
            verticalAlign: 'middle'}}></span>
          <span>Processing...</span>
        </>
      ) : !ttsReady ? (
        <>
          <span>Waiting for Voice...</span>
          <span style={{ fontSize: '0.8rem', marginLeft: '4px' }}>({Math.floor(localTTSProgress)}%)</span>
        </>
      ) : (
        <>
          <span>Continue to Listen</span>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </>
      )}
    </button>
    
    {/* TTSProgressIndicator - only show when processing */}
    {localTTSProcessing && localTTSProgress < 100 && (
      <TTSProgressIndicator 
        isBackgroundTTSProcessing={localTTSProcessing}
        backgroundTTSProgress={localTTSProgress}
        ttsKey={ttsKey}
        isMobile={isMobile}
        onComplete={() => {
          setIsTTSComplete(true);
          setTtsReady(true);
          setLocalTTSProgress(100);
          setLocalTTSProcessing(false);
          console.log("TTS generation complete in AudioStep");
        }}
      />
    )}
  </div>

      {/* Help text when TTS is processing */}
      {isBackgroundTTSProcessing && backgroundTTSProgress < 85 && (
        <div style={{
          fontSize: '13px',
          color: 'rgba(255,255,255,0.7)',
          textAlign: 'center',
          marginTop: '10px',
          padding: '8px 12px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
          maxWidth: '450px',
          margin: '10px auto 0'
        }}>
          Voice generation must complete before continuing. This ensures highest quality audio.
        </div>
      )}

      {/* Add the style element properly, within the return statement */}
      <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
      <style dangerouslySetInnerHTML={{ __html: loadingAnimationStyles }} />
    </div>
  );
};

export default AudioStep;