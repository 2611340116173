/**
 * Centralized utilities for managing transition flags
 * Helps prevent stuck states and conflicts between components
 */

/**
 * Clears all transition flags in window to resolve stuck states
 * @param {Object} options - Configuration options
 * @param {boolean} options.log - Whether to log the operation
 * @returns {boolean} Success status
 */
export function clearAllTransitionFlags(options = { log: true }) {
    if (options.log) {
      console.log("transitionUtils: Forcefully clearing all transition flags");
    }
    
    if (typeof window === 'undefined') {
      return false;
    }
    
    // Clear all transition-related window flags
    try {
      // Core transition flags
      window.__transitionLock = false;
      window.__transitionInProgress = false;
      window.__waveSurferLock = false;
      window.__audioStepTransitionStarted = false;
      
      // Step transition flags
      window.__transitionFromStep = null;
      window.__transitionToStep = null;
      window.__transitionTimestamp = null;
      
      // Audio state flags
      window.__transitionPosition = null;
      window.__transitionVolume = null;
      window.__fsTransitionPlaying = null;
      window.__fsTransitionTime = null;
      window.__audioUrlForTransition = null;
      window.__audioPlayingForTransition = null;
      window.__ttsKeyForTransition = null;
      window.__musicForTransition = null;
      
      // Completion flags
      window.__transitionCompleted = true;
      window.__transitionCompletedTime = Date.now();
      
      // Also update TransitionManager if available
      if (window.TransitionManager && typeof window.TransitionManager.clearAllTransitionFlags === 'function') {
        window.TransitionManager.clearAllTransitionFlags();
      }
      
      return true;
    } catch (error) {
      console.error("transitionUtils: Error clearing transition flags", error);
      return false;
    }
  }
  
  /**
   * Detects if any transition flags are active
   * @returns {boolean} Whether any transition flags are active
   */
  export function isAnyTransitionActive() {
    return (
      window.__transitionInProgress === true ||
      window.__transitionLock === true || 
      window.__waveSurferLock === true ||
      window.__audioStepTransitionStarted === true ||
      (window.TransitionManager && typeof window.TransitionManager.isTransitionActive === 'function' 
       && window.TransitionManager.isTransitionActive()) ||
      (window.globalAudioState && typeof window.globalAudioState.isInTransition === 'function' 
       && window.globalAudioState.isInTransition())
    );
  }
  
  /**
   * Performs a health check on transition system and fixes stuck flags
   * @returns {Object} Status report
   */
  export function performTransitionHealthCheck() {
    if (typeof window === "undefined") return true;
    
    const now = Date.now();
    const stuckTransitionThreshold = 10000; // 10 seconds
    
    // Check if there's a stuck transition
    if (window.__transitionInProgress === true && window.__transitionTimestamp) {
      const transitionDuration = now - window.__transitionTimestamp;
      if (transitionDuration > stuckTransitionThreshold) {
        console.warn(`Detected stuck transition (${transitionDuration}ms). Clearing flags.`);
        clearAllTransitionFlags();
        return false;
      }
    }
    
    // Check if there's a stuck URL lock
    if (window.__transitionLock === true && window.__transitionTimestamp) {
      const lockDuration = now - window.__transitionTimestamp;
      if (lockDuration > stuckTransitionThreshold) {
        console.warn(`Detected stuck URL lock (${lockDuration}ms). Clearing flags.`);
        clearAllTransitionFlags();
        return false;
      }
    }
    
    // Check if WaveSurfer lock is stuck
    if (window.__waveSurferLock === true) {
      if (!window.__transitionInProgress && !window.__transitionLock) {
        console.warn("Detected orphaned WaveSurfer lock without active transition. Clearing.");
        window.__waveSurferLock = false;
        return false;
      }
    }
    
    return true;
  }
  
  /**
   * Sets up periodic monitoring of transition flags
   * @param {number} interval - Check interval in milliseconds
   * @returns {Function} Cleanup function to stop monitoring
   */
  export function monitorTransitionFlags(interval = 10000) {
    if (typeof window === 'undefined') {
      return () => {};
    }
    
    // Avoid setting up duplicate monitors
    if (window.__transitionMonitorInterval) {
      clearInterval(window.__transitionMonitorInterval);
    }
    
    // Set up interval to check for stuck transitions
    const intervalId = setInterval(() => {
      performTransitionHealthCheck();
    }, interval);
    
    window.__transitionMonitorInterval = intervalId;
    
    // Return cleanup function
    return () => {
      if (window.__transitionMonitorInterval) {
        clearInterval(window.__transitionMonitorInterval);
        window.__transitionMonitorInterval = null;
      }
    };
  }
  
  export default {
    clearAllTransitionFlags,
    isAnyTransitionActive,
    performTransitionHealthCheck,
    monitorTransitionFlags
  };
  