// src/pages/MeditationApp/MeditationApp.js

import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Global state and hooks
import globalAudioState from './components/audio/globalAudioState';

// Step components
import CreateStep from './components/steps/CreateStep';
import ScriptStep from './components/steps/ScriptStep';
import VoiceStep from './components/steps/VoiceStep';
import MusicSelection from './components/steps/MusicSelection';
import AudioStep from './components/steps/AudioStep';

// Common components
import ProgressBar from './components/common/ProgressBar';
import StepNavigation from './components/common/StepNavigation';
import LoadingOverlay from './components/common/LoadingOverlay';
import CustomToastContainer from './components/common/CustomToast';

// Theme and layout
import { ThemeProvider } from '../Dashboard/components/contexts/ThemeContext';
import { useLayout } from '../Dashboard/components/layout/AppLayout';

// Styles and external services
import './App.css';
import audioService from '../../services/audioService';
import sseManager from './utils/sseManager';
import 'react-toastify/dist/ReactToastify.css';

// Audio utilities
import { createSilentAudioBlob } from './components/audio/SilentAudioBlob';
import TTSProgressIndicator from './components/common/TTSProgressIndicator';
import TransitionManager from './components/audio/TransitionManager';
import { clearAllTransitionFlags, performTransitionHealthCheck } from './utils/transitionUtils';

/* ===================== ErrorBoundary Component ===================== */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 20, color: 'red' }}>
          An error occurred in the audio player.
        </div>
      );
    }
    return this.props.children;
  }
}

/* ===================== Helper Functions ===================== */
const OPERATION_PHASES = {
  PREPARATION: "preparation",
  SCRIPT_GENERATION: "script_generation",
  SCRIPT_PROCESSING: "script_processing",
  VOICE_SYNTHESIS: "voice_synthesis",
  AUDIO_LOADING: "audio_loading",
  AUDIO_PROCESSING: "audio_processing",
  AUDIO_EFFECTS: "audio_effects",
  EXPORT: "export",
  FINALIZATION: "finalization"
};

const isValidUrl = (url) =>
  !!(url && typeof url === 'string' &&
    (url.startsWith('http://') ||
     url.startsWith('https://') ||
     url.startsWith('blob:') ||
     url.startsWith('data:')));

const getTtsUrl = (filename, baseUrl) => {
  if (!filename) return '';
  const DEFAULT_API_BASE = 'https://api.pause.site';
  let apiBase = baseUrl || process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE;
  if (!apiBase || apiBase === 'undefined' || typeof apiBase !== 'string') {
    console.warn('Invalid API base URL detected, using default:', DEFAULT_API_BASE);
    apiBase = DEFAULT_API_BASE;
  }
  let cleanFilename = filename.includes('/') ? filename.split('/').pop() : filename;
  if (cleanFilename.startsWith("TTS/")) cleanFilename = cleanFilename.substring(4);
  const url = `${apiBase}/tts/${cleanFilename}`;
  if (url.includes('undefined') || !url.startsWith('http')) {
    console.error('Generated invalid TTS URL:', url);
    return `${DEFAULT_API_BASE}/tts/${cleanFilename}`;
  }
  return url;
};

/* ===================== Main Component ===================== */
const MeditationApp = () => {
  const navigate = useNavigate();
  const toastInstance = toast;
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

  // ===================== TTS STATE MANAGEMENT =====================
  // These states manage the TTS process both locally and globally.
  const [ttsProcessing, setTtsProcessing] = useState(false);
  const [ttsProgress, setTtsProgress] = useState(0);
  const [ttsKey, setTtsKey] = useState(null);
  const [ttsCompleted, setTtsCompleted] = useState(false);

  // Safety timeout ref to ensure TTS eventually completes.
  const ttsTimeoutId = useRef(null);

  const clearTTSSafetyTimeout = useCallback(() => {
    if (ttsTimeoutId.current) {
      clearTimeout(ttsTimeoutId.current);
      ttsTimeoutId.current = null;
    }
  }, []);

  const handleTTSStart = useCallback(() => {
    console.log("MeditationApp: TTS processing started");
    setTtsProcessing(true);
    setTtsProgress(0);

    // Update global audio state
    if (globalAudioState && typeof globalAudioState.startTTSProcessing === 'function') {
      globalAudioState.startTTSProcessing();
    }

    clearTTSSafetyTimeout();
    ttsTimeoutId.current = setTimeout(() => {
      console.log("TTS safety timeout triggered");
      handleTTSComplete();
    }, 60000); // 60 second safety timeout
  }, [clearTTSSafetyTimeout]);

  const handleTTSProgress = useCallback((progress) => {
    if (typeof progress !== 'number' || isNaN(progress)) return;
    // Prevent progress regression
    if (progress < ttsProgress) return;

    setTtsProgress(progress);
    if (globalAudioState && typeof globalAudioState.updateTTSProgress === 'function') {
      globalAudioState.updateTTSProgress(progress);
    }

    if (progress >= 100) {
      handleTTSComplete();
    }
  }, [ttsProgress]);

  const handleTTSComplete = useCallback(() => {
    console.log("MeditationApp: TTS processing complete");
    clearTTSSafetyTimeout();

    setTtsProcessing(false);
    setTtsProgress(100);
    setTtsCompleted(true);

    if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
      globalAudioState.completeTTSProcessing();
    }

    // Set global flags for other components
    if (typeof window !== 'undefined') {
      window.__ttsCompleted = true;
      window.__ttsCompletionTime = Date.now();
    }
  }, [clearTTSSafetyTimeout]);

  // Clear safety timeout on unmount.
  useEffect(() => {
    return () => {
      clearTTSSafetyTimeout();
    };
  }, [clearTTSSafetyTimeout]);

  // ===================== OTHER STATE MANAGEMENT =====================
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [error, setError] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [customPrompt, setCustomPrompt] = useState('');
  const [meditationScript, setMeditationScript] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('onyx');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [musicLibrary, setMusicLibrary] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [volume, setVolume] = useState({ overall_volume: 1, tts_volume: 1, bg_volume: 0.2 });
  const [filters, setFilters] = useState({
    stereoPan: { enabled: false, value: 0.5, speed: 30, pattern: 'sine' },
    reverb: { enabled: false, wet: 0.5, dry: 1.0, preset: '' },
    eq: { enabled: false, low_cut: 80, mid_gain: 3.0, mid_freq: 1000, mid_Q: 1.0, high_cut: 12000, preset: '' }
  });
  const [isMixingAudio, setIsMixingAudio] = useState(false);
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [lastMixedAudio, setLastMixedAudio] = useState(null);
  const [musicSelectionComplete, setMusicSelectionComplete] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [errorRecoveryMode, setErrorRecoveryMode] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionMessage, setTransitionMessage] = useState('');
  const [transitionSubtitle, setTransitionSubtitle] = useState('');
  const [transitionDirection, setTransitionDirection] = useState(null);

  const audioElementRef = useRef(null);
  const mounted = useRef(true);

  // ===================== LAYOUT / WINDOW RESIZE =====================
  const { isSidebarOpen, isNotificationsOpen } = useLayout();

  useEffect(() => {
    const handleResize = () => setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ===================== GLOBAL TTS SUBSCRIPTIONS =====================
  // Subscribe to globalAudioState events for TTS progress
  useEffect(() => {
    const handleGlobalTTSStart = () => {
      console.log("MeditationApp: Global TTS processing started");
      setTtsProcessing(true);
      setTtsProgress(0);
    };

    const handleGlobalTTSProgress = (data) => {
      if (data && typeof data.progress === 'number') {
        // Limit console noise by logging every 10%
        if (Math.floor(data.progress) % 10 === 0) {
          console.log("MeditationApp: Global TTS progress update:", Math.floor(data.progress));
        }
        setTtsProgress(data.progress);
      }
    };

    const handleGlobalTTSComplete = () => {
      console.log("MeditationApp: Global TTS processing completed");
      setTtsProcessing(false);
      setTtsProgress(100);
    };

    const ttsStartSub = globalAudioState.subscribe('tts-processing-started', handleGlobalTTSStart);
    const ttsProgressSub = globalAudioState.subscribe('tts-progress-updated', handleGlobalTTSProgress);
    const ttsCompleteSub = globalAudioState.subscribe('tts-processing-completed', handleGlobalTTSComplete);

    return () => {
      ttsStartSub();
      ttsProgressSub();
      ttsCompleteSub();
    };
  }, []);

  // ===================== SESSION STORAGE & DEBUGGING =====================
  useEffect(() => {
    console.log("SessionStorage - ttsKey:", sessionStorage.getItem("ttsKey"));
    console.log("SessionStorage - ttsAudioUrl:", sessionStorage.getItem("ttsAudioUrl"));
    console.log("SessionStorage - mixedAudioUrl:", sessionStorage.getItem("mixedAudioUrl"));
    console.log("SessionStorage - currentAudioUrl:", sessionStorage.getItem("currentAudioUrl"));
    console.log("SessionStorage - stableTransitionUrl:", sessionStorage.getItem("stableTransitionUrl"));
    console.log("SessionStorage - urlPriority:", sessionStorage.getItem("urlPriority"));
  }, []);

  useEffect(() => {
    console.log("MeditationApp rendering state:", { currentStep, audioUrl, ttsKey });
  }, [currentStep, audioUrl, ttsKey]);

  const markStepCompleted = useCallback((step) => {
    if (!completedSteps.includes(step)) {
      const uniqueSteps = [...new Set([...completedSteps, step])];
      setCompletedSteps(uniqueSteps);
      try {
        sessionStorage.setItem('completedStep_' + step, 'true');
      } catch (e) {
        console.warn("Error storing completed step:", e);
      }
    }
  }, [completedSteps]);

  // ===================== SCRIPT GENERATION =====================
  const handleGenerateScript = useCallback(async (prompt) => {
    if (!prompt || !mounted.current) return null;
    setIsScriptLoading(true);
    const toastId = toastInstance.loading("Creating your meditation script...", { toastId: 'script-generation' });
    try {
      // Simulated SSE loading and API call for script generation
      await new Promise((resolve) => setTimeout(resolve, 300));
      const response = await fetch(`${baseUrl}/generate-ssml`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt,
          selected_options: selectedOptions,
          selected_voice: selectedVoice,
          globalPauseFactor: 1.2
        })
      });
      if (!response.ok) {
        const errorText = await response.text();
        toastInstance.update(toastId, { render: `Script generation failed: ${response.status}`, type: "error", autoClose: 5000 });
        throw new Error(`Server error: ${response.status} - ${errorText}`);
      }
      const data = await response.json();
      if (data.status === 'success') {
        const rawScript = data.data?.raw_script || data.raw_script;
        const cleanedScript = rawScript.replace(/\n{3,}/g, '\n\n').replace(/\s{2,}/g, ' ').trim();
        if (mounted.current) {
          setMeditationScript(cleanedScript);
          setIsScriptGenerated(true);
        }
        markStepCompleted(1);
        toastInstance.update(toastId, { render: 'Script generated successfully!', type: "success", autoClose: 3000 });
        return cleanedScript;
      } else {
        toastInstance.update(toastId, { render: data.message || 'Failed to generate script', type: "error", autoClose: 5000 });
        throw new Error(data.message || 'Failed to generate script');
      }
    } catch (err) {
      console.error('Script generation failed:', err);
      toastInstance.error(`Script generation failed: ${err.message}`, { toastId: 'script-generation', autoClose: 5000 });
      return null;
    } finally {
      if (mounted.current) setIsScriptLoading(false);
    }
  }, [baseUrl, selectedOptions, selectedVoice, toastInstance, mounted, markStepCompleted]);

  // ===================== TTS VOICE GENERATION =====================
  const handleCreateTTS = useCallback(async () => {
    if (!meditationScript.trim()) {
      toastInstance.error('Meditation script cannot be empty');
      return;
    }
    console.log("Starting voice synthesis process");
    setTtsProcessing(true);
    setTtsProgress(5);
    if (globalAudioState && typeof globalAudioState.startTTSProcessing === 'function') {
      globalAudioState.startTTSProcessing();
    }
    // Set global flags
    if (typeof window !== 'undefined') {
      window.__ttsStarted = true;
      window.__ttsStartTime = Date.now();
      window.__ttsProcessing = true;
    }
    // Clear any job ID if you use one for script generation
    markStepCompleted(3);
    setCurrentStep(4);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toastInstance.info("Voice is being generated in the background while you select music", { toastId: 'background-tts', autoClose: 5000 });

    // Set up safety progress simulation (can be replaced by real SSE events)
    const progressTimer = setInterval(() => {
      setTtsProgress(prev => {
        const newProgress = Math.min(prev + Math.random() * 2, 85);
        if (globalAudioState && typeof globalAudioState.updateTTSProgress === 'function') {
          globalAudioState.updateTTSProgress(newProgress);
        }
        return newProgress;
      });
    }, 1000);

    const safetyTimeoutId = setTimeout(() => {
      console.log("TTS safety timeout triggered");
      clearInterval(progressTimer);
      if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
        globalAudioState.completeTTSProcessing();
      }
      if (typeof window !== 'undefined') {
        window.__ttsCompleted = true;
        window.__ttsCompletionTime = Date.now();
      }
      setTtsProgress(100);
      setTtsProcessing(false);
    }, 60000);

    try {
      const result = await audioService.createTTS({
        text: meditationScript,
        voice: selectedVoice,
        speaking_rate: 1.0
      });
      clearInterval(progressTimer);
      clearTimeout(safetyTimeoutId);
      if (result) {
        // Handle placeholder/silent audio case
        if (result.placeholder || result.silent) {
          console.log("TTS returned placeholder/silent audio:", result);
          let finalTtsUrl, finalTtsKey;
          if (result.localBlob && result.filePath && result.filePath.startsWith('blob:')) {
            finalTtsUrl = result.filePath;
            finalTtsKey = result.filename || `silent_audio_${Date.now()}.mp3`;
          } else {
            const silentUrl = createSilentAudioBlob();
            finalTtsUrl = silentUrl || result.filePath;
            finalTtsKey = result.filename || `silent_audio_${Date.now()}.mp3`;
          }
          setTtsKey(finalTtsKey);
          setAudioUrl(finalTtsUrl);
          if (globalAudioState && typeof globalAudioState.updateTTSKey === 'function') {
            globalAudioState.updateTTSKey(finalTtsKey, finalTtsUrl);
          }
          if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
            globalAudioState.completeTTSProcessing();
          }
          if (typeof window !== 'undefined') {
            window.__ttsCompleted = true;
            window.__ttsCompletionTime = Date.now();
          }
          toastInstance.warning('Voice generation used a silent placeholder. Your meditation will still work.', { autoClose: 5000 });
          setTtsProgress(100);
          setTtsProcessing(false);
          return { success: true, silent: true };
        }

        // Process normal TTS result
        let cleanFilename = result.filename;
        if (cleanFilename.includes('/')) cleanFilename = cleanFilename.split('/').pop();
        if (cleanFilename.startsWith("TTS/")) cleanFilename = cleanFilename.substring(4);

        // Verify TTS file existence on the server
        try {
          const verifyResponse = await fetch(`${baseUrl}/verify-tts/${cleanFilename}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            cache: 'no-cache'
          });
          if (verifyResponse.ok) {
            const verifyData = await verifyResponse.json();
            if (verifyData && verifyData.exists) {
              const finalTtsUrl = verifyData.presigned_url || result.filePath || getTtsUrl(cleanFilename, baseUrl);
              setTtsKey(cleanFilename);
              setAudioUrl(finalTtsUrl);
              if (globalAudioState && typeof globalAudioState.updateTTSKey === 'function') {
                globalAudioState.updateTTSKey(cleanFilename, finalTtsUrl);
              }
              if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
                globalAudioState.completeTTSProcessing();
              }
              if (typeof window !== 'undefined') {
                window.__ttsCompleted = true;
                window.__ttsCompletionTime = Date.now();
              }
              toastInstance.success("Voice generation completed successfully!", { toastId: 'voice-complete-music-step', autoClose: 3000 });
              setTtsProgress(100);
              setTtsProcessing(false);
              return { success: true };
            } else {
              console.warn("TTS verification: file doesn't exist");
            }
          } else {
            console.warn("TTS verification request failed");
          }
        } catch (verifyError) {
          console.error("Error verifying TTS file:", verifyError);
        }
        // Fallback: use TTS URL directly
        const fallbackTtsUrl = result.filePath || getTtsUrl(cleanFilename, baseUrl);
        setTtsKey(cleanFilename);
        setAudioUrl(fallbackTtsUrl);
        if (globalAudioState && typeof globalAudioState.updateTTSKey === 'function') {
          globalAudioState.updateTTSKey(cleanFilename, fallbackTtsUrl);
        }
        if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
          globalAudioState.completeTTSProcessing();
        }
        if (typeof window !== 'undefined') {
          window.__ttsCompleted = true;
          window.__ttsCompletionTime = Date.now();
        }
        if (currentStep === 4) {
          toastInstance.success("Voice generation completed!", { toastId: 'voice-complete-music-step', autoClose: 3000 });
        }
        setTtsProgress(100);
        setTtsProcessing(false);
        return { success: true };
      }
      // If result is falsy, fall back to silent audio
      const silentAudioUrl = createSilentAudioBlob();
      if (silentAudioUrl) {
        const silentFilename = `silent_audio_${Date.now()}.mp3`;
        setTtsKey(silentFilename);
        setAudioUrl(silentAudioUrl);
        if (globalAudioState && typeof globalAudioState.updateTTSKey === 'function') {
          globalAudioState.updateTTSKey(silentFilename, silentAudioUrl);
        }
        if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
          globalAudioState.completeTTSProcessing();
        }
        if (typeof window !== 'undefined') {
          window.__ttsCompleted = true;
          window.__ttsCompletionTime = Date.now();
        }
        toastInstance.warning('Voice generation encountered an issue. Using silent audio for meditation.', { autoClose: 5000 });
      } else {
        toastInstance.error("Failed to generate voice audio.");
      }
      setTtsProgress(100);
      setTtsProcessing(false);
      return { success: false, silent: true };
    } catch (err) {
      clearInterval(progressTimer);
      clearTimeout(safetyTimeoutId);
      console.error(`Error generating TTS: ${err.message || 'Unknown error'}`);
      const silentAudioUrl = createSilentAudioBlob();
      if (silentAudioUrl) {
        const silentFilename = `silent_audio_${Date.now()}.mp3`;
        setTtsKey(silentFilename);
        setAudioUrl(silentAudioUrl);
        if (globalAudioState && typeof globalAudioState.updateTTSKey === 'function') {
          globalAudioState.updateTTSKey(silentFilename, silentAudioUrl);
        }
        if (globalAudioState && typeof globalAudioState.completeTTSProcessing === 'function') {
          globalAudioState.completeTTSProcessing();
        }
        if (typeof window !== 'undefined') {
          window.__ttsCompleted = true;
          window.__ttsCompletionTime = Date.now();
        }
        toastInstance.warning('Voice generation failed. Using silent audio for meditation.', { autoClose: 5000 });
      } else {
        toastInstance.error("Failed to generate voice audio.");
        if (globalAudioState && typeof globalAudioState.failAudioMixing === 'function') {
          globalAudioState.failAudioMixing('tts-failed');
        }
      }
      setTtsProgress(100);
      setTtsProcessing(false);
      return { success: false, error: err.message, silent: true };
    }
  }, [meditationScript, selectedVoice, baseUrl, toastInstance, currentStep, markStepCompleted]);

  // ===================== AUDIO MIXING =====================
  const handleAudioMixingHandler = useCallback(async (providedVolume, providedFilters) => {
    console.log("Starting audio mixing process");
    if (!ttsKey && globalAudioState?.state?.ttsKey) {
      console.log("Syncing missing TTS key from GlobalAudioState before mixing");
      setTtsKey(globalAudioState.state.ttsKey);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    if (!ttsKey && !globalAudioState?.state?.ttsKey) {
      console.warn("No TTS key available for mixing");
      toastInstance.warning("Voice generation not complete. Audio mixing may fail.", { toastId: 'tts-missing-warning', autoClose: 3000 });
    }
    const mixToastId = toastInstance.loading('Preparing your meditation audio...');
    setIsMixingAudio(true);
    if (globalAudioState && typeof globalAudioState.startAudioMixing === 'function') {
      globalAudioState.startAudioMixing();
    }
    // Prepare mixing payload
    const volToUse = providedVolume || volume;
    const filtersToUse = providedFilters || filters;
    const transformedFilters = {};
    if (filtersToUse.stereoPan?.enabled) {
      transformedFilters.stereo_pan = {
        enabled: true,
        ...filtersToUse.stereoPan,
        preset_key: filtersToUse.stereoPan.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    if (filtersToUse.reverb?.enabled) {
      transformedFilters.reverb = {
        enabled: true,
        ...filtersToUse.reverb,
        preset_key: filtersToUse.reverb.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    if (filtersToUse.eq?.enabled) {
      transformedFilters.eq = {
        enabled: true,
        ...filtersToUse.eq,
        preset_key: filtersToUse.eq.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    let fallbackAudioUrl = '';
    if (ttsKey) {
      fallbackAudioUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, '')}`;
      try {
        sessionStorage.setItem('fallbackAudioUrl', fallbackAudioUrl);
      } catch (e) {
        console.warn("Error storing fallback URL:", e);
      }
      if (globalAudioState && typeof globalAudioState.setCurrentAudioUrl === 'function') {
        globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
      }
    }
    const mixPayload = {
      tts_audio: ttsKey ? `TTS/${ttsKey.replace(/^TTS\//, '')}` : '',
      bg_audio: selectedMusic ? `Background_Music/${selectedMusic}.mp3` : '',
      bg_volume: volToUse.bg_volume,
      tts_volume: volToUse.tts_volume,
      overall_volume: volToUse.overall_volume,
      filters: transformedFilters
    };
    console.log("Sending to /get-mixed-audio:", mixPayload);
    let progressValue = 0;
    const progressInterval = setInterval(() => {
      progressValue = Math.min(95, progressValue + Math.random() * 2);
      if (globalAudioState && typeof globalAudioState.updateMixingProgress === 'function') {
        globalAudioState.updateMixingProgress(progressValue);
      }
      try {
        sessionStorage.setItem('mixingProgress', progressValue.toString());
      } catch (e) {
        console.warn("Error storing mixing progress:", e);
      }
    }, 1000);
    try {
      let mixAttempt = 0;
      let mixResult = null;
      while (mixAttempt < 2 && !mixResult) {
        try {
          if (mixAttempt > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            console.log(`Retrying audio mixing (attempt ${mixAttempt + 1})...`);
          }
          const response = await fetch(`${baseUrl}/get-mixed-audio`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mixPayload)
          });
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Server error: ${response.status} - ${errorText}`);
          }
          mixResult = await response.json();
          console.log("Mix result received:", mixResult);
        } catch (fetchError) {
          console.warn(`Mix attempt ${mixAttempt + 1} failed:`, fetchError);
          mixAttempt++;
          if (mixAttempt >= 2) throw fetchError;
        }
      }
      clearInterval(progressInterval);
      if (mixResult && mixResult.status === 'success') {
        let mixedUrl = null;
        if (mixResult.extracted_url && isValidUrl(mixResult.extracted_url)) {
          mixedUrl = mixResult.extracted_url;
        } else if (mixResult.data?.file_path && isValidUrl(mixResult.data.file_path)) {
          mixedUrl = mixResult.data.file_path;
        } else if (mixResult.file_path && isValidUrl(mixResult.file_path)) {
          mixedUrl = mixResult.file_path;
        } else if (mixResult.data?.file_path && typeof mixResult.data.file_path === 'string') {
          mixedUrl = mixResult.data.file_path.startsWith('/')
            ? baseUrl + mixResult.data.file_path
            : baseUrl + '/' + mixResult.data.file_path;
        }
        if (!mixedUrl || !isValidUrl(mixedUrl)) {
          console.warn("No valid URL in mix result:", mixResult);
          throw new Error("No valid URL found in mix result");
        }
        console.log("Final mixed audio URL:", mixedUrl);
        // Preload audio metadata
        try {
          const preloadAudio = new Audio();
          preloadAudio.crossOrigin = "anonymous";
          preloadAudio.src = mixedUrl;
          preloadAudio.preload = 'metadata';
          preloadAudio.volume = 0;
          preloadAudio.load();
        } catch (preloadErr) {
          console.warn("Audio preload setup error:", preloadErr);
        }
        if (globalAudioState && typeof globalAudioState.lockUrl === 'function') {
          globalAudioState.lockUrl(mixedUrl, 'mixed');
          setTimeout(() => {
            if (typeof globalAudioState.unlockUrl === 'function') {
              globalAudioState.unlockUrl();
            }
          }, 2000);
        } else {
          globalAudioState.updateAllAudioUrls(mixedUrl);
          try {
            sessionStorage.setItem('urlLocked', 'true');
            sessionStorage.setItem('urlLockTime', Date.now().toString());
            sessionStorage.setItem('urlPriority', 'mixed');
            setTimeout(() => {
              sessionStorage.setItem('urlLocked', 'false');
            }, 2000);
          } catch (e) {
            console.warn("Error setting URL lock:", e);
          }
        }
        setLastMixedAudio({ url: mixedUrl, timestamp: Date.now() });
        toastInstance.update(mixToastId, { render: 'Your meditation audio is ready!', type: "success", autoClose: 3000 });
        setIsMixingAudio(false);
        return {
          status: "success",
          data: { file_path: mixedUrl, filename: mixResult.data?.filename || "mixed_audio.mp3" },
          originalResult: mixResult
        };
      } else {
        console.warn("Mix result invalid:", mixResult);
        throw new Error(mixResult?.message || "Invalid response from server");
      }
    } catch (error) {
      console.error("Audio mixing error:", error);
      clearInterval(progressInterval);
      if (globalAudioState && typeof globalAudioState.failAudioMixing === 'function') {
        globalAudioState.failAudioMixing('server-error');
      }
      if (fallbackAudioUrl) {
        console.log("Using fallback audio URL:", fallbackAudioUrl);
        toastInstance.update(mixToastId, { render: 'Audio processing encountered an issue. Using voice audio only.', type: "warning", autoClose: 5000 });
        setAudioUrl(fallbackAudioUrl);
        if (globalAudioState && typeof globalAudioState.setCurrentAudioUrl === 'function') {
          globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
        }
        return fallbackAudioUrl;
      } else {
        toastInstance.update(mixToastId, { render: 'Failed to apply changes. Please try again.', type: "error", autoClose: 5000 });
        throw error;
      }
    }
  }, [baseUrl, ttsKey, selectedMusic, volume, filters, toastInstance]);

  // ===================== AUDIO ERROR HANDLER =====================
  const handleAudioErrorHandler = useCallback((error) => {
    console.error('Audio error occurred:', error);
    setAudioUrl(globalAudioState.getBestAudioUrl());
  }, []);

  // ===================== EXPORT AUDIO =====================
  const handleExportAudioHandler = useCallback(async () => {
    if (!audioUrl) {
      toastInstance.error('No audio available to export');
      return;
    }
    try {
      const response = await fetch(audioUrl, { method: 'GET', cache: 'no-cache' });
      const audioBlob = await response.blob();
      if (!audioBlob) throw new Error("Failed to fetch audio for download");
      const a = document.createElement('a');
      const blobUrl = URL.createObjectURL(audioBlob);
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      toastInstance.success('Downloading your meditation audio');
    } catch (err) {
      console.warn("Blob download error, falling back to direct URL:", err);
      const a = document.createElement('a');
      a.href = audioUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 100);
      toastInstance.success('Downloading your meditation audio');
    }
  }, [audioUrl, toastInstance]);

  // ===================== RESET SESSION =====================
  const handleResetSessionHandler = useCallback(() => {
    setCurrentStep(1);
    setCompletedSteps([]);
    setCustomPrompt('');
    setMeditationScript('');
    setSelectedVoice('onyx');
    setSelectedOptions([]);
    setAudioUrl('');
    setTtsKey('');
    setSelectedMusic('');
    setVolume({ overall_volume: 1, tts_volume: 1, bg_volume: 0.2 });
    setFilters({
      stereoPan: { enabled: false, value: 0.5, speed: 30, pattern: 'sine' },
      reverb: { enabled: false, wet: 0.5, dry: 1.0, preset: '' },
      eq: { enabled: false, low_cut: 80, mid_gain: 3.0, mid_freq: 1000, mid_Q: 1.0, high_cut: 12000, preset: '' }
    });
    setIsScriptGenerated(false);
    setLastMixedAudio(null);
    setTtsProcessing(false);
    setTtsProgress(0);
    setTtsCompleted(false);
    // Reset any global audio state flags and session storage values
    globalAudioState.state.ttsKey = null;
    globalAudioState.state.ttsProgress = 0;
    globalAudioState.state.ttsProcessing = false;
    globalAudioState.state.mixedAudioUrl = null;
    globalAudioState.state.currentAudioUrl = null;
    globalAudioState.saveToSessionStorage();
  }, []);

  // ===================== STEP TRANSITIONING =====================
  const handleStepTransitionHandler = useCallback(async (fromStep, toStep) => {
    console.log(`Transitioning from step ${fromStep} to step ${toStep}`);
    if (fromStep > toStep && currentStep === 6) {
      console.warn("Backwards navigation from ListenStep prevented");
      return;
    }
    if (TransitionManager && TransitionManager.isTransitionActive()) {
      console.warn("Transition in progress. Ignoring redundant request.");
      return;
    }
    if (fromStep === 5 && toStep === 6) {
      window.__transitionInProgress = true;
      window.__transitionFromStep = fromStep;
      window.__transitionToStep = toStep;
      window.__transitionTimestamp = Date.now();
      window.__waveSurferLock = true;
      try {
        const ttsReadiness = (globalAudioState.isTTSReadyForMixing && globalAudioState.isTTSReadyForMixing())
          || { isReady: !ttsProcessing || ttsProgress >= 90 || !!ttsKey };
        if (!ttsReadiness.isReady) {
          console.log("TTS not ready; displaying loader", ttsReadiness);
          setIsTransitioning(true);
          setTransitionMessage("Preparing your meditation audio...");
          setTransitionSubtitle("Waiting for voice generation to complete");
          await new Promise((resolve) => {
            const maxWaitTime = 20000;
            const startTime = Date.now();
            const checkInterval = setInterval(() => {
              const currentReadiness = (globalAudioState.isTTSReadyForMixing && globalAudioState.isTTSReadyForMixing())
                || { isReady: !ttsProcessing || ttsProgress >= 90 || !!ttsKey, progress: ttsProgress };
              if (currentReadiness.progress > 0) {
                setTransitionSubtitle(`Voice generation: ${Math.round(currentReadiness.progress)}%`);
              }
              if (currentReadiness.isReady || (Date.now() - startTime) >= maxWaitTime) {
                clearInterval(checkInterval);
                resolve(currentReadiness);
              }
            }, 500);
            setTimeout(() => { clearInterval(checkInterval); resolve({ isReady: true, forced: true }); }, maxWaitTime + 1000);
          });
        }
        markStepCompleted(fromStep);
        setTransitionDirection('forward');
        setIsTransitioning(true);
        setTransitionMessage("Preparing your meditation...");
        setTransitionSubtitle("Setting up your audio");
        const currentAudioUrl = audioUrl || (ttsKey ? `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, "")}` : null);
        const audioIsPlaying = audioElementRef.current ? !audioElementRef.current.paused : false;
        if (globalAudioState && typeof globalAudioState.storeAudioStateForTransition === 'function') {
          globalAudioState.storeAudioStateForTransition({
            url: currentAudioUrl,
            isPlaying: audioIsPlaying,
            ttsKey,
            selectedMusic
          });
        }
        let transitionResult = { success: false, url: null };
        try {
          await new Promise(resolve => setTimeout(resolve, 800));
          transitionResult = await globalAudioState.prepareTransitionToListenStep({ source: "MeditationApp", fromStep, toStep });
        } catch (err) {
          console.error("Transition preparation error:", err);
        }
        if (!transitionResult.success) {
          toastInstance.warning("Transition encountered issues. Using fallback audio.", { toastId: 'transition-warning', autoClose: 5000 });
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        setCurrentStep(toStep);
        setTimeout(() => {
          if (transitionResult.url) globalAudioState.completeTransition(transitionResult.url);
          else globalAudioState.unlockUrl();
          setTransitionDirection(null);
          setIsTransitioning(false);
          setTimeout(() => { window.__waveSurferLock = false; }, 1000);
          setTimeout(() => {
            window.__transitionInProgress = false;
            window.__transitionLock = false;
            window.__transitionCompleted = true;
            window.__transitionCompletedTime = Date.now();
          }, 3000);
        }, 4000);
      } catch (error) {
        console.error("Critical transition error:", error);
        if (TransitionManager && typeof TransitionManager.clearAllTransitionFlags === 'function') {
          TransitionManager.clearAllTransitionFlags();
        } else {
          window.__transitionInProgress = false;
          window.__waveSurferLock = false;
          window.__transitionLock = false;
        }
        markStepCompleted(fromStep);
        setCurrentStep(toStep);
        setIsTransitioning(false);
        setTransitionDirection(null);
        toastInstance.error("There was an issue preparing your meditation. Please refresh if needed.");
      }
    } else {
      setCurrentStep(toStep);
    }
  }, [audioUrl, ttsKey, ttsProcessing, ttsProgress, currentStep, selectedMusic, baseUrl, toastInstance, globalAudioState, markStepCompleted]);

  // ===================== AUDIO MIXING FROM LISTEN STEP =====================
  const handleAudioMixingFromListenStep = useCallback(async (volumeOverride, filtersOverride) => {
    const mixToastId = toastInstance.loading('Applying your changes...');
    setIsMixingAudio(true);
    if (globalAudioState && typeof globalAudioState.startAudioMixing === 'function') {
      globalAudioState.startAudioMixing();
    }
    const volumeToUse = volumeOverride || volume;
    const filtersToUse = filtersOverride || filters;
    const transformedFilters = {};
    if (filtersToUse.stereoPan?.enabled) {
      transformedFilters.stereo_pan = {
        enabled: true,
        ...filtersToUse.stereoPan,
        preset_key: filtersToUse.stereoPan.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    if (filtersToUse.reverb?.enabled) {
      transformedFilters.reverb = {
        enabled: true,
        ...filtersToUse.reverb,
        preset_key: filtersToUse.reverb.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    if (filtersToUse.eq?.enabled) {
      transformedFilters.eq = {
        enabled: true,
        ...filtersToUse.eq,
        preset_key: filtersToUse.eq.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    let fallbackAudioUrl = '';
    if (ttsKey) {
      fallbackAudioUrl = `${baseUrl}/tts/${ttsKey.replace(/^TTS\//, '')}`;
      try {
        sessionStorage.setItem('fallbackAudioUrl', fallbackAudioUrl);
      } catch (e) {
        console.warn("Error storing fallback URL:", e);
      }
    }
    const mixPayload = {
      tts_audio: ttsKey ? `TTS/${ttsKey.replace(/^TTS\//, '')}` : '',
      bg_audio: selectedMusic ? `Background_Music/${selectedMusic}.mp3` : '',
      bg_volume: volumeToUse.bg_volume,
      tts_volume: volumeToUse.tts_volume,
      overall_volume: volumeToUse.overall_volume,
      filters: transformedFilters
    };
    console.log("Sending to /get-mixed-audio:", mixPayload);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress = Math.min(95, progress + Math.random() * 2);
      if (globalAudioState && typeof globalAudioState.updateMixingProgress === 'function') {
        globalAudioState.updateMixingProgress(progress);
      }
      try {
        sessionStorage.setItem('mixingProgress', progress.toString());
      } catch (e) {
        console.warn("Error storing mixing progress:", e);
      }
    }, 1000);
    try {
      let mixAttempt = 0;
      let mixResult = null;
      while (mixAttempt < 2 && !mixResult) {
        try {
          if (mixAttempt > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            console.log(`Retrying audio mixing (attempt ${mixAttempt + 1})...`);
          }
          const response = await fetch(`${baseUrl}/get-mixed-audio`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mixPayload)
          });
          clearInterval(progressInterval);
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Server error: ${response.status} - ${errorText}`);
          }
          mixResult = await response.json();
          console.log("Received mix result:", mixResult);
          const finalUrl = mixResult.data.file_path.startsWith('http')
            ? mixResult.data.file_path
            : `${baseUrl}${mixResult.data.file_path.startsWith('/') ? '' : '/'}${mixResult.data.file_path}`;
          console.log("Final mixed audio URL:", finalUrl);
          if (globalAudioState && typeof globalAudioState.setCurrentAudioUrl === 'function') {
            globalAudioState.setCurrentAudioUrl(finalUrl);
          }
          if (globalAudioState && typeof globalAudioState.completeAudioMixing === 'function') {
            globalAudioState.completeAudioMixing(finalUrl);
          }
          try {
            sessionStorage.setItem('mixedAudioUrl', finalUrl);
            sessionStorage.setItem('currentAudioUrl', finalUrl);
            sessionStorage.setItem('urlPriority', 'mixed');
          } catch (e) {
            console.warn("Error updating session storage:", e);
          }
          toastInstance.update(mixToastId, { render: 'Your changes have been applied!', type: "success", autoClose: 3000 });
          setAudioUrl(finalUrl);
          return finalUrl;
        } catch (error) {
          console.error("Audio mixing error:", error);
          mixAttempt++;
          if (mixAttempt >= 2) throw error;
        }
      }
    } catch (error) {
      console.error("Audio mixing error:", error);
      clearInterval(progressInterval);
      if (globalAudioState && typeof globalAudioState.failAudioMixing === 'function') {
        globalAudioState.failAudioMixing('server-error');
      }
      if (fallbackAudioUrl) {
        console.log("Using fallback audio URL:", fallbackAudioUrl);
        toastInstance.update(mixToastId, { render: 'Audio processing encountered an issue. Using voice audio only.', type: "warning", autoClose: 5000 });
        setAudioUrl(fallbackAudioUrl);
        if (globalAudioState && typeof globalAudioState.setCurrentAudioUrl === 'function') {
          globalAudioState.setCurrentAudioUrl(fallbackAudioUrl);
        }
        return fallbackAudioUrl;
      } else {
        toastInstance.update(mixToastId, { render: 'Failed to apply changes. Please try again.', type: "error", autoClose: 5000 });
        throw error;
      }
    }
  }, [baseUrl, ttsKey, selectedMusic, volume, filters, toastInstance]);

  // ===================== RENDERING =====================
  // Define steps for progress bar
  const steps = [
    { id: 1, name: "Create", completed: completedSteps.includes(1) },
    { id: 2, name: "Script", completed: completedSteps.includes(2) },
    { id: 3, name: "Voice", completed: completedSteps.includes(3) },
    { id: 4, name: "Music", completed: completedSteps.includes(4) },
    { id: 5, name: "Audio", completed: completedSteps.includes(5) },
    { id: 6, name: "Listen", completed: completedSteps.includes(6) }
  ];

  return (
    <ThemeProvider>
      <div className="meditation-app-container">
        <div className="sr-only" aria-live="polite" role="status">
          {`Step ${currentStep} of 6: ${steps[currentStep - 1]?.name}`}
        </div>
        {(ttsProcessing || isMixingAudio || isTransitioning) && (
          <LoadingOverlay
            message={isTransitioning ? (transitionMessage || "Preparing your meditation...") : "Loading..."}
            subtitle={isTransitioning ? (transitionSubtitle || "Finding your calm") : ""}
            progress={isTransitioning ? 60 : 0}
            isVisible={true}
            isMobile={windowDimensions.width <= 768}
            jobId={null}
            currentPhase={null}
            baseUrl={baseUrl}
          />
        )}
        <ProgressBar steps={steps} currentStep={currentStep} />
        <div className="meditation-content">
          <div className="step-content">
            {currentStep === 1 && (
              <CreateStep
                customPrompt={customPrompt}
                setCustomPrompt={setCustomPrompt}
                onGenerateScript={async (prompt) => {
                  try {
                    const script = await handleGenerateScript(prompt);
                    setMeditationScript(script);
                    markStepCompleted(1);
                    setCurrentStep(2);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  } catch (err) {
                    setError(err.message);
                    toastInstance.error(err.message);
                  }
                }}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setError={setError}
                baseUrl={baseUrl}
                selectedVoice={selectedVoice}
                setMeditationScript={setMeditationScript}
                markStepCompleted={markStepCompleted}
                goToNextStep={() => setCurrentStep(2)}
                isLoading={isScriptLoading}
              />
            )}
            {currentStep === 2 && (
              <ScriptStep
                meditationScript={meditationScript}
                setMeditationScript={setMeditationScript}
                onScriptReady={() => {
                  markStepCompleted(2);
                  setCurrentStep(3);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                globalPauseFactor={1.2}
              />
            )}
            {currentStep === 3 && (
              <VoiceStep
                selectedVoice={selectedVoice}
                setSelectedVoice={setSelectedVoice}
                onTTSStart={handleTTSStart}
                onTTSProgress={handleTTSProgress}
                onTTSComplete={handleTTSComplete}
              />
            )}
            {currentStep === 4 && (
              <MusicSelection
                musicLibrary={musicLibrary}
                selectedMusic={selectedMusic}
                setSelectedMusic={setSelectedMusic}
                isMusicLoading={isMusicLoading}
                markStepCompleted={(step) => markStepCompleted(step)}
                goToNextStep={() => setCurrentStep(5)}
                isSessionCreated={true}
                baseUrl={baseUrl}
                isBackgroundTTSProcessing={ttsProcessing}
                backgroundTTSProgress={ttsProgress}
                ttsKey={ttsKey}
                ttsCompleted={ttsCompleted}
                onMusicLibraryLoaded={(loadedLibrary) => {
                  console.log("Music library loaded:", loadedLibrary.length, "tracks");
                  setMusicLibrary(loadedLibrary);
                  setMusicSelectionComplete(true);
                }}
              />
            )}
            {currentStep === 5 && (
              <AudioStep
                volume={volume}
                setVolume={(type, value) => {
                  if (typeof type === 'object') setVolume(type);
                  else if (typeof type === 'string') setVolume(prev => ({ ...prev, [type]: parseFloat(value) }));
                }}
                filters={filters}
                setFilters={setFilters}
                musicLibrary={musicLibrary}
                selectedMusic={selectedMusic}
                setSelectedMusic={setSelectedMusic}
                onApplyChanges={async () => {
                  try {
                    await handleAudioMixingHandler(null, null);
                    markStepCompleted(5);
                    setCurrentStep(6);
                  } catch (err) {
                    toastInstance.error(err.message);
                  }
                }}
                ttsKey={ttsKey}
                isMixingAudio={isMixingAudio}
                isBackgroundTTSProcessing={ttsProcessing}
                backgroundTTSProgress={ttsProgress}
                markStepCompleted={markStepCompleted}
                goToNextStep={async () => await handleStepTransitionHandler(5, 6)}
                baseUrl={baseUrl}
              />
            )}
            {currentStep === 6 && (
              <div className="stable-listen-container" style={{ width: '100%', minHeight: '400px' }}>
                <ErrorBoundary>
                  <AudioStep
                    audioUrl={audioUrl}
                    onExportAudio={handleExportAudioHandler}
                    onReset={handleResetSessionHandler}
                    onError={handleAudioErrorHandler}
                    ttsKey={ttsKey}
                    selectedMusic={selectedMusic}
                    baseUrl={baseUrl}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </div>
        <StepNavigation
          currentStep={currentStep}
          totalSteps={6}
          onNext={currentStep === 5 ? async () => await handleStepTransitionHandler(5, 6) : () => setCurrentStep(currentStep + 1)}
          onPrevious={() => setCurrentStep(currentStep - 1)}
        />
        <CustomToastContainer />
      </div>
    </ThemeProvider>
  );
};

export default MeditationApp;
