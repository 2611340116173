// TransitionManager.js
// Comprehensive transition manager for audio and step transitions

/**
 * TransitionManager - Encapsulates all transition-related state and methods 
 * Coordinates smooth transitions between steps and ensures audio continuity
 */
class TransitionManager {
    constructor() {
      this.state = {
        // Core transition state
        inTransition: false,
        transitionStartTime: null,
        transitionCompleted: false,
        
        // URL tracking
        stableUrl: null,
        currentUrl: null,
        urlPriority: null,
        
        // Audio state
        audioPlaying: false,
        currentTime: 0,
        duration: 0,
        volume: 1,
        
        // Step transition
        fromStep: null,
        toStep: null,
        
        // Shared resources
        audioElement: null,
        
        // Flags
        isAudioLocked: false,
        hasTransitionError: false
      };
      
      // Initialize event handling
      this.subscribers = {};
      
      // Clear any window flags that might be lingering from previous runs
      if (typeof window !== 'undefined') {
        window.__transitionLock = false;
        window.__transitionInProgress = false;
        window.__waveSurferLock = false;
        window.__audioStepTransitionStarted = false;
        window.__transitionCompleted = false;
      }
      
      // Make this instance available globally for cross-component access
      if (typeof window !== 'undefined') {
        window.TransitionManager = this;
        console.log("TransitionManager: Initialized and cleared stale flags");
      }
    }
    
    /**
     * Updates state with new values
     */
    setState(newState) {
      this.state = { ...this.state, ...newState };
      
      // Publish state change event
      this.publishEvent('state-changed', this.getState());
      
      return this.state;
    }
    
    /**
     * Returns a copy of the current state
     */
    getState() {
      return { ...this.state };
    }
    
    /**
     * Event subscription system
     */
    subscribe(eventName, callback) {
      if (!this.subscribers[eventName]) {
        this.subscribers[eventName] = [];
      }
      
      this.subscribers[eventName].push(callback);
      
      // Return unsubscribe function
      return () => {
        this.subscribers[eventName] = this.subscribers[eventName]
          .filter(cb => cb !== callback);
      };
    }
    
    /**
     * Publish events to subscribers
     */
    publishEvent(eventName, data) {
      if (!this.subscribers[eventName]) return;
      
      this.subscribers[eventName].forEach(callback => {
        try {
          callback(data);
        } catch (err) {
          console.warn(`TransitionManager: Error in event subscriber for ${eventName}:`, err);
        }
      });
    }
    
    /**
     * Clears all transition flags to resolve stuck states
     * Can be called from any component when transitions appear stuck
     */
    clearAllTransitionFlags() {
        console.log("TransitionManager: Forcefully clearing all transition flags");
        
        // Clear internal state
        this.setState({
          inTransition: false,
          transitionStartTime: null,
          transitionCompleted: false,
          isAudioLocked: false,
          urlPriority: null,
          fromStep: null,
          toStep: null,
          hasTransitionError: false
        });
        
        // Clear all window flags
        if (typeof window !== "undefined") {
          // Clear main transition flags
          window.__transitionLock = false;
          window.__transitionInProgress = false;
          window.__waveSurferLock = false;
          window.__audioStepTransitionStarted = false;
          
          // Clear transition metadata
          window.__transitionFromStep = null;
          window.__transitionToStep = null;
          window.__transitionTimestamp = null;
          window.__transitionPosition = null;
          window.__transitionVolume = null;
          
          // Clear fullscreen transition flags
          window.__fsTransitionPlaying = null;
          window.__fsTransitionTime = null;
          window.__audioUrlForTransition = null;
          window.__audioPlayingForTransition = null;
          window.__ttsKeyForTransition = null;
          window.__musicForTransition = null;
          
          // Mark as completed to prevent stuck states
          window.__transitionCompleted = true;
          window.__transitionCompletedTime = Date.now();
        }
        
        // Also update globalAudioState if available
        if (typeof window !== "undefined" && window.globalAudioState) {
          if (window.globalAudioState.state) {
            window.globalAudioState.state.isInTransition = false;
          }
          
          if (typeof window.globalAudioState.saveToSessionStorage === 'function') {
            window.globalAudioState.saveToSessionStorage();
          }
          
          // Call completeTransition if available
          if (typeof window.globalAudioState.completeTransition === 'function') {
            try {
              window.globalAudioState.completeTransition(this.state.currentUrl || '');
            } catch (error) {
              console.warn("TransitionManager: Error calling globalAudioState.completeTransition:", error);
            }
          }
        }
        
        // Publish reset event
        this.publishEvent('transition-flags-cleared', {
          timestamp: Date.now(),
          reason: 'manual_reset'
        });
        
        return true;
      }
    
    /**
     * Checks if a transition is currently active
     */
    isTransitionActive() {
      // Primary check is internal state
      if (this.state.inTransition) {
        return true;
      }
      
      // Secondary check is window flags, but combine them into one logical check
      if (typeof window !== 'undefined') {
        const windowFlagsActive = 
          window.__transitionInProgress === true || 
          window.__transitionLock === true ||
          window.__waveSurferLock === true;
        
        if (windowFlagsActive) {
          // If window flags indicate transition but state doesn't,
          // synchronize the state to match the flags
          if (!this.state.inTransition) {
            console.log("TransitionManager: Syncing transition state with window flags");
            this.setState({ inTransition: true });
          }
          return true;
        }
      }
      
      return false;
    }
    
    /**
     * Locks URL during transition to prevent changes
     */
    lockUrl(url, priority = "other") {
      if (!url || typeof url !== "string") {
        console.warn("TransitionManager: Cannot lock an invalid URL.");
        return this.getState();
      }
      
      console.log(`TransitionManager: Locking URL ${url} with priority ${priority}`);
      
      this.setState({
        inTransition: true,
        transitionStartTime: Date.now(),
        stableUrl: url,
        currentUrl: url,
        urlPriority: priority,
        transitionCompleted: false,
        isAudioLocked: true
      });
      
      // Set window flags for cross-component detection
      if (typeof window !== 'undefined') {
        window.__transitionLock = true;
        window.__transitionTimestamp = Date.now();
        window.__stableUrl = url;
        window.__urlPriority = priority;
        
        // Auto-release lock after 10 seconds as safety measure
        setTimeout(() => {
          if (window.__transitionLock === true) {
            console.log("TransitionManager: Auto-releasing URL lock after timeout");
            window.__transitionLock = false;
            
            // Update state
            this.setState({
              inTransition: false,
              isAudioLocked: false
            });
          }
        }, 10000);
      }
      
      // Publish event
      this.publishEvent('url-locked', {
        url,
        priority,
        timestamp: Date.now()
      });
      
      return this.getState();
    }
    
    /**
     * Updates URL without locking transition state
     */
    setUrl(url, priority = "other") {
      if (!url || typeof url !== "string") {
        console.warn("TransitionManager: Invalid URL provided to setUrl");
        return this.getState();
      }
      
      console.log(`TransitionManager: Setting URL ${url} with priority ${priority}`);
      
      this.setState({
        currentUrl: url,
        stableUrl: url,
        urlPriority: priority
      });
      
      // Update window flags
      if (typeof window !== 'undefined') {
        window.__stableUrl = url;
        window.__urlPriority = priority;
      }
      
      // Publish event
      this.publishEvent('url-updated', {
        url,
        priority,
        timestamp: Date.now()
      });
      
      return this.getState();
    }
    
    /**
     * Marks a transition as complete and updates final URL
     */
    completeTransition(finalUrl) {
      if (finalUrl && typeof finalUrl === "string") {
        this.setState({
          currentUrl: finalUrl,
          stableUrl: finalUrl
        });
        
        // Update window flags
        if (typeof window !== 'undefined') {
          window.__stableUrl = finalUrl;
        }
      }
      
      console.log("TransitionManager: Completing transition");
      
      this.setState({
        inTransition: false,
        transitionCompleted: true,
        transitionStartTime: null,
        fromStep: null,
        toStep: null,
        isAudioLocked: false
      });
      
      // Update window flags
      if (typeof window !== 'undefined') {
        window.__transitionLock = false;
        window.__transitionInProgress = false;
        window.__transitionFromStep = null;
        window.__transitionToStep = null;
        window.__transitionCompleted = true;
        window.__transitionCompletedTime = Date.now();
      }
      
      // Publish event
      this.publishEvent('transition-complete', {
        finalUrl,
        timestamp: Date.now()
      });
      
      // Also notify globalAudioState if available
      if (typeof window !== 'undefined' && window.globalAudioState) {
        if (typeof window.globalAudioState.completeTransition === 'function') {
          try {
            window.globalAudioState.completeTransition(finalUrl);
          } catch (error) {
            console.warn("TransitionManager: Error calling globalAudioState.completeTransition:", error);
          }
        }
      }
      
      return this.getState();
    }
    
    /**
     * Releases URL lock without marking transition as complete
     */
    unlockUrl() {
      console.log("TransitionManager: Unlocking URL");
      
      this.setState({
        inTransition: false,
        transitionCompleted: false,
        transitionStartTime: null,
        isAudioLocked: false,
        urlPriority: null
      });
      
      // Clear window flags
      if (typeof window !== 'undefined') {
        window.__transitionLock = false;
        window.__transitionInProgress = false;
      }
      
      // Publish event
      this.publishEvent('url-unlocked', {
        timestamp: Date.now()
      });
      
      return this.getState();
    }
    
    /**
     * Gets best URL based on priorities
     */
    getBestUrl() {
      if (this.state.isAudioLocked && this.state.stableUrl) {
        return this.state.stableUrl;
      }
      
      return this.state.currentUrl || '';
    }
    
    /**
     * Coordinates transition between steps
     * Ensures audio state is preserved during transitions
     */
    coordinateTransition(params) {
      const {
        fromStep,
        toStep,
        globalAudioState,
        ttsKey,
        audioUrl,
        isPlaying,
        selectedMusic,
        toast,
        setIsTransitioning,
        setTransitionMessage,
        setTransitionSubtitle
      } = params;
  
      console.log(`TransitionManager: Coordinating transition from step ${fromStep} to step ${toStep}`);
      
      // Set transition UI state if provided
      if (setIsTransitioning) setIsTransitioning(true);
      if (setTransitionMessage) setTransitionMessage("Creating your meditation audio...");
      if (setTransitionSubtitle) setTransitionSubtitle("Preparing seamless transition");
  
      // Update transition state
      this.setState({
        inTransition: true,
        transitionStartTime: Date.now(),
        fromStep: fromStep,
        toStep: toStep,
        audioPlaying: isPlaying,
        hasTransitionError: false
      });
      
      // Lock URL for transition
      if (audioUrl && typeof audioUrl === 'string') {
        this.lockUrl(audioUrl, 'transition');
      }
      
      // Set window flags for transition state detection
      if (typeof window !== 'undefined') {
        window.__transitionTimestamp = Date.now();
        window.__transitionInProgress = true;
        window.__fsTransitionPlaying = isPlaying;
        window.__transitionLock = true;
        window.__transitionFromStep = fromStep;
        window.__transitionToStep = toStep;
        
        // Store additional transition information
        window.__ttsKeyForTransition = ttsKey;
        window.__audioUrlForTransition = audioUrl;
        window.__musicForTransition = selectedMusic;
        
        // Auto-release transition lock after 5 seconds
        setTimeout(() => {
          if (window.__transitionLock === true) {
            window.__transitionLock = false;
          }
        }, 5000);
      }
      
      // Set state in globalAudioState if available
      if (globalAudioState) {
        // Store audio state
        if (typeof globalAudioState.state === 'object') {
          globalAudioState.state.isInTransition = true;
          globalAudioState.state.transitionTimestamp = Date.now();
          
          globalAudioState.state.currentAudioUrl = audioUrl;
          globalAudioState.state.audioPlaying = isPlaying;
          
          if (selectedMusic) {
            globalAudioState.state.selectedMusic = selectedMusic;
          }
          
          if (ttsKey) {
            globalAudioState.state.ttsKey = ttsKey;
          }
          
          // Store transition metadata
          globalAudioState.state.transitionFromStep = fromStep;
          globalAudioState.state.transitionToStep = toStep;
        }
        
        // Save to session storage if method exists
        if (typeof globalAudioState.saveToSessionStorage === 'function') {
          globalAudioState.saveToSessionStorage();
        }
        
        // Use globalAudioState's transition methods if available
        if (typeof globalAudioState.prepareTransitionToListenStep === 'function') {
          try {
            globalAudioState.prepareTransitionToListenStep({
              isPlaying, 
              audioUrl,
              ttsKey,
              selectedMusic,
              fromStep,
              toStep
            });
          } catch (error) {
            console.warn("TransitionManager: Error calling globalAudioState.prepareTransitionToListenStep:", error);
          }
        } else if (typeof globalAudioState.prepareForTransition === 'function') {
          try {
            globalAudioState.prepareForTransition({
              isPlaying, 
              audioUrl
            });
          } catch (error) {
            console.warn("TransitionManager: Error calling globalAudioState.prepareForTransition:", error);
          }
        }
      }
  
      // Get shared audio element
      const sharedAudioElement = globalAudioState?._audioElement || null;
      
      // Track audio element
      this.setState({
        audioElement: sharedAudioElement
      });
  
      // If audio is playing, pause it temporarily for transition
      if (isPlaying && sharedAudioElement && !sharedAudioElement.paused) {
        console.log("TransitionManager: Temporarily pausing audio");
        try {
          // Store current position
          const currentTime = sharedAudioElement.currentTime;
          const volume = sharedAudioElement.volume;
          
          // Update state
          this.setState({
            currentTime,
            volume
          });
          
          // Update window flags
          if (typeof window !== 'undefined') {
            window.__transitionPosition = currentTime;
            window.__transitionVolume = volume;
          }
          
          // Update global state
          if (globalAudioState && typeof globalAudioState.state === 'object') {
            globalAudioState.state.transitionPosition = currentTime;
            globalAudioState.state.currentTime = currentTime;
            globalAudioState.state.volume = volume;
            
            if (typeof globalAudioState.saveToSessionStorage === 'function') {
              globalAudioState.saveToSessionStorage();
            }
          }
          
          // Pause the audio
          sharedAudioElement.pause();
          
          // Publish pause event
          this.publishEvent('audio-paused-for-transition', {
            currentTime,
            volume,
            timestamp: Date.now()
          });
        } catch (e) {
          console.warn("TransitionManager: Error pausing audio:", e);
          
          // Mark error but continue
          this.setState({
            hasTransitionError: true
          });
        }
      }
  
      // Publish transition event
      this.publishEvent('transition-started', {
        fromStep,
        toStep,
        audioUrl,
        isPlaying,
        timestamp: Date.now()
      });
  
      return {
        success: true,
        message: "Transition preparation complete",
        audioElement: sharedAudioElement,
        url: audioUrl || (globalAudioState && typeof globalAudioState.getBestAudioUrl === 'function' ? 
            globalAudioState.getBestAudioUrl() : '')
      };
    }
  
    /**
     * Completes an audio transition after the ListenStep has mounted
     * Restores playback state and clears transition flags
     */
    completeAudioTransition(params) {
      const {
        globalAudioState,
        wasPlaying = false,
        audioElement = null,
        finalUrl = null
      } = params;
      
      console.log("TransitionManager: Completing audio transition, playback state:", wasPlaying);
      
      // Set final URL if provided
      if (finalUrl && typeof finalUrl === 'string') {
        this.setUrl(finalUrl, 'final');
      }
      
      // Clear transition state
      this.setState({
        inTransition: false,
        transitionCompleted: true,
        isAudioLocked: false,
        fromStep: null,
        toStep: null
      });
      
      // Clear window flags
      if (typeof window !== 'undefined') {
        window.__transitionInProgress = false;
        window.__transitionLock = false;
        window.__transitionFromStep = null;
        window.__transitionToStep = null;
        window.__transitionCompleted = true;
        window.__transitionCompletedTime = Date.now();
      }
      
      // Clear global state transition flags
      if (globalAudioState) {
        if (typeof globalAudioState.state === 'object') {
          globalAudioState.state.isInTransition = false;
        }
        
        if (typeof globalAudioState.completeTransition === 'function') {
          try {
            globalAudioState.completeTransition(finalUrl);
          } catch (error) {
            console.warn("TransitionManager: Error calling globalAudioState.completeTransition:", error);
          }
        } else if (typeof globalAudioState.resumeFromTransition === 'function') {
          try {
            globalAudioState.resumeFromTransition();
          } catch (error) {
            console.warn("TransitionManager: Error calling globalAudioState.resumeFromTransition:", error);
          }
        } else if (typeof globalAudioState.saveToSessionStorage === 'function') {
          globalAudioState.saveToSessionStorage();
        }
      }
      
      // Determine which audio element to use
      const targetAudioElement = audioElement || this.state.audioElement || 
                                 (globalAudioState && globalAudioState._audioElement);
      
      // Resume playback if it was active before
      if (wasPlaying && targetAudioElement && targetAudioElement.paused) {
        console.log("TransitionManager: Resuming playback");
        try {
          // Use a small delay to ensure target component is ready
          setTimeout(() => {
            if (targetAudioElement.paused) {
              const playPromise = targetAudioElement.play();
              if (playPromise) {
                playPromise.catch(err => {
                  console.warn("TransitionManager: Failed to resume playback:", err);
                  
                  // Publish error event
                  this.publishEvent('playback-resume-failed', {
                    error: err.message,
                    timestamp: Date.now()
                  });
                });
              }
            }
          }, 500);
        } catch (e) {
          console.warn("TransitionManager: Error resuming playback:", e);
        }
      }
      
      // Publish completion event
      this.publishEvent('transition-completed', {
        wasPlaying,
        finalUrl,
        timestamp: Date.now()
      });
      
      return {
        success: true,
        message: "Transition completed"
      };
    }
  
    /**
     * Handles transition errors
     */
    handleTransitionError(error) {
      console.error("TransitionManager: Transition error:", error);
      
      this.setState({
        hasTransitionError: true
      });
      
      // Publish error event
      this.publishEvent('transition-error', {
        error: error.message || 'Unknown error',
        timestamp: Date.now()
      });
      
      // Try to recover by releasing locks
      this.unlockUrl();
      
      if (typeof window !== 'undefined') {
        window.__transitionLock = false;
        window.__transitionInProgress = false;
      }
      
      return {
        success: false,
        error: error.message || 'Unknown error'
      };
    }
  
    /**
     * Logs current state for debugging
     */
    debug() {
      console.log("TransitionManager state:", this.getState());
      
      // Also log window flags
      if (typeof window !== 'undefined') {
        console.log("Window transition flags:", {
          transitionLock: window.__transitionLock,
          transitionInProgress: window.__transitionInProgress,
          waveSurferLock: window.__waveSurferLock,
          transitionTimestamp: window.__transitionTimestamp,
          stableUrl: window.__stableUrl,
          urlPriority: window.__urlPriority,
          transitionFromStep: window.__transitionFromStep,
          transitionToStep: window.__transitionToStep,
          fsTransitionPlaying: window.__fsTransitionPlaying,
          transitionCompleted: window.__transitionCompleted,
          transitionCompletedTime: window.__transitionCompletedTime
        });
      }
      
      return this.getState();
    }
}

// Export a singleton instance
export default new TransitionManager();